/* eslint-disable indent */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { getOtherUserAction } from "../../../../redux/actions/profileActions";
import { logProfileVisits } from "../../../../redux/actions/insightActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { bool, func, object, string } from "prop-types";
import { Divider, IconButton } from "@material-ui/core";
import icons from "../../../../assets/icons/icon-collection.svg";
import CustomButton from "../../../../components/CustomButton";
import ViewDetails from "./ViewDetails";
import Refer from "./ReferJob";
import moment from "moment";

import Apply from "./Apply";
import { useDispatch, useSelector } from "react-redux";
import {
  applyAction,
  // getJob,
  saveJobAction,
  applyToRecruiterJob,
  getEitherJob,
  recruitRequest,
} from "../../../../redux/actions/jobActions";
import { getSingleExperienceAction } from "../../../../redux/actions/experienceAction";
import { ButtonLoader } from "../../../../components/ButtonLoader";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { SectionLoader } from "../../../../components/PageLoader";
import { updateProfileAction } from "../../../../redux/actions/profileActions";
import { saveBusinessRef } from "../../../../redux/actions/businessActions";
import { DemoPointer } from "../../../../demo";
import { demoAction } from "../../../../redux/actions/demoActions";
import cogoToast from "cogo-toast";

const HomeModal = ({ open, handleClose, hideActions, isBusiness }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
   const { profile } = useSelector((store) => store);
  const { profileData } = profile;
   const { id, stage } = useParams();
  const[experiences, setExperiences] = useState([])
  const { jobId, recruiterId, company_id, refLink } = useParams();
  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState();
    const [idProfile, setIdProfile] = useState("");
  const [subSection, setSubSection] = useState("details");
  const [applying, setApplying] = useState(false);

  const { userData } = useSelector(({ auth }) => auth);
  const { resume } = useSelector(({ profile }) => profile);
  const path = history.location.pathname;
  const { job } = useSelector((state) => state.job);

  const requestToRecruit = async () => {
    recruitRequest(jobId);
  };
   

  useEffect(() => {
   console.log(id)
    if (id) {
      getOtherUserAction(dispatch, id);
      logProfileVisits(id);
    }
 }, [dispatch, id]);
 
  const refCode = userData.refCode;
  useEffect(() => {
    setLoading(true);
    Promise.resolve(
      getEitherJob(
        dispatch,
        jobId,
        path.includes("recommended") ? true : isBusiness
      )
    ).finally(() => setLoading(false));
  }, [dispatch, jobId]);

  useEffect(() => {
    if (pathname.includes("apply") || pathname.includes("recommended")) {
      setSection("apply");
    } else if (pathname.includes("refer")) {
      setSection("refer");
    } else {
      setSection("details");
    }
  }, [pathname]);

  const saveJob = (jobId) => {
    saveJobAction(dispatch, jobId);
  };
  const viewJobDetails = () => {
    history.push(`/home/${jobId}`);
    setSection("details");
    setSubSection("details");
  };

  const saveForLater = () => {
    const payload = {
      job_id: jobId,
      status: 1,
      refCode,
    };
    const saveLater = async () => {
      await saveBusinessRef(dispatch, 1, payload);
    };
    if (localStorage.getItem("accountType") === "business") {
      saveJob(jobId);
    } else {
      saveLater();
    }
  };
  const viewDocument = () => {
    history.push(`/home/${jobId}`);
    setSection("details");
    setSubSection("documents");
  };
  const applyToJob = () => {
    if(!profileData?.first_name ||
      !profileData?.about ||
      !profileData?.resume ||
      !profileData?.last_name ||
      !profileData?.gender ||
      !profileData?.dob ||
      !profileData.country ||
     !profileData.location|| !profileData.experience) {
     cogoToast.error("Please complete your profile")
     return
      }
    setApplying(true);
    const refCode = refLink ? refLink : null;
    const recruitId = recruiterId ? recruiterId : null;
    const resumeLink =
      resume && resume.link && resume.name
        ? { resume: resume.link, resumeName: resume.name }
        : null;
    // if (resume && resume.link && resume.userId === userData.id) {
    if (resumeLink && userData.id) {
      
      const data = {
        about: userData.about,
        country: userData.country,
        dob: userData.dob,
        first_name: userData.first_name,
        gender: userData.gender,
        image_url: userData.image_url,
        industry: userData.industry,
        isShowOnProfile: userData.isShowOnProfile,
        last_name: userData.last_name,
        links: userData.links,
        phone: userData.phone,
        resume: resumeLink.resume,
        resumeName: resumeLink.resumeName,
        state: userData.state,
      };
      Promise.all([
        updateProfileAction(data, dispatch),
        recruitId && recruitId.length < 5
          ? applyToRecruiterJob(dispatch, job.id, recruitId, () =>
              history.push("/insights/applications")
            )
          : applyAction(
              dispatch,
              job.id,
              refCode,
              company_id || job.company_id,
              () => history.push("/insights/applications")
            ),
      ]).finally(() => {
        setApplying(false);
        // history.push("/home/");
      });
    } else {
      Promise.resolve(
        recruitId && recruitId.length < 5
          ? applyToRecruiterJob(dispatch, job.id, recruitId, () =>
              history.push("/insights/applications")
            )
          : applyAction(
              dispatch,
              job.id,
              refCode,
              company_id || job.company_id,
              () => history.push("/insights/applications")
            )
      ).finally(() => {
        setApplying(false);
        // history.push("/home/");
      });
    }
  };

  return (
    <div className="position-relative">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="View details"
        PaperProps={{
          style: {
            width: "95%",
            maxWidth: "50rem",
            borderRadius: "1rem",
            padding: "0.75rem",
          },
        }}
      >
        {!job && loading && (
          <div className="min-height-25">
            <SectionLoader />
          </div>
        )}

        {job && !loading && (
          <>
            <div className="d-flex justify-content-between align-items-centter">
              <DialogTitle id="details-dialog-title">
                <div>
                  <h2 className="mb-0">{job.role_title}</h2>
                  <p className="mb-0 co-darkgray">{job.campany_name}</p>
                </div>
              </DialogTitle>
              <div className="close-button">
                <IconButton onClick={handleClose}>
                  <svg width="30" height="30" className="rounded-circle">
                    <use href={`${icons}#close`}></use>
                  </svg>
                </IconButton>
              </div>
            </div>
            <div className="position-relative sticky">
              <div className="bg-white w-100 position-absolute">
                <div className="d-flex px-4 justify-content-start">
                  <button
                    onClick={viewJobDetails}
                    className="co-white px-4 py-2 mr-2 fw-bold border-0 bg-primary rounded"
                  >
                    Job Description
                  </button>
                  <button
                    onClick={viewDocument}
                    className="co-white px-4 py-2 fw-bold border-0 bg-darkgrey rounded"
                  >
                    Additional Documents
                  </button>
                </div>

                <div className="d-flex justify-content-start my-3">
                  <span className="ml-4 mr-2 co-gray d-block">
                    posted {moment(job.date_created).fromNow()}
                  </span>
                </div>

                <div className="d-flex justify-content-start my-3">
                  <span className="ml-4 mr-2">
                    <svg width="17" height="16" className="mr-1">
                      <use href={`${icons}#briefcase`}></use>
                    </svg>
                    {job.job_level && job.job_level.title}
                  </span>
                  <span className="mr-2">
                    <svg width="14" height="14" className="mr-1">
                      <use href={`${icons}#mappin`}></use>
                    </svg>
                    {job.location}
                  </span>
                  <span className="mr-2">
                    <svg width="24" height="17" className="mr-1">
                      <use href={`${icons}#persons`}></use>
                    </svg>
                    {job.no_of_staff} workers
                  </span>
                </div>
                <Divider />
              </div>
            </div>

            <DialogContent>
              <div className="mt-3">
                {section === "details" && (
                  <ViewDetails job={job} section={subSection} />
                )}
                {section === "refer" && (
                  <Refer job={job} isBusiness={isBusiness} />
                )}
                {section === "apply" && <Apply />}
              </div>

              {job.isSaved == 0 && (
                <div className="d-flex justify-content-end my-1">
                  <div className="w-50 d-flex align-items-center justify-content-end">
                    <span className="co-green mr-1">
                      <b>Save for later</b>
                    </span>
                    <button
                      type="button"
                      onClick={() => saveJob(job.id)}
                      className="borderless bg-white"
                    >
                      <svg width="42" height="42" className="mr-1">
                        <use href={`${icons}#save`}></use>
                      </svg>
                    </button>
                  </div>
                </div>
              )}
              {job.isSaved == 1 && (
                <div className="d-flex justify-content-end my-1">
                  <div className="w-50 d-flex align-items-center justify-content-end">
                    <span className="co-green mr-1">
                      <b>Saved</b>
                    </span>
                  </div>
                </div>
              )}
            </DialogContent>
            {!hideActions && (
              <DialogActions>
                {section === "details" && (
                  <>
                    <CustomButton
                      backgroundColor="#41B883"
                      handleClick={() => history.push(`/home/${jobId}/refer`)}
                      color="#fff"
                      text="Refer"
                      additionalClass="mx-3 mt-2"
                    />

                    {!isBusiness && (
                      <CustomButton
                        handleClick={() => {
                          history.push(`/home/${jobId}/apply`);
                          const state = {
                            ["See more details of job here"]: false,
                            ["Apply here"]: false,
                          };
                          demoAction(dispatch, state);
                        }}
                        backgroundColor="#3A6ED4"
                        color="#fff"
                        disabled={applying}
                        text="Apply"
                        additionalClass="mx-3 mt-2"
                      />
                    )}
                  </>
                )}
                {section === "apply" && (
                  <div className="d-flex flex-column w-100 text-center">
                    {(!userData.first_name ||
                      !userData.first_name ||
                      !userData.resume) && (
                      <div style={{ color: "red" }}>
                        {" "}
                        <span>
                          <Link to="/profile">
                            Complete your profile before you can apply
                          </Link>
                        </span>
                      </div>
                    )}

                    <CustomButton
                      handleClick={() => applyToJob()}
                      backgroundColor="#3A6ED4"
                      color="#fff"
                      text={applying ? <ButtonLoader /> : <span>Apply</span>}
                      additionalClass="mx-3 mt-2"
                      disabled={
                        !userData.first_name ||
                        !userData.phone ||
                        !(
                          userData.resume ||
                          (resume && resume.link && resume.name)
                        )
                      }
                    />
                  </div>
                )}
                {isBusiness && (
                  <CustomButton
                    backgroundColor="#41B883"
                    handleClick={saveForLater}
                    color="#fff"
                    text="Save for Later"
                    additionalClass="mx-3 mt-2"
                    loading={loading}
                  />
                )}

                {userData.isRecruiter === 1 &&
                  job.canRecruiterRequest === "1" && (
                    <CustomButton
                      backgroundColor="#41B883"
                      handleClick={requestToRecruit}
                      color="#fff"
                      text="Request to Recruit"
                      additionalClass="mx-3 mt-2"
                      loading={loading}
                    />
                  )}
              </DialogActions>
            )}
          </>
        )}
      </Dialog>
    </div>
  );
};

HomeModal.propTypes = {
  success: bool,
  setSuccess: func,
  open: bool.isRequired,
  handleClose: func.isRequired,
  section: string.isRequired,
  setSection: func.isRequired,
  job: object,
  hideActions: bool,
};

HomeModal.defaultProps = {
  job: {},
  success: false,
  setSuccess: () => {},
  hideActions: false,
};

export default HomeModal;
