/* eslint-disable react/prop-types */
import React from "react";
import Card from "../../../../../components/Card";
import icons from "../../../../../assets/icons/icon-collection.svg";
import {
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Checkbox,
  DialogActions,
  FormControlLabel,
  DialogContent,
  DialogTitle,
  Dialog,
  FormGroup,
} from "@material-ui/core";
import CustomButton from "../../../../../components/CustomButton";
import { Link } from "react-router-dom";
// import { useHistory } from "react-router-dom";
import { openRecruiterModal } from "../../../../../redux/actions/demoActions";
import { useDispatch } from "react-redux";

const ReferralJobCard = ({
  job,
  handleClick,
  anchorEl,
  handleClose,
  openDialog,
  closeDialog,
  tooLong,
  handleChange,
  noApplication,
  notAccepting,
  other,
  open,
}) => {
  // const history = useHistory();
  const dispatch = useDispatch();
  return (
    <Card key={job.id} noPadding additionalClass="p-0 mb-4">
      <div className="d-flex align-items-center status-sent px-3 py-2">
        <span className="mr-2">
          <svg width="13" height="13">
            <use href={`${icons}#elipse`}></use>
          </svg>
        </span>
        {job.job_applicants} Applicants
      </div>
      <div className="mx-4 py-3">
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex flex-column">
            {job.role_title}
            <span className="co-gray small-text">{job.role_title}</span>
          </div>
          <IconButton onClick={handleClick}>
            <svg width="20" height="15">
              <use href={`${icons}#dots`}></use>
            </svg>
          </IconButton>
        </div>

        <Menu
          id="profile-menu"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom" }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "15rem",
              marginTop: "0.2rem",
              borderRadius: "1rem",
              boxShadow: "0px 0px 5px -3px rgba(0,0,0,0.2)",
            },
          }}
        >
          <MenuItem className="px-4 py-2 medium-text" onClick={handleClose}>
            Edit this Job
          </MenuItem>
          <MenuItem
            className="px-4 py-2 medium-text co-lightred"
            onClick={openDialog}
          >
            Close this job
          </MenuItem>
          <Divider />
          <MenuItem
            className="px-4 py-3 co-lightred medium-text"
            onClick={handleClose}
          >
            Cancel
          </MenuItem>
        </Menu>
        <Dialog
          open={open}
          onClose={closeDialog}
          aria-labelledby="close-job"
          PaperProps={{
            style: {
              width: "30rem",
              borderRadius: "1rem",
              padding: "0.75rem",
            },
          }}
        >
          <div className="d-flex justify-content-between align-items-centter">
            <DialogTitle id="form-dialog-title">
              <span className="co-red">Close this job</span>
            </DialogTitle>
            <div className="close-button">
              <IconButton onClick={closeDialog}>
                <svg width="30" height="30" className="rounded-circle">
                  <use href={`${icons}#close`}></use>
                </svg>
              </IconButton>
            </div>
          </div>
          <DialogContent>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={tooLong}
                    onChange={handleChange}
                    name="tooLong"
                  />
                }
                label="The process is taking too long"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={noApplication}
                    onChange={handleChange}
                    name="noApplication"
                  />
                }
                label="I haven't received any application"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={notAccepting}
                    onChange={handleChange}
                    name="notAccepting"
                  />
                }
                label="No longer accepting applications"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={other}
                    onChange={handleChange}
                    name="other"
                  />
                }
                label="other"
              />
            </FormGroup>
          </DialogContent>
          <DialogActions className="mt-5">
            <span className="w-25 mr-4">
              <button className="w-40 co-green borderless bg-white">
                <b>Submit</b>
              </button>
            </span>
          </DialogActions>
        </Dialog>

        <div className="w-100 mt-3 d-flex flex-column flex-sm-row align-items-md-center justify-content-md-between">
          <Link to={`jobs/applicants/${job.id}`} className="w-50 co-primary">
            View All Applicants
          </Link>
          <div className="w-50">
            <CustomButton
              backgroundColor="#3A6ED4"
              text="Hire a Recruiter"
              color="#fff"
              borderColor="#3A6ED4"
              additionalClass="w-100"
              handleClick={() => {
                openRecruiterModal(dispatch);
                // history.push({ pathname: "/recruiter/hire", state: { job } })
              }}
            />
          </div>
        </div>

        <div className="w-100 mt-3 d-flex flex-column flex-sm-row align-items-md-center justify-content-md-between">
          <Link
            to={{ pathname: `jobs/requests/${job.id}`, state: { job } }}
            className="w-50 co-primary"
          >
            See Recruiting Requests
          </Link>
        </div>
      </div>
    </Card>
  );
};

export default ReferralJobCard;
