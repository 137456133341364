import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@material-ui/core";
import Layout from "../../../layout";
import TabPanel from "../../../components/TabPanel";
import Referrals from "./Referrals";
// import Applications from "./Applications";
import Recommended from "./Recommended";

import AllJobs from "./AllJobs";
import SearchResult from "./SearchResult";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";

// import MyJobs from './MyJobs';
import "./index.scss";
import Joyride, { STATUS } from "react-joyride";

const Home = () => {
  const [value, setValue] = useState(0);
  const { searchedTerm } = useSelector(({ job }) => job);
  const [showIntro, setShowIntro] = useState(true);

  const [toursteps, setTourSteps] = useState([
    {
      target: ".joyride-post-job",
      content: "Publish a new job to referral or recruiter marketplaces",
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: ".joyride-all-jobs",
      content:
        "See all available jobs. Refer, Apply, Save or Request recruiter services",
      placement: "bottom",
    },
    {
      target: ".joyride-recommended-jobs",
      content: "See jobs we think  may catch your attention",
      placement: "bottom",
    },

    {
      target: ".joyride-refer-candidate",
      content: "To refer a candidate to a Job and earn if they get hired",
    },
    {
      target: ".joyride-request-apply-candidate",
      content: "To Apply to a Job, Simple Click here",
    },
    {
      target: ".joyride-become-recruiter",
      content:
        "Your first step in joining the largest network of recruiters in the world!",
    },
  ]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    //alert(localStorage.getItem('showintro'));
    setShowIntro(localStorage.getItem("showintro"));
  });

  return (
    <Layout>
      <ReactTooltip delayHide={1000} />
      {searchedTerm && <SearchResult />}
      {!searchedTerm && (
        <>
          {/*
          showIntro == "true" || showIntro==null? 
        <Joyride
            steps={toursteps}
            continuous={true}
            
            callback={({ status }) => {
              if (([STATUS.FINISHED, STATUS.SKIPPED]).includes(status)) {
                    window.localStorage.setItem('showintro', 'false');
              }
            }}
            styles={{
              options: {
                arrowColor: '#e3ffeb',
                primaryColor: '#3A6ED4',
                zIndex: 1000,
              }
            }}
            spotlightClicks={true} 
            showStepsProgress={true}
            showSkipButton={true} 
          />
        :
          <span></span>
          */}

          <Tabs indicatorColor="primary" value={value} onChange={handleChange}>
            <Tab
              label="All Jobs"
              data-tip="See all available jobs. Refer, Apply, Save or Request recruiter services.  In order to see country specific jobs, kindly update your profile"
              data-place="bottom"
            />
            <Tab
              label="Recommended Jobs"
              data-tip="See jobs we think  may catch your attention"
              data-place="bottom"
            />
            {/* <Tab label="My Applications" /> */}
            <Tab
              label="WB Business Referral"
              data-tip="See your private referral jobs shared by your contacts at specific organizations"
              data-place="bottom"
            />
          </Tabs>
          <TabPanel value={value} index={0}>
            <AllJobs />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Recommended />
          </TabPanel>
          {/* <TabPanel value={value} index={2}>
              <Applications />
            </TabPanel> */}
          <TabPanel value={value} index={3}>
            <Referrals />
          </TabPanel>
        </>
      )}
    </Layout>
  );
};

export default Home;
