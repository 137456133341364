import React, { useState } from "react";
import { useDispatch } from "react-redux";
import CustomButton from "../../../../components/CustomButton";
import CustomInput from "../../../../components/CustomInput";
import { addBankDetails } from "../../../../redux/actions/addBankAction";

const Bank = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    bank_code: "",
    account: "",
    account_type: "business",
    type:"paystack"
  });
  
  const handleChange = ({ target: { value, name } }) => {
    const newValue = {};
    newValue[name] = value;
    setState({ ...state, ...newValue });
  };
   
  const handleSubmit = (e) => {
    e.preventDefault();
    addBankDetails(dispatch, state);
  };
  
  return (
    <div>
      <h6 className="bolder-text">Add Bank Details</h6>
      <form className="mt-4" onSubmit={handleSubmit}>
      
       {/* <CustomInput
          name="bank_code"
          onChange={handleChange}
        
          label="Select Bank"
          id="role"
          isRequired
        /> */}

        <select className="form-control"     onChange={handleChange}   name="bank_code">
        <option selected>Choose</option>
                      <option value="801"> Abbey Mortgage Bank </option>
                <option value="044"> Access Bank </option>
                <option value="063"> Access Bank (Diamond) </option>
                <option value="035A"> ALAT by WEMA </option>
                <option value="50926"> Amju Unique MFB </option>
                <option value="401"> ASO Savings and Loans </option>
                <option value="51229"> Bainescredit MFB </option>
                <option value="50931"> Bowen Microfinance Bank </option>
                <option value="565"> Carbon </option>
                <option value="50823"> CEMCS Microfinance Bank </option>
                <option value="023"> Citibank Nigeria </option>
                <option value="559"> Coronation Merchant Bank </option>
                <option value="050"> Ecobank Nigeria </option>
                <option value="562"> Ekondo Microfinance Bank </option>
                <option value="50126"> Eyowo </option>
                <option value="070"> Fidelity Bank </option>
                <option value="51314"> Firmus MFB </option>
                <option value="011"> First Bank of Nigeria </option>
                <option value="214"> First City Monument Bank </option>
                <option value="501"> FSDH Merchant Bank Limited </option>
                <option value="00103"> Globus Bank </option>
                <option value="232"> GoMoney </option>
                <option value="058"> Guaranty Trust Bank </option>
                <option value="51251"> Hackman Microfinance Bank </option>
                <option value="50383"> Hasal Microfinance Bank </option>
                <option value="030"> Heritage Bank </option>
                <option value="51244"> Ibile Microfinance Bank </option>
                <option value="50457"> Infinity MFB </option>
                <option value="301"> Jaiz Bank </option>
                <option value="082"> Keystone Bank </option>
                <option value="50200"> Kredi Money MFB LTD </option>
                <option value="50211"> Kuda Bank </option>
                <option value="90052"> Lagos Building Investment Company Plc. </option>
                <option value="50549"> Links MFB </option>
                <option value="50563"> Mayfair MFB </option>
                <option value="50304"> Mint MFB </option>
                <option value="999991"> PalmPay </option>
                <option value="526"> Parallex Bank </option>
                <option value="311"> Parkway - ReadyCash </option>
                <option value="999992"> Paycom </option>
                <option value="50746"> Petra Mircofinance Bank Plc </option>
                <option value="076"> Polaris Bank </option>
                <option value="101"> Providus Bank </option>
                <option value="502"> Rand Merchant Bank </option>
                <option value="125"> Rubies MFB </option>
                <option value="51310"> Sparkle Microfinance Bank </option>
                <option value="221"> Stanbic IBTC Bank </option>
                <option value="068"> Standard Chartered Bank </option>
                <option value="232"> Sterling Bank </option>
                <option value="100"> Suntrust Bank </option>
                <option value="302"> TAJ Bank </option>
                <option value="51211"> TCF MFB </option>
                <option value="102"> Titan Bank </option>
                <option value="032"> Union Bank of Nigeria </option>
                <option value="033"> United Bank For Africa </option>
                <option value="215"> Unity Bank </option>
                <option value="566"> VFD Microfinance Bank Limited </option>
                <option value="035"> Wema Bank </option>
                <option value="057"> Zenith Bank </option>
        </select>

        <CustomInput
          type="number"
          name="account"
          label="Account Number"
          id="name"
          onChange={handleChange}
          isRequired
        />
        
        <CustomButton
          type="submit"
          additionalClass="my-5"
          text="Save"
          backgroundColor="#3A6ED4"
          color="#fff"
        />
      </form>
    </div>
  );
};

export default Bank;
