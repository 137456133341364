import React, {  useState } from "react";
import CustomButton from "../../../../components/CustomButton";
import SlidingPane from "react-sliding-pane";
import TermsAndConditions from "../../../../components/TermsAndConditions";
import './about.css';
const About = () => {

  const [termsofuse, setTermsOfUse] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });

  return (
    <div>
      <h6 className="bolder-text">About workbrook</h6>
      <p className="small-text co-darkgray mt-3">
        No work is left undone anywhere in the world. workbrook gives you an
        opportunity to share your work needs with people across the world, share
        opportunities with your contacts on the leading social networking
        platforms and open a door of opportunities to your next big move!
        workbrook matches extraordinary people with great opportunities!
      </p>
      <CustomButton
        leftALign
        additionalClass="my-3"
        text="Data Policy"
        handleClick={() =>
          window.open('https://workbrook.com/workbrook-privacy-policy/', '_blank')}
        backgroundColor="#3A6ED4"
        color="#fff"
      />
      <CustomButton
        leftALign
        additionalClass="my-3"
        text="Terms of Use"
        backgroundColor="#3A6ED4"
        handleClick = {()=>{
          setTermsOfUse({ isPaneOpen: true })
        }}
        color="#fff"
        additionalClass={{ alignItems: "center" }}
      />

    <SlidingPane
          className="sliderx"
          overlayClassName="some-custom-overlay-class"
          isOpen={termsofuse.isPaneOpen}
          title="Terms of Use"
          subtitle="Workbrook"
          width="90%"
          from={'right'}
          onRequestClose={() => {
            // triggered on "<" on left top click or on outside click
            setTermsOfUse({ isPaneOpen: false });
          }}
        >
          <TermsAndConditions/>
    </SlidingPane>


    </div>
  );
};

export default About;
