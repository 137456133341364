import React, { useState, useEffect } from "react";
import CustomButton from "../../../../components/CustomButton";
import { IoPersonSharp } from "react-icons/io5";
import CustomTextArea from "../../../../components/CustomTextArea";
import { BsPeople, BsGraphUp } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";

import {
  getEmployee,
  getPerformance,
  postperformances,
  postsendback,
} from "../../../../redux/actions/jobActions";

function AdminApproval() {
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [activeTab, setActiveTab] = useState("Leadership");
  const [checkedall, setChecked] = useState(false);
  const [checkedPlan, setCheckedPlan] = useState(false);
  const [employees, setEmployees] = useState([]);
  const dispatch = useDispatch();
  const [employeeAppraisal, setEmployeeAppraisal] = useState([]);
  const [performanceData, setPerformanceData] = useState([]);

  const [sendEmployee, setSendEmployee] = useState({
    employee_id: "",
    employee_name: "",
    employee_goals: [],
  });

  useEffect(() => {
    getEmployee(dispatch)
      .then((action) => {
        setEmployees(action.payload.data);
      })
      .catch((error) => {
        console.error("Error fetching employees:", error);
      })
      .finally(() => {
        setTimeout(() => {}, 1000);
      });
  }, [dispatch]);
  const handleSendBack = () => {
    const sendData = {
      employee_id: sendEmployee.employee_id,
      // employee_name: sendEmployee.employee_name,
    };

    console.log(sendData);

    postsendback(dispatch, sendData);
  };
  const handleEmployeeChange = (e) => {
    const value = e.target.value;
    const employee = employees.find((all) => all.first_name === value);
    if (employee) {
      setSelectedEmployee(employee);
      setSendEmployee((prevSendEmployee) => ({
        ...prevSendEmployee,
        employee_id: employee.id,
        employee_name: employee.first_name,
      }));
      setTimeout(() => {
        getPerformance(dispatch, employee.id)
          .then((action) => {
            console.log(action.payload.data);

            const performanceData = JSON.parse(
              action.payload.data.employee_goals
            );

            const employeeAppraisal = JSON.parse(
              action.payload.data.employee_appraisal
            );
            console.log(employeeAppraisal);
            console.log(employeeAppraisal.type);

            setEmployeeAppraisal(employeeAppraisal);
            setPerformanceData(performanceData);
            console.log(performanceData);
          })
          .catch((error) => {
            console.error(error);
          });
      }, 1000);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="card-height" additionalClass="mt-4 p-4">
      <h5 style={{ fontWeight: "bold" }} className="co-grayx">
        HR Confirmation
      </h5>

      <div
        style={{ padding: "10px", maxHeight: "680px" }}
        className="col-12 col-md-12 list-achievements"
      >
        <div className="select-container mt-3">
          <strong>Select Employee to view</strong>
          <select
            style={{ border: "1px solid #e8e9eb" }}
            onChange={handleEmployeeChange}
          >
            <option value="">Select an Option</option>
            {employees &&
              employees.map((employee) => (
                <option key={employee.id} value={employee.first_name}>
                  {employee.first_name}
                </option>
              ))}
          </select>
        </div>

        <br />

        {selectedEmployee && (
          <>
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <button
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    activeTab === "Leadership" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Leadership")}
                  style={{
                    backgroundColor:
                      activeTab === "Leadership" ? "#dcdcdc" : "#3A6ED4",
                    color: "#fff",
                  }}
                >
                  <BsPeople className="mb-1" /> Leadership
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    activeTab === "Performance" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Performance")}
                  style={{
                    backgroundColor:
                      activeTab === "Performance" ? "#dcdcdc" : "#3A6ED4",
                    color: "#fff",
                    marginLeft: "2rem",
                  }}
                >
                  <BsGraphUp className="mb-1" /> Performance
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    activeTab === "Development" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Development")}
                  style={{
                    backgroundColor:
                      activeTab === "Development" ? "#dcdcdc" : "#3A6ED4",
                    color: "#fff",
                    marginLeft: "2rem",
                  }}
                >
                  <IoPersonSharp className="mb-1" /> Development
                </button>
              </li>
            </ul>
            <div className="tab-content mt-3">
              <div
                className={`tab-pane fade ${
                  activeTab === "Leadership" ? "show active" : ""
                }`}
                id="leadership-tab"
                style={{ marginTop: "7px" }}
              >
                {performanceData && performanceData.Leadership && (
                  <div>
                    {performanceData.Leadership.map((goal, index) => (
                      <div key={index}>
                        <strong>
                          {" "}
                          {sendEmployee.employee_name} Leadership
                          Goals/Objectives
                        </strong>
                        <CustomTextArea value={goal.goal_content} />
                      </div>
                    ))}
                  </div>
                )}
                <br />
                <br />
                {employeeAppraisal
                  .filter((item) => item.type === "Leadership")
                  .map((item, index) => (
                    <div>
                      <strong className="mt-5">
                        Summary of {sendEmployee.employee_name} Review
                        Comment(Free text)
                      </strong>
                      <CustomTextArea
                        value={item.employee_appraisal}
                        label=""
                      />
                    </div>
                  ))}
                <>
                  <br />
                  <br />
                  {performanceData && performanceData.Leadership && (
                    <div>
                      {performanceData.Leadership.map((goal, index) => (
                        <div key={index}>
                          <strong>Supervisor's Edited Goals</strong>
                          <CustomTextArea value={goal.supervisor_EditGoal} />
                        </div>
                      ))}
                    </div>
                  )}
                  <br />

                  <br />
                  <button
                    className="rounded-3 border border-none"
                    style={{
                      width: "25%",
                      color: "#fff",
                      height: "6vh",
                      borderRadius: "5px",
                      backgroundColor: "red",
                    }}
                    color="#fff"
                    text="Submit"
                    onClick={handleSendBack}
                  >
                    SendBack
                  </button>

                  <button
                    className="rounded-3 border border-none"
                    style={{
                      width: "25%",
                      color: "#fff",
                      height: "6vh",
                      borderRadius: "5px",
                      backgroundColor: "#3A6ED4",
                    }}
                    color="#fff"
                    text="Submit"
                  >
                    Confirm Submission
                  </button>
                </>
              </div>
              <div
                className={`tab-pane fade ${
                  activeTab === "Performance" ? "show active" : ""
                }`}
                id="performance-tab"
                style={{ marginTop: "7px" }}
              >
                {performanceData && performanceData.Performance && (
                  <div>
                    {performanceData.Performance.map((goal, index) => (
                      <div key={index}>
                        <strong>
                          {" "}
                          {sendEmployee.employee_name} Perfomance
                          Goals/Objectives
                        </strong>
                        <CustomTextArea value={goal.goal_content} />
                      </div>
                    ))}
                  </div>
                )}
                <br />
                <br />
                {employeeAppraisal
                  .filter((item) => item.type === "Performance")
                  .map((item, index) => (
                    <div>
                      <strong className="mt-5">
                        Summary of {sendEmployee.employee_name} Review
                        Comment(Free text)
                      </strong>
                      <CustomTextArea
                        value={item.employee_appraisal}
                        label=""
                      />
                    </div>
                  ))}
                <>
                  <br />
                  {performanceData && performanceData.Performance && (
                    <div>
                      {performanceData.Performance.map((goal, index) => (
                        <div key={index}>
                          <strong>Supervisor's Edited Goals</strong>
                          <CustomTextArea value={goal.supervisor_EditGoal} />
                        </div>
                      ))}
                    </div>
                  )}
                  <br />
                  <>
                    <br />
                  </>
                  <button
                    className="rounded-3 border border-none"
                    style={{
                      width: "25%",
                      color: "#fff",
                      height: "6vh",
                      borderRadius: "5px",
                      backgroundColor: "red",
                    }}
                    color="#fff"
                    text="Submit"
                    onClick={handleSendBack}
                  >
                    SendBack
                  </button>

                  <button
                    className="rounded-3 border border-none"
                    style={{
                      width: "25%",
                      color: "#fff",
                      height: "6vh",
                      borderRadius: "5px",
                      backgroundColor: "#3A6ED4",
                    }}
                    color="#fff"
                    text="Submit"
                  >
                    Confirm Submission
                  </button>
                </>
              </div>
              <div
                className={`tab-pane fade ${
                  activeTab === "Development" ? "show active" : ""
                }`}
                id="development-tab"
                style={{ marginTop: "7px" }}
              >
                {performanceData && performanceData.Development && (
                  <div>
                    {performanceData.Development.map((goal, index) => (
                      <div key={index}>
                        <strong>
                          {" "}
                          {sendEmployee.employee_name} Development
                          Goals/Objectives
                        </strong>
                        <CustomTextArea value={goal.goal_content} />
                      </div>
                    ))}
                  </div>
                )}
                <br />
                <br />
                {employeeAppraisal
                  .filter((item) => item.type === "Development")
                  .map((item, index) => (
                    <div>
                      <strong className="mt-5">
                        Summary of {sendEmployee.employee_name} Review
                        Comment(Free text)
                      </strong>
                      <CustomTextArea
                        value={item.employee_appraisal}
                        label=""
                      />
                    </div>
                  ))}
                <br />
                {performanceData && performanceData.Development && (
                  <div>
                    {performanceData.Development.map((goal, index) => (
                      <div key={index}>
                        <strong>Supervisor's Edited Goals</strong>
                        <CustomTextArea value={goal.supervisor_EditGoal} />
                      </div>
                    ))}
                  </div>
                )}
                <br />
                <>
                  <br />
                </>
                <button
                  className="rounded-3 border border-none"
                  style={{
                    width: "25%",
                    color: "#fff",
                    height: "6vh",
                    borderRadius: "5px",
                    backgroundColor: "red",
                  }}
                  color="#fff"
                  text="Submit"
                  onClick={handleSendBack}
                >
                  SendBack
                </button>

                <button
                  className="rounded-3 border border-none"
                  style={{
                    width: "25%",
                    color: "#fff",
                    height: "6vh",
                    borderRadius: "5px",
                    backgroundColor: "#3A6ED4",
                  }}
                  color="#fff"
                  text="Submit"
                >
                  Confirm Submission
                </button>
              </div>
            </div>
          </>
        )}

        <br />
        <br />
      </div>
    </div>
  );
}

export default AdminApproval;
