import React, { useState, useEffect } from "react";
import CustomButton from "../../../../components/CustomButton";
import { IoPersonSharp } from "react-icons/io5";
// import { Toast, toast } from "react-toastify";
import CustomTextArea from "../../../../components/CustomTextArea";
import { BsPeople, BsGraphUp } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";

import {
  getEmployee,
  getPerformance,
  postperformances,
  postsendback,
} from "../../../../redux/actions/jobActions";

function SupervisorReward() {
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [activeTab, setActiveTab] = useState("Leadership");
  const [checkedall, setChecked] = useState(false);
  const [checkedPlan, setCheckedPlan] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [goalSubmit, setGoalSubmit] = useState(false);
  const [performanceData, setPerformanceData] = useState([]);

  const [employeeAppraisal, setEmployeeAppraisal] = useState([]);
  const dispatch = useDispatch();
  const [sendEmployee, setSendEmployee] = useState({
    employee_id: "",
    employee_name: "",
    employee_goals: [],
  });
  const [Succession, setSuccession] = useState({
    Leadership: "",
  });
  const [Successionn, setSuccessionn] = useState({
    Performance: "",
  });
  const [Successions, setSuccessions] = useState({
    Development: "",
  });
  const [leadershipImprove, setLeadershipImprove] = useState({
    Leadership: "",
  });
  const [performanceImprove, setPerformanceImprove] = useState({
    Performance: "",
  });
  const [developmentImprove, setDevelopmentImprove] = useState({
    Development: "",
  });
  useEffect(() => {
    getEmployee(dispatch)
      .then((action) => {
        setEmployees(action.payload.data);
        // Now you can use employeesData as your fetched data
      })
      .catch((error) => {
        console.error("Error fetching employees:", error);
      })
      .finally(() => {
        // This code inside finally block will execute regardless of whether the promise was resolved or rejected
        setTimeout(() => {}, 1000); // Add your code here if needed
      });
  }, [dispatch]);
  const handleEmployeeChange = (e) => {
    const value = e.target.value;
    const employee = employees.find((all) => all.first_name === value);
    if (employee) {
      setSelectedEmployee(employee);
      setSendEmployee((prevSendEmployee) => ({
        ...prevSendEmployee,
        employee_id: employee.id,
        employee_name: employee.first_name,
      }));
      setTimeout(() => {
        getPerformance(dispatch, employee.id)
          .then((action) => {
            console.log(action.payload.data);

            const performanceData = JSON.parse(
              action.payload.data.employee_goals
            );

            const employeeAppraisal = JSON.parse(
              action.payload.data.employee_appraisal
            );
            console.log(employeeAppraisal);
            console.log(employeeAppraisal.type);

            setEmployeeAppraisal(employeeAppraisal);
            setPerformanceData(performanceData);
            console.log(performanceData);
          })
          .catch((error) => {
            console.error(error);
          });
      }, 1000);
    }
  };
  const handleSubmit = () => {
    const formDataa = {
      Leadership: performanceData.Leadership.map((goal) => ({
        ...goal,
        Succession: Succession.Leadership,
        Improve_Performance: leadershipImprove.Leadership,
      })),
      Performance: performanceData.Performance.map((goal) => ({
        ...goal,
        Succession: Successionn.Performance,
        Improve_Performance: performanceImprove.Performance,
      })),
      Development: performanceData.Development.map((goal) => ({
        ...goal,
        Succession: Successions.Development,
        Improve_Performance: developmentImprove.Development,
      })),
    };
    setGoalSubmit(true);
    localStorage.setItem("formDataa", JSON.stringify(formDataa));
    // toast.success("Saved Successfully");

    console.log(formDataa);
  };
  const handleSendBack = () => {
    const sendData = {
      employee_id: sendEmployee.employee_id,
      // employee_name: sendEmployee.employee_name,
    };

    console.log(sendData);

    postsendback(dispatch, sendData);
  };

  const handleAll = () => {
    const superv = JSON.parse(localStorage.getItem("formDataa")) || {};
    const appraisalData = JSON.parse(localStorage.getItem("appraisalData"));
    const sendData = {
      employee_id: sendEmployee.employee_id,
      employee_name: sendEmployee.employee_name,
      employee_appraisal: JSON.stringify(appraisalData),

      employee_goals: JSON.stringify(superv),
    };
    console.log(superv);
    console.log(sendData);

    postperformances(dispatch, sendData);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSuccession((prevSuccession) => ({
      ...prevSuccession,
      [name]: value,
    }));
  };
  const handleChanges = (e) => {
    const { name, value } = e.target;
    setSuccessions((prevSuccessions) => ({
      ...prevSuccessions,
      [name]: value,
    }));
  };
  const handleChangee = (e) => {
    const { name, value } = e.target;
    setSuccessionn((prevSuccessionn) => ({
      ...prevSuccessionn,
      [name]: value,
    }));
  };
  const handleChangesImprove = (e) => {
    const { name, value } = e.target;
    setDevelopmentImprove((prevDevelopmentImprove) => ({
      ...prevDevelopmentImprove,
      [name]: value,
    }));
  };
  const handleChangeImprove = (e) => {
    const { name, value } = e.target;
    setLeadershipImprove((prevLeadershipImprove) => ({
      ...prevLeadershipImprove,
      [name]: value,
    }));
  };
  const handleChangeeImprove = (e) => {
    const { name, value } = e.target;
    setPerformanceImprove((prevPerformanceImprove) => ({
      ...prevPerformanceImprove,
      [name]: value,
    }));
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="card-height" additionalClass="mt-4 p-4">
      <h5 style={{ fontWeight: "bold" }} className="co-grayx">
        Supervisor Reward of Performance
      </h5>

      <div
        style={{ padding: "10px", maxHeight: "680px" }}
        className="col-12 col-md-12 list-achievements"
      >
        <div className="select-container mt-3">
          <strong>Select Employee to view</strong>
          <select
            style={{ border: "1px solid #e8e9eb" }}
            onChange={handleEmployeeChange}
          >
            <option value="">Select an Option</option>
            {employees &&
              employees.map((employee) => (
                <option key={employee.id} value={employee.first_name}>
                  {employee.first_name}
                </option>
              ))}
          </select>
        </div>

        <br />

        {selectedEmployee && (
          <>
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <button
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    activeTab === "Leadership" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Leadership")}
                  style={{
                    backgroundColor:
                      activeTab === "Leadership" ? "#dcdcdc" : "#3A6ED4",
                    color: "#fff",
                  }}
                >
                  <BsPeople className="mb-1" /> Leadership
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    activeTab === "Performance" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Performance")}
                  style={{
                    backgroundColor:
                      activeTab === "Performance" ? "#dcdcdc" : "#3A6ED4",
                    color: "#fff",
                    marginLeft: "2rem",
                  }}
                >
                  <BsGraphUp className="mb-1" /> Performance
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    activeTab === "Development" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Development")}
                  style={{
                    backgroundColor:
                      activeTab === "Development" ? "#dcdcdc" : "#3A6ED4",
                    color: "#fff",
                    marginLeft: "2rem",
                  }}
                >
                  <IoPersonSharp className="mb-1" /> Development
                </button>
              </li>
            </ul>
            <div className="tab-content mt-3">
              <div
                className={`tab-pane fade ${
                  activeTab === "Leadership" ? "show active" : ""
                }`}
                id="leadership-tab"
                style={{ marginTop: "7px" }}
              >
                {performanceData && performanceData.Leadership && (
                  <div>
                    {performanceData.Leadership.map((goal, index) => (
                      <div key={index}>
                        <strong>
                          {" "}
                          {sendEmployee.employee_name} Leadership
                          Goals/Objectives
                        </strong>
                        <CustomTextArea value={goal.goal_content} />
                        <br />
                      </div>
                    ))}
                  </div>
                )}
                <br />
                <br />
                {employeeAppraisal
                  .filter((item) => item.type === "Leadership")
                  .map((item, index) => (
                    <div>
                      <strong className="mt-5">
                        Summary of {sendEmployee.employee_name} Review
                        Comment(Free text)
                      </strong>
                      <CustomTextArea
                        value={item.employee_appraisal}
                        label=""
                      />
                    </div>
                  ))}
                <>
                  <br />
                  <br />
                  <div>
                    <strong>
                      Would you like to improve or reward Performance?
                    </strong>
                    <br />
                    <br />

                    <div>
                      <input
                        onChange={() => {
                          setChecked(true);
                          setCheckedPlan(false);
                        }}
                        style={{ marginTop: "3px" }}
                        type="checkbox"
                        checked={checkedall}
                      />
                      <strong>Improve Performance</strong>
                    </div>
                    <br />

                    <div>
                      <input
                        type="checkbox"
                        onChange={() => {
                          setCheckedPlan(true);
                          setChecked(false);
                        }}
                        checked={checkedPlan}
                      />
                      <strong>Reward Performance</strong>
                    </div>
                  </div>
                  <br />

                  {checkedall && (
                    <div>
                      <strong className="mt-5">
                        Performance Plan for {sendEmployee.employee_name}'s
                        Goals
                      </strong>
                      <CustomTextArea
                        name="Leadership"
                        value={leadershipImprove.Leadership}
                        onChange={handleChangeImprove}
                      />
                    </div>
                  )}
                  <br />

                  {checkedPlan && (
                    <div>
                      <strong className="mt-5">
                        Succession Plan for {sendEmployee.employee_name}'s Goals
                      </strong>
                      <CustomTextArea
                        name="Leadership"
                        value={Succession.Leadership}
                        onChange={handleChange}
                      />
                    </div>
                  )}

                  <br />
                  <br />
                  <button
                    className="rounded-3 border border-none"
                    style={{
                      width: "10%",
                      color: "#fff",
                      height: "6vh",
                      borderRadius: "5px",
                      backgroundColor: "red",
                    }}
                    color="#fff"
                    text="Submit"
                    onClick={handleSendBack}
                  >
                    SendBack
                  </button>
                  <button
                    className="rounded-3 border border-none"
                    style={{
                      width: "10%",
                      color: "#fff",
                      height: "6vh",
                      borderRadius: "5px",
                      backgroundColor: "#3A6ED4",
                    }}
                    color="#fff"
                    disabled={
                      !leadershipImprove.Leadership && !Succession.Leadership
                    }
                    text="Submit"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                </>
              </div>
              <div
                className={`tab-pane fade ${
                  activeTab === "Performance" ? "show active" : ""
                }`}
                id="performance-tab"
                style={{ marginTop: "7px" }}
              >
                {performanceData && performanceData.Performance && (
                  <div>
                    {performanceData.Performance.map((goal, index) => (
                      <div key={index}>
                        <strong>
                          {" "}
                          {sendEmployee.employee_name} Perfomance
                          Goals/Objectives
                        </strong>
                        <CustomTextArea value={goal.goal_content} />
                        <br />
                      </div>
                    ))}
                  </div>
                )}
                <br />
                <br />
                {employeeAppraisal
                  .filter((item) => item.type === "Performance")
                  .map((item, index) => (
                    <div>
                      <strong className="mt-5">
                        Summary of {sendEmployee.employee_name} Review
                        Comment(Free text)
                      </strong>
                      <CustomTextArea
                        value={item.employee_appraisal}
                        label=""
                      />
                    </div>
                  ))}
                <>
                  <br />
                  <br />
                  <div>
                    <strong>
                      Would you like to improve or reward Performance?
                    </strong>
                    <br />
                    <br />

                    <div>
                      <input
                        onChange={() => {
                          setChecked(true);
                          setCheckedPlan(false);
                        }}
                        style={{ marginTop: "3px" }}
                        type="checkbox"
                        checked={checkedall}
                      />
                      <strong>Improve Performance</strong>
                    </div>
                    <br />

                    <div>
                      <input
                        type="checkbox"
                        onChange={() => {
                          setCheckedPlan(true);
                          setChecked(false);
                        }}
                        checked={checkedPlan}
                      />
                      <strong>Reward Performance</strong>
                    </div>
                  </div>
                  <br />
                  <br />
                  {checkedall && (
                    <div>
                      <strong className="mt-5">
                        Performance Plan for {sendEmployee.employee_name}'s
                        Goals
                      </strong>
                      <CustomTextArea
                        name="Performance"
                        value={performanceImprove.Performance}
                        onChange={handleChangeeImprove}
                      />
                    </div>
                  )}
                  <br />
                  <br />

                  {checkedPlan && (
                    <div>
                      <strong className="mt-5">
                        Succession Plan for {sendEmployee.employee_name}'s Goals
                      </strong>
                      <CustomTextArea
                        name="Performance"
                        value={Successionn.Performance}
                        onChange={handleChangee}
                      />
                    </div>
                  )}

                  <br />
                  <br />
                  <button
                    className="rounded-3 border border-none"
                    style={{
                      width: "10%",
                      color: "#fff",
                      height: "6vh",
                      borderRadius: "5px",
                      backgroundColor: "red",
                    }}
                    color="#fff"
                    text="Submit"
                    onClick={handleSendBack}
                  >
                    SendBack
                  </button>
                  <button
                    className="rounded-3 border border-none"
                    style={{
                      width: "10%",
                      color: "#fff",
                      height: "6vh",
                      borderRadius: "5px",
                      backgroundColor: "#3A6ED4",
                    }}
                    color="#fff"
                    text="Submit"
                    disabled={
                      !performanceImprove.Performance &&
                      !Successionn.Performance
                    }
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                </>
              </div>
              <div
                className={`tab-pane fade ${
                  activeTab === "Development" ? "show active" : ""
                }`}
                id="development-tab"
                style={{ marginTop: "7px" }}
              >
                {performanceData && performanceData.Development && (
                  <div>
                    {performanceData.Development.map((goal, index) => (
                      <div key={index}>
                        <strong>
                          {" "}
                          {sendEmployee.employee_name} Development
                          Goals/Objectives
                        </strong>
                        <CustomTextArea value={goal.goal_content} />
                        <br />
                      </div>
                    ))}
                  </div>
                )}
                <br />
                <br />
                {employeeAppraisal
                  .filter((item) => item.type === "Development")
                  .map((item, index) => (
                    <div>
                      <strong className="mt-5">
                        Summary of {sendEmployee.employee_name} Review
                        Comment(Free text)
                      </strong>
                      <CustomTextArea
                        value={item.employee_appraisal}
                        label=""
                      />
                    </div>
                  ))}
                <>
                  <br />
                  <br />
                  <div>
                    <strong>
                      Would you like to improve or reward Performance?
                    </strong>
                    <br />
                    <br />

                    <div>
                      <input
                        onChange={() => {
                          setChecked(true);
                          setCheckedPlan(false);
                        }}
                        style={{ marginTop: "3px" }}
                        type="checkbox"
                        checked={checkedall}
                      />
                      <strong>Improve Performance</strong>
                    </div>
                    <br />

                    <div>
                      <input
                        type="checkbox"
                        onChange={() => {
                          setCheckedPlan(true);
                          setChecked(false);
                        }}
                        checked={checkedPlan}
                      />
                      <strong>Reward Performance</strong>
                    </div>
                  </div>
                  <br />
                  <br />
                  {checkedall && (
                    <div>
                      <strong className="mt-5">
                        Performance Plan for {sendEmployee.employee_name}'s
                        Goals
                      </strong>
                      <CustomTextArea
                        name="Development"
                        value={developmentImprove.Development}
                        onChange={handleChangesImprove}
                      />
                    </div>
                  )}
                  <br />
                  <br />

                  {checkedPlan && (
                    <div>
                      <strong className="mt-5">
                        Succession Plan for {sendEmployee.employee_name}'s Goals
                      </strong>
                      <CustomTextArea
                        name="Development"
                        value={Successions.Development}
                        onChange={handleChanges}
                      />
                    </div>
                  )}

                  <br />
                  <br />
                  <button
                    className="rounded-3 border border-none"
                    style={{
                      width: "10%",
                      color: "#fff",
                      height: "6vh",
                      borderRadius: "5px",
                      backgroundColor: "red",
                    }}
                    color="#fff"
                    text="Submit"
                    onClick={handleSendBack}
                  >
                    SendBack
                  </button>
                  {!goalSubmit ? (
                    <button
                      className="rounded-3 border border-none"
                      style={{
                        width: "10%",
                        color: "#fff",
                        height: "6vh",
                        borderRadius: "5px",
                        backgroundColor: "#3A6ED4",
                      }}
                      color="#fff"
                      text="Submit"
                      disabled={
                        !developmentImprove.Development &&
                        !Successions.Development
                      }
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      className="rounded-3 border border-none"
                      style={{
                        width: "10%",
                        color: "#fff",
                        height: "6vh",
                        borderRadius: "5px",
                        backgroundColor: "#3A6ED4",
                      }}
                      color="#fff"
                      text="Submit"
                      onClick={handleAll}
                    >
                      Submit
                    </button>
                  )}
                </>
              </div>
            </div>
          </>
        )}

        <br />
        <br />
      </div>
    </div>
  );
}

export default SupervisorReward;
