import React, { useState, useEffect } from "react";
import CustomButton from "../../../../components/CustomButton";
import { IoPersonSharp } from "react-icons/io5";
import { Toast, toast } from "react-toastify";
import cogoToast from "cogo-toast";
import CustomTextArea from "../../../../components/CustomTextArea";
import { BsPeople, BsGraphUp } from "react-icons/bs";

import { useDispatch, useSelector } from "react-redux";
import {
  getEmployee,
  getPerformance,
  postperformances,
  postsendback,
} from "../../../../redux/actions/jobActions";

function SupervisorReview() {
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [activeTab, setActiveTab] = useState("Leadership");
  const [checkedall, setChecked] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [checkedPlan, setCheckedPlan] = useState(false);
  const dispatch = useDispatch();
  const [goalSubmit, setGoalSubmit] = useState(false);
  const [performanceData, setPerformanceData] = useState([]);
  const [editable, seteditable] = useState(false);
  const [editablee, seteditablee] = useState(false);
  const [editables, seteditables] = useState(false);
  const [editableGoal, setEditableGoal] = useState(
    performanceData?.Leadership || []
  );
  const [editableGoall, setEditableGoall] = useState(
    performanceData?.Performance || []
  );
  const [editableGoals, setEditableGoals] = useState(
    performanceData?.Development || []
  );
  const [employeeAppraisal, setEmployeeAppraisal] = useState([]);
  const [supervisorComment, setSupervisorComment] = useState({
    Leadership: "",
  });
  const [supervisorCommentt, setSupervisorCommentt] = useState({
    Performance: "",
  });
  const [supervisorComments, setSupervisorComments] = useState({
    Development: "",
  });
  const [sendEmployee, setSendEmployee] = useState({
    employee_id: "",
    employee_name: "",
    employee_goals: [],
    employee_appraisal: [],
  });

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    if (performanceData) {
      setEditableGoal({
        Leadership: performanceData.Leadership || [],
      });
      setEditableGoall({
        Performance: performanceData.Performance || [],
      });
      setEditableGoals({
        Development: performanceData.Development || [],
      });
    }
  }, [performanceData]);
  useEffect(() => {
    getEmployee(dispatch)
      .then((action) => {
        setEmployees(action.payload.data);
      })
      .catch((error) => {
        console.error("Error fetching employees:", error);
      })
      .finally(() => {
        setTimeout(() => {}, 1000);
      });
  }, [dispatch]);
  const handleEmployeeChange = (e) => {
    const value = e.target.value;
    const employee = employees.find((all) => all.first_name === value);
    if (employee) {
      setSelectedEmployee(employee);
      setSendEmployee((prevSendEmployee) => ({
        ...prevSendEmployee,
        employee_id: employee.id,
        employee_name: employee.first_name,
      }));
      setTimeout(() => {
        getPerformance(dispatch, employee.id)
          .then((action) => {
            console.log(action.payload.data);

            const performanceData = JSON.parse(
              action.payload.data.employee_goals
            );

            const employeeAppraisal = JSON.parse(
              action.payload.data.employee_appraisal
            );
            console.log(employeeAppraisal);
            console.log(employeeAppraisal.type);

            setEmployeeAppraisal(employeeAppraisal);
            setPerformanceData(performanceData);
            console.log(performanceData);
          })
          .catch((error) => {
            console.error(error);
          });
      }, 1000);
    }
  };

  const handleSubmit = () => {
    console.log(editableGoal);

    if (!supervisorComment.Leadership) {
      cogoToast.error("Please fill your comments");
      return;
    }

    const updatedPerformanceData = { ...performanceData };

    if (editableGoal && Array.isArray(editableGoal.Leadership)) {
      updatedPerformanceData.Leadership.forEach((goal, index) => {
        if (editableGoal.Leadership[index]) {
          goal.goal_content = editableGoal.Leadership[index].goal_content;
        }
      });
      setPerformanceData(updatedPerformanceData);

      let formDatas = {
        Leadership: updatedPerformanceData.Leadership.map((goal) => ({
          ...goal,
          supervisor_Comment: supervisorComment.Leadership,
          supervisor_EditGoal: editableGoal.goal_content,
        })),
      };

      localStorage.setItem("Leader", JSON.stringify(formDatas));

      // toast.success("Supervisor's review for Leadership saved successfully");
      console.log(formDatas);
    } else {
      updatedPerformanceData.Leadership.forEach((goal) => {
        goal.supervisor_EditGoal = goal.goal_content;
      });
    }

    setPerformanceData(updatedPerformanceData);

    let formDatas = {
      Leadership: updatedPerformanceData.Leadership.map((goal) => ({
        ...goal,
        supervisor_Comment: supervisorComment.Leadership,
        supervisor_EditGoal: goal.supervisor_EditGoal || goal.goal_content, // Ensure to use edited or original content
      })),
    };

    localStorage.setItem("Leader", JSON.stringify(formDatas));

    // Show success message
    cogoToast.success("Supervisor's review for Leadership saved successfully");
    console.log(formDatas);
  };

  const handleSubmits = () => {
    console.log(editableGoall);
    if (!supervisorCommentt.Performance) {
      cogoToast.error("Please fill your comments");
      return;
    }
    const updatedPerformanceData = { ...performanceData };
    if (editableGoall && Array.isArray(editableGoall.Performance)) {
      updatedPerformanceData.Performance.forEach((goal, index) => {
        if (editableGoall.Performance[index]) {
          goal.goal_content = editableGoall.Performance[index].goal_content;
        }
      });
      setPerformanceData(updatedPerformanceData);

      let formDatas = {
        Performance: updatedPerformanceData.Performance.map((goal) => ({
          ...goal,
          supervisor_Comment: supervisorCommentt.Performance,
          supervisor_EditGoal: editableGoall.goal_content,
        })),
      };

      localStorage.setItem("perform", JSON.stringify(formDatas));

      // toast.success("Supervisor's review for Performance saved successfully");
      console.log(formDatas);
    } else {
      updatedPerformanceData.Performance.forEach((goal, index) => {
        goal.goal_content = editableGoall.goal_content;
      });
    }

    setPerformanceData(updatedPerformanceData);

    let formDatas = {
      Performance: updatedPerformanceData.Performance.map((goal) => ({
        ...goal,
        supervisor_Comment: supervisorCommentt.Performance,
        supervisor_EditGoal: editableGoall.goal_content,
      })),
    };

    localStorage.setItem("perform", JSON.stringify(formDatas));

    cogoToast.success("Supervisor's review for Performance saved successfully");
    console.log(formDatas);
  };
  const handleSubmitt = () => {
    if (!supervisorComments.Development) {
      cogoToast.error("Please fill your comments");
      return;
    }
    const updatedPerformanceData = { ...performanceData };
    if (editableGoals && Array.isArray(editableGoals.Development)) {
      updatedPerformanceData.Development.forEach((goal, index) => {
        if (editableGoals.Development[index]) {
          goal.goal_content = editableGoals.goal_content;
        } else {
          updatedPerformanceData.Development.forEach((goal, index) => {
            goal.goal_content = editableGoals.goal_content;
          });
        }
      });

      setPerformanceData(updatedPerformanceData);

      let formDatas = {
        Development: updatedPerformanceData.Development.map((goal) => ({
          ...goal,
          supervisor_Comment: supervisorComments.Development,
          supervisor_EditGoal: editableGoals.goal_content || goal.goal_content,
        })),
      };

      localStorage.setItem("Develop", JSON.stringify(formDatas));
      console.log(formDatas);
      setGoalSubmit(true);

      cogoToast.success(
        "Supervisor's review for Development saved successfully"
      );
    } else {
      console.error(
        "Editable goal or Leadership array is undefined or not an array."
      );
    }
  };
  const handleSendBack = () => {
    const sendData = {
      employee_id: sendEmployee.employee_id,
      // employee_name: sendEmployee.employee_name,
    };

    console.log(sendData);

    postsendback(dispatch, sendData);
  };

  const handleEdit = () => {
    seteditable(true);
    cogoToast.success("you are now allowed to edit");
  };
  const handleEdits = () => {
    cogoToast.success("you are now allowed to edit");
    seteditables(true);
  };
  const handleEditt = () => {
    cogoToast.success("you are now allowed to edit");
    seteditablee(true);
  };
  const handleEditLeadership = (e) => {
    const { name, value } = e.target;
    setEditableGoal((prevEditableGoal) => ({
      ...prevEditableGoal,
      goal_content: value,
    }));
  };
  const handleEditPerformance = (e) => {
    const { name, value } = e.target;
    setEditableGoall((prevEditableGoall) => ({
      ...prevEditableGoall,
      goal_content: value,
    }));
    // setPerformanceData((prevPerformanceData) => ({
    //   ...prevPerformanceData,
    //   Leadership: editableGoal,
    // }));
  };
  const handleEditDevelopment = (e) => {
    const { name, value } = e.target;
    setEditableGoals((prevEditableGoals) => ({
      ...prevEditableGoals,
      goal_content: value,
    }));
    // setPerformanceData((prevPerformanceData) => ({
    //   ...prevPerformanceData,
    //   Leadership: editableGoal,
    // }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSupervisorComment((prevSupervisorComment) => ({
      ...prevSupervisorComment,
      [name]: value,
    }));
  };
  const handleChanges = (e) => {
    const { name, value } = e.target;
    setSupervisorComments((prevSupervisorComments) => ({
      ...prevSupervisorComments,
      [name]: value,
    }));
  };
  const handleChangee = (e) => {
    const { name, value } = e.target;
    setSupervisorCommentt((prevSupervisorCommentt) => ({
      ...prevSupervisorCommentt,
      [name]: value,
    }));
  };
  const handleAll = () => {
    const leadership = JSON.parse(localStorage.getItem("Leader")) || {};
    const performance = JSON.parse(localStorage.getItem("perform")) || {};
    const development = JSON.parse(localStorage.getItem("Develop")) || {};
    const appraisalData = JSON.parse(localStorage.getItem("appraisalData"));

    const combinedData = {
      Leadership: (leadership.Leadership || []).map((goal) => ({
        ...goal,
        // supervisor_Comment: leadership.supervisor_Comment,
        // supervisor_EditGoal: leadership.supervisor_EditGoal,
      })),
      Performance: (performance.Performance || []).map((goal) => ({
        ...goal,
        // supervisor_Comment: performance.supervisor_Comment,
        // supervisor_EditGoal: performance.supervisor_EditGoal,
      })),
      Development: (development.Development || []).map((goal) => ({
        ...goal,
        // supervisor_Comment: development.supervisor_Comment,
        // supervisor_EditGoal: development.supervisor_EditGoal,
      })),
    };
    console.log(combinedData);

    const sendData = {
      employee_id: sendEmployee.employee_id,
      employee_name: sendEmployee.employee_name,
      employee_appraisal: JSON.stringify(appraisalData),

      employee_goals: JSON.stringify(combinedData),
    };

    console.log(sendData);
    try {
      const response = postperformances(dispatch, sendData);
      if (response) {
        localStorage.clear();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="card-height" additionalClass="mt-4 p-4">
      <h5 style={{ fontWeight: "bold" }} className="co-grayx">
        Supervisor's Review of Performance
      </h5>

      <div
        style={{ padding: "10px", maxHeight: "680px" }}
        className="col-12 col-md-12 list-achievements"
      >
        <div className="select-container mt-3">
          <strong>Select Employee to view</strong>
          <select
            style={{ border: "1px solid #e8e9eb" }}
            onChange={handleEmployeeChange}
          >
            <option value="">Select an Option</option>
            {employees &&
              employees.map((employee) => (
                <option key={employee.id} value={employee.first_name}>
                  {employee.first_name}
                </option>
              ))}
          </select>
        </div>
        <br />

        {selectedEmployee && (
          <>
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <button
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    activeTab === "Leadership" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Leadership")}
                  style={{
                    backgroundColor:
                      activeTab === "Leadership" ? "#dcdcdc" : "#3A6ED4",
                    color: "#fff",
                  }}
                >
                  <BsPeople className="mb-1" /> Leadership
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    activeTab === "Performance" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Performance")}
                  style={{
                    backgroundColor:
                      activeTab === "Performance" ? "#dcdcdc" : "#3A6ED4",
                    color: "#fff",
                    marginLeft: "2rem",
                  }}
                >
                  <BsGraphUp className="mb-1" /> Performance
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    activeTab === "Development" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Development")}
                  style={{
                    backgroundColor:
                      activeTab === "Development" ? "#dcdcdc" : "#3A6ED4",
                    color: "#fff",
                    marginLeft: "2rem",
                  }}
                >
                  <IoPersonSharp className="mb-1" /> Development
                </button>
              </li>
            </ul>
            <div className="tab-content mt-3">
              <div
                className={`tab-pane fade ${
                  activeTab === "Leadership" ? "show active" : ""
                }`}
                id="leadership-tab"
                style={{ marginTop: "7px" }}
              >
                {performanceData && performanceData.Leadership && (
                  <div>
                    {performanceData.Leadership.map((goal, index) => (
                      <div key={index}>
                        <strong>
                          {" "}
                          {sendEmployee.employee_name} Leadership
                          Goals/Objectives
                        </strong>
                        <CustomTextArea
                          defaultValue={goal.goal_content}
                          readOnly={!editable}
                          onChange={(e) => handleEditLeadership(e)}
                        />
                        <br />
                      </div>
                    ))}
                  </div>
                )}
                <br />
                <br />
                <button
                  className="rounded-3 border border-none"
                  style={{
                    width: "14%",
                    color: "#fff",
                    height: "6vh",
                    borderRadius: "5px",
                    backgroundColor: "#3A6ED4",
                  }}
                  onClick={handleEdit}
                  color="#fff"
                >
                  {editable ? "Submit Edited Version" : "Click to Edit"}
                </button>
                <br />
                <br />
                {employeeAppraisal
                  .filter((item) => item.type === "Leadership")
                  .map((item, index) => (
                    <div key={index}>
                      <strong className="mt-5">
                        Summary of {sendEmployee.employee_name} Review
                        Comment(Free text)
                      </strong>
                      <CustomTextArea
                        value={item.employee_appraisal}
                        label=""
                      />
                    </div>
                  ))}

                <br />
                <br />
                <div>
                  <strong>Supervisor's Review</strong>
                  <CustomTextArea
                    name="Leadership"
                    value={
                      performanceData &&
                      performanceData.Leadership &&
                      performanceData.Leadership.length > 0
                        ? performanceData.Leadership[0].supervisor_Comment
                        : supervisorComment.Leadership
                    }
                    onChange={handleChange}
                  />
                </div>
                {/* {performanceData && performanceData.Leadership && (
                  <div>
                    {performanceData.Leadership.map((goal, index) => (
                      <div key={index}>
                        <strong>Supervisor's Review</strong>
                        <CustomTextArea
                          name="Leadership"
                          value={
                            goal.supervisor_Comment ||
                            supervisorComment.Leadership
                          }
                          onChange={handleChange}
                        />
                      </div>
                    ))}
                  </div>
                )} */}

                {/* <strong>Supervisor's Review</strong>
                <CustomTextArea
                  name="Leadership"
                  value={supervisorComment.Leadership}
                  onChange={handleChange}
                /> */}
                <br />

                <br />

                <br />
                <button
                  className="rounded-3 border border-none"
                  style={{
                    width: "20%",
                    color: "#fff",
                    height: "6vh",
                    borderRadius: "5px",
                    backgroundColor: "red",
                  }}
                  color="#fff"
                  text="Submit"
                  onClick={handleSendBack}
                >
                  SendBack
                </button>
                <button
                  className="rounded-3 border border-none"
                  style={{
                    width: "20%",
                    color: "#fff",
                    height: "6vh",
                    marginLeft: "10px",
                    borderRadius: "5px",
                    backgroundColor: "#3A6ED4",
                  }}
                  color="#fff"
                  text="Submit"
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
              <div
                className={`tab-pane fade ${
                  activeTab === "Performance" ? "show active" : ""
                }`}
                id="performance-tab"
                style={{ marginTop: "7px" }}
              >
                {performanceData && performanceData.Performance && (
                  <div>
                    {performanceData.Performance.map((goal, index) => (
                      <div key={index}>
                        <strong>
                          {" "}
                          {sendEmployee.employee_name} Performance
                          Goals/Objectives
                        </strong>
                        <CustomTextArea
                          readOnly={!editablee}
                          defaultValue={goal.goal_content}
                          onChange={(e) => handleEditPerformance(e)}
                        />
                        <br />
                      </div>
                    ))}
                  </div>
                )}
                <br />
                <br />
                <button
                  className="rounded-3 border border-none"
                  style={{
                    width: "14%",
                    color: "#fff",
                    height: "6vh",
                    borderRadius: "5px",
                    backgroundColor: "#3A6ED4",
                  }}
                  color="#fff"
                  text="Edit"
                  onClick={handleEditt}
                >
                  {editablee ? "Submit Edited Version" : "Click to Edit"}
                </button>
                <br />
                <br />
                {employeeAppraisal
                  .filter((item) => item.type === "Performance")
                  .map((item, index) => (
                    <div>
                      <strong className="mt-5">
                        Summary of {sendEmployee.employee_name} Review
                        Comment(Free text)
                      </strong>
                      <CustomTextArea
                        value={item.employee_appraisal}
                        label=""
                      />
                    </div>
                  ))}

                <br />
                <br />
                <div>
                  <strong>Supervisor's Review</strong>
                  <CustomTextArea
                    name="Performance"
                    value={
                      performanceData &&
                      performanceData.Performance &&
                      performanceData.Performance.length > 0
                        ? performanceData.Performance[0].supervisor_Comment
                        : supervisorCommentt.Performance
                    }
                    onChange={handleChangee}
                  />
                </div>
                {/* {performanceData && performanceData.Performance ? (
                  <div>
                    {performanceData.Performance.map((goal, index) => (
                      <div key={index}>
                        <strong>Supervisor's Review</strong>
                        <CustomTextArea
                          name="Performance"
                          value={goal.supervisor_Comment}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>
                    <strong>Supervisor's Review</strong>
                    <CustomTextArea
                      name="Performance"
                      value={supervisorCommentt.Performance}
                      onChange={handleChangee}
                    />
                  </div>
                )} */}

                <br />

                <br />
                <button
                  className="rounded-3 border border-none"
                  style={{
                    width: "20%",
                    color: "#fff",
                    height: "6vh",
                    borderRadius: "5px",
                    backgroundColor: "red",
                  }}
                  color="#fff"
                  text="Submit"
                  onClick={handleSendBack}
                >
                  SendBack
                </button>
                <button
                  className="rounded-3 border border-none"
                  style={{
                    width: "20%",
                    color: "#fff",
                    height: "6vh",
                    marginLeft: "10px",
                    borderRadius: "5px",
                    backgroundColor: "#3A6ED4",
                  }}
                  color="#fff"
                  onClick={handleSubmits}
                  text="Submit"
                >
                  Save
                </button>
              </div>
              <div
                className={`tab-pane fade ${
                  activeTab === "Development" ? "show active" : ""
                }`}
                id="development-tab"
                style={{ marginTop: "7px" }}
              >
                {performanceData && performanceData.Development && (
                  <div>
                    {performanceData.Development.map((goal, index) => (
                      <div key={index}>
                        <strong>
                          {" "}
                          {sendEmployee.employee_name} Development
                          Goals/Objectives
                        </strong>
                        <CustomTextArea
                          defaultValue={goal.goal_content}
                          readOnly={!editables}
                          onChange={(e) => handleEditDevelopment(e)}
                        />
                        <br />
                      </div>
                    ))}
                  </div>
                )}
                <br />
                <br />
                <button
                  className="rounded-3 border border-none"
                  style={{
                    width: "14%",
                    color: "#fff",
                    height: "6vh",
                    borderRadius: "5px",
                    backgroundColor: "#3A6ED4",
                  }}
                  onClick={handleEdits}
                  color="#fff"
                >
                  {editables ? "Submit Edited Version" : "Click to Edit"}
                </button>
                <br />
                <br />
                {employeeAppraisal
                  .filter((item) => item.type === "Development")
                  .map((item, index) => (
                    <div>
                      <strong className="mt-5">
                        Summary of {sendEmployee.employee_name} Review
                        Comment(Free text)
                      </strong>
                      <CustomTextArea
                        value={item.employee_appraisal}
                        label=""
                      />
                    </div>
                  ))}

                <br />
                <br />
                <strong>Supervisor's Review</strong>
                <CustomTextArea
                  name="Development"
                  value={
                    performanceData &&
                    performanceData.Development &&
                    performanceData.Development.length > 0
                      ? performanceData.Development[0].supervisor_Comment
                      : supervisorComments.Development
                  }
                  onChange={handleChanges}
                />

                {/* {performanceData && performanceData.Development ? (
                  <div>
                    {performanceData.Development.map((goal, index) => (
                      <div key={index}>
                        <strong>Supervisor's Review</strong>
                        <CustomTextArea
                          name="Development"
                          value={goal.supervisor_Comment}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>
                    <strong>Supervisor's Review</strong>
                    <CustomTextArea
                      name="Development"
                      value={supervisorComments.Development}
                      onChange={handleChanges}
                    />
                  </div>
                )} */}
                {/* {performanceData && performanceData.Development && (
                  <div>
                    {performanceData.Development.map((goal, index) => (
                      <div key={index}>
                        <strong>Supervisor's Review</strong>
                        <CustomTextArea
                          name="Development"
                          value={
                            goal.supervisor_Comment ||
                            supervisorComments.Development
                          }
                          onChange={handleChanges}
                        />
                      </div>
                    ))}
                  </div>
                )} */}
                {/* <strong>Supervisor's Review</strong>
                <CustomTextArea
                  name="Development"
                  value={supervisorComments.Development}
                  onChange={handleChanges}
                />
                <br /> */}

                <br />

                <br />
                <button
                  className="rounded-3 border border-none"
                  style={{
                    width: "20%",
                    color: "#fff",
                    height: "6vh",
                    borderRadius: "5px",
                    backgroundColor: "red",
                  }}
                  onClick={handleSendBack}
                  color="#fff"
                  text="Submit"
                >
                  SendBack
                </button>
                {!goalSubmit ? (
                  <button
                    className="rounded-3 border border-none"
                    style={{
                      width: "20%",
                      color: "#fff",
                      height: "6vh",
                      marginLeft: "10px",
                      borderRadius: "5px",
                      backgroundColor: "#3A6ED4",
                    }}
                    color="#fff"
                    text="Submit"
                    onClick={handleSubmitt}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    className="rounded-3 border border-none"
                    style={{
                      width: "20%",
                      color: "#fff",
                      height: "6vh",
                      marginLeft: "10px",
                      borderRadius: "5px",
                      backgroundColor: "#3A6ED4",
                    }}
                    color="#fff"
                    text="Submit"
                    onClick={handleAll}
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </>
        )}

        <br />
        <br />
      </div>
    </div>
  );
}

export default SupervisorReview;
