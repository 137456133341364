import React, { useState } from "react";
import cogoToast from "cogo-toast";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import placeholder from "../../../../assets/icons/placeholder.svg";
import { node } from "prop-types";
import { updateProfileAction } from "../../../../redux/actions/profileActions";
import {
  ternaryResolver,
  uploadFile,
  anonymousFunc,
} from "../../../../utils/helpers";
import { ButtonLoader } from "../../../../components/ButtonLoader";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const ProfileLayout = ({ children }) => {
  const { pathname } = useLocation();
  const [isUploading, setIsUploading] = useState(false);
  const { userData } = useSelector((store) => store.auth);
  const dispatch = useDispatch();
  const { first_name, last_name, image_url, title } = userData;

  const [isCropping, setIsCropping] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const [crop, setCrop] = useState({ aspect: 1 / 1 });
  const [data, setData] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [blob, setBlob] = useState(null);

  const handleChange = ({ target: { files } }) => {
    setIsUploading(true);
    Promise.resolve(uploadFile(files[0])).then(({ data, success }) => {
      if (success) {
        const payload = {
          first_name: userData.first_name,
          last_name: userData.last_name,
          title: userData.title,
          gender: userData.gender,
          dob: userData.dob,
          state: userData.state,
          country: userData.country,
          industry: userData.industry,
          image_url: data,
          resume: userData.resume,
          phone: userData.phone,
          links: userData.links,
          isShowOnProfile: 1,
          about: userData.about,
        };
        const promise = updateProfileAction(payload, dispatch);
        Promise.resolve(promise).finally(() => {
          setIsUploading(false);
          setIsCropping(false);
        });
      } else {
        cogoToast.error("Unsuccessful image upload", {
          hideAfter: 4,
          position: "top-center",
        });
      }
    });
  };

  const croppedImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          setProfileImage(reader.result);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onImageLoaded = (image) => {
    setData(image);
  };

  const onCropComplete = async (crop) => {
    makeClientCrop(crop);
  };

  const makeClientCrop = async (crop) => {
    if (data && crop.width && crop.height) {
      const imageUrl = await getCroppedImg(data, crop, "profile.jpeg");
      setCroppedImageUrl(imageUrl);
    }
  };

  const getCroppedImg = async (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          cogoToast.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        setBlob(blob);
        window.URL.revokeObjectURL(fileUrl);
        setFileUrl(window.URL.createObjectURL(blob));
        resolve(fileUrl);
      }, "image/jpeg");
    });
  };

  return (
    <div className="mt-3 px-2">
      <div className="profile-header d-flex">
        {ternaryResolver(
          isUploading,
          <div
            className="mr-5"
            style={{
              width: "150px",
              height: "150px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ButtonLoader color="#3a6ed4" width="80" height="80" />
          </div>,
          <img
            height="150"
            width="150"
            className="mr-5"
            src={croppedImageUrl || image_url || placeholder}
            style={{
              borderRadius: "100px",
            }}
            onError={({ target }) => {
              target.src = placeholder;
            }}
            alt=""
          />
        )}
        <div className="d-flex flex-column mt-2">
          <h4>
            {first_name ?? "Hello"} <b>{last_name ?? "User"}</b>
          </h4>
          <span className="co-gray">{title}</span>

          <input
            disabled={isUploading}
            onChange={ternaryResolver(isUploading, anonymousFunc, (event) => {
              croppedImage(event);
              setIsCropping(true);
            })}
            // onChange={}
            type="file"
            id="upload-profile-img"
            accept=".png,.jpg,.jpeg,.gif"
            style={{ display: "none" }}
          />
          {isCropping && (
            <div>
              <ReactCrop
                src={profileImage}
                onComplete={onCropComplete}
                onImageLoaded={onImageLoaded}
                crop={crop}
                onChange={(newCrop) => {
                  setCrop(newCrop);
                }}
              />
              <br />
              <span>
                <label
                  style={{
                    cursor: "pointer",
                  }}
                  htmlFor="crop-profile-img"
                  className="underlined co-primary py-1 my-1"
                  onClick={() => handleChange({ target: { files: [blob] } })}
                >
                  <b>
                    {ternaryResolver(isUploading, "Cropping image", "Submit")}
                  </b>
                </label>
              </span>
            </div>
          )}

          {!isCropping && (
            <label
              htmlFor="upload-profile-img"
              className="underlined co-primary py-1 my-1"
              style={{
                cursor: ternaryResolver(isUploading, "not-allowed", "pointer"),
              }}
            >
              <b>
                {ternaryResolver(
                  isUploading,
                  "Uploading image",
                  "Change Avatar"
                )}
              </b>
            </label>
          )}
          {!isCropping && (
            <Link
              to={
                pathname.includes("edit") ? "/profile" : "/profile/edit/basic"
              }
              className="edit-profile-button text-center my-2 py-2"
            >
              {pathname.includes("edit") ? "View Profile" : "Edit Profile"}
            </Link>
          )}
        </div>
      </div>
      {children}
    </div>
  );
};

ProfileLayout.propTypes = {
  children: node.isRequired,
};

export default ProfileLayout;
