import React, { useState, useEffect } from "react";
import { TextField, Checkbox } from "@material-ui/core";

import CustomButton from "../../../../../components/CustomButton";
import { useDispatch } from "react-redux";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { useHistory } from "react-router-dom";
import Counter from "../../../../../components/Counter";
import {
  createExperienceAction,
  getAllExperienceAction,
  updateExperienceAction,
  getSingleExperienceAction,
} from "../../../../../redux/actions/experienceAction";
import CustomTextArea from "../../../../../components/CustomTextArea";
import EditCard from "../EditCardd";
import { ternaryResolver } from "../../../../../utils/helpers";

function Experience() {
  const [experiences, setExperiences] = useState([]);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [currentExperience, setCurrentExperience] = useState({
    campany_name: "",
    title: "",
    start_date: "",
    end_date: "",
    achievements: "",
    isPresent: false,
  });

  const dispatch = useDispatch();
  const { push } = useHistory();

  useEffect(() => {
    handleGetExperience();
  }, []);

  const handleGetExperience = async () => {
    try {
      const response = await getAllExperienceAction(dispatch);
      if (response && response.payload) {
        setExperiences(response.payload);
        setIsUpdateMode(response.payload.length > 0);
      } else {
        setExperiences([]);
      }
    } catch (error) {
      console.error(error);
      setExperiences([]);
    }
  };

  const handleText = (e, index) => {
    const { name, value } = e.target;
    if (index === undefined) {
      setCurrentExperience((prevCurrentExperience) => ({
        ...prevCurrentExperience,
        [name]: value,
      }));
    } else {
      const updatedExperiences = [...experiences];
      updatedExperiences[index] = {
        ...updatedExperiences[index],
        [name]: value,
      };
      setExperiences(updatedExperiences);
    }
  };
const handleAchievementsChange = (event, editor, index) => {
    const data = editor.getData();
    if (index === undefined) {
      setCurrentExperience((prevCurrentExperience) => ({
        ...prevCurrentExperience,
        achievements: data,
      }));
    } else {
      const updatedExperiences = [...experiences];
      updatedExperiences[index] = {
        ...updatedExperiences[index],
        achievements: data,
      };
      setExperiences(updatedExperiences);
    }
  };

  const handlePresentChange = (event, index) => {
    const isChecked = event.target.checked;
    if (index === undefined) {
      setCurrentExperience((prevCurrentExperience) => ({
        ...prevCurrentExperience,
        isPresent: isChecked,
        end_date: isChecked ? "Present" : "",
      }));
    } else {
      const updatedExperiences = [...experiences];
      updatedExperiences[index] = {
        ...updatedExperiences[index],
        isPresent: isChecked,
        end_date: isChecked ? "Present" : "",
      };
      setExperiences(updatedExperiences);
    }
  };

  const handleEndDateChange = (event, index) => {
    const date = event.target.value;
    if (index === undefined) {
      setCurrentExperience((prevCurrentExperience) => ({
        ...prevCurrentExperience,
        end_date: date,
      }));
    } else {
      const updatedExperiences = [...experiences];
      updatedExperiences[index] = {
        ...updatedExperiences[index],
        end_date: date,
      };
      setExperiences(updatedExperiences);
    }
  };

  const handleExperience = async () => {
    try {
      if (experiences.length === 0) {
        const data = {
          campany_name: currentExperience.campany_name,
          title: currentExperience.title,
          start_date: currentExperience.start_date,
          end_date: currentExperience.end_date,
          achievements: currentExperience.achievements,
        };
        await createExperienceAction(data, dispatch);
      } else {
        for (const experience of experiences) {
          const data = {
            campany_name: experience.campany_name,
            title: experience.title,
            start_date: experience.start_date,
            end_date: experience.end_date,
            achievements: experience.achievements,
          };
          if (experience.id) {
            await updateExperienceAction(experience.id, data, dispatch);
          } else {
            await createExperienceAction(data, dispatch);
          }
        }
      }
      console.log("Experiences saved successfully");
      push("/profile");
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddExperience = () => {
    if (experiences.length === 0 && currentExperience.campany_name !== "") {
      setExperiences([currentExperience]);
    } else {
      setExperiences([
        ...experiences,
        {
          campany_name: "",
          title: "",
          start_date: "",
          end_date: "",
          achievements: "",
          isPresent: false,
        },
      ]);
    }

    // Reset the current experience state
    setCurrentExperience({
      campany_name: "",
      title: "",
      start_date: "",
      end_date: "",
      achievements: "",
      isPresent: false,
    });
  };

  return (
    <div>
      {experiences.length === 0 ? (
        <EditCard>
          <TextField
            name="campany_name"
            onChange={(e) => handleText(e)}
            value={currentExperience.campany_name}
            className="w-100 mb-4"
            variant="outlined"
            label="Employer Name"
          />

          <TextField
            name="title"
            onChange={(e) => handleText(e)}
            value={currentExperience.title}
            className="w-100 mb-4"
            variant="outlined"
            label="Job Role"
          />

          <div  >
      <CKEditor
  editor={ClassicEditor}
  config={{}}  
  data={currentExperience.achievements}  
  onReady={(editor) => {
    
  }}
  onChange={(event, editor) => {
    const data = editor.getData(); 
    handleAchievementsChange(event, editor);
  }}
/>


{/* 
            <CustomTextArea
              classes="pt-4 pr-5"
              value={currentExperience.achievements}
              name="achievements"
              onChange={(e) => handleText(e)}
              rows="15"
              id="about"
              placeholder="Write about your achievements"
            /> */}
          </div>

          <TextField
            name="start_date"
            type="date"
            onChange={(e) => handleText(e)}
            value={currentExperience.start_date}
            className="w-100 mb-4"
            variant="outlined"
          />

          <TextField
            name="end_date"
            type="date"
            value={
              currentExperience.isPresent ? "" : currentExperience.end_date
            }
            onChange={(e) => handleEndDateChange(e)}
            className="w-100 mb-4"
            variant="outlined"
            disabled={currentExperience.isPresent}
          />

          <div className="w-100 position-relative">
            <Checkbox
              checked={currentExperience.isPresent}
              onChange={(e) => handlePresentChange(e)}
              color="primary"
              id="stillInSchool"
            />
            <label htmlFor="stillInSchool">Present</label>
          </div>
        </EditCard>
      ) : (
        <>
          {experiences.map((experience, index) => (
            <EditCard key={index}>
              <TextField
                name="campany_name"
                onChange={(e) => handleText(e, index)}
                value={experience.campany_name}
                className="w-100 mb-4"
                variant="outlined"
                label="Employer Name"
              />

              <TextField
                name="title"
                onChange={(e) => handleText(e, index)}
                value={experience.title}
                className="w-100 mb-4"
                variant="outlined"
                label="Job Role"
              />

              <div className="w-100 position-relative mb-4">
       <CKEditor
  editor={ClassicEditor}
  config={{}} 
  data={experience.achievements}  
  onReady={(editor) => {
    
  }}
  onChange={(event, editor) => {
    const data = editor.getData(); 
    handleAchievementsChange(event, editor, index);  
  }}
/>

                

                {/* <Counter />
                <CustomTextArea
                  classes="pt-4 pr-5"
                  value={experience.achievements}
                  name="achievements"
                  onChange={(e) => handleText(e, index)}
                  rows="15"
                  id="about"
                  placeholder="Write about your achievements"
                /> */}
              </div>

              <TextField
                name="start_date"
                type="date"
                onChange={(e) => handleText(e, index)}
                value={experience.start_date}
                className="w-100 mb-4"
                variant="outlined"
                label="Start date"
              />

              <TextField
                name="end_date"
                type="date"
                value={
                  experience.end_date === "Present" ? "" : experience.end_date
                }
                onChange={(e) => handleEndDateChange(e, index)}
                className="w-100 mb-4"
                variant="outlined"
                label="End date"
                disabled={experience.end_date === "Present"}
              />

              <div className="w-100 position-relative">
                <Checkbox
                  checked={experience.end_date === "Present"}
                  onChange={(e) => handlePresentChange(e, index)}
                  color="primary"
                  id={`stillInSchool-${index}`}
                />
                <label htmlFor={`stillInSchool-${index}`}>Present</label>
              </div>
            </EditCard>
          ))}
        </>
      )}
      <CustomButton
        handleClick={handleAddExperience}
        backgroundColor="#3A6ED4"
        color="#fff"
        text="Add Experience"
        additionalClass="m-3"
        type="button"
      />

      {/* Single Submit/Update Button for all experiences */}
      <CustomButton
        backgroundColor="#3A6ED4"
        color="#fff"
        text={isUpdateMode ? "Update All" : "Submit All"}
        additionalClass="m-3"
        type="button"
        handleClick={handleExperience}
      />
    </div>
  );
}

export default Experience;
