import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
// import { GoogleLogin } from "react-google-login";
import CustomButton from "../../../../components/CustomButton";
import CustomInput from "../../../../components/CustomInput";
import CustomSelect from "../../../../components/CustomSelect";
import { getCountryAction } from "../../../../redux/actions/countryActions";

import TermsAndConditions from "../../../../components/TermsAndConditions";

import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
} from "@material-ui/core";
import AuthWrapper from "../AuthWrapper";
// import GoogleIcon from "../../../../assets/icons/google-icon.svg";
import WBlogo from "../../../../assets/icons/WBlogo.svg";
import EyeIcon from "../../../../assets/icons/eye.svg";
import { isValidEmail } from "../../../../utils/helpers";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import SlidingPane from "react-sliding-pane";
import { getStateAction } from "../../../../redux/actions/stateActions";

// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
// import clsx from "clsx";
import {
  activateUserAction,
  createUserAction,
  resendOTPAction,
  // socialMediaRegister,
} from "../../../../redux/actions/authActions";
import {
  AUTH_LOADING,
  CLEAR_CREATE_USER,
  GET_STATES,
  STOP_AUTH_LOADING,
} from "../../../../redux/actionTypes";
// import { FacebookIcon, LinkedinIcon } from "react-share";
// import LinkedIn from "linkedin-login-for-react";
const SignUp = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const [showTermsError, setShowTermsError] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState();
  const [actEmail, setActEmail] = useState();
  const { inviteCode } = useParams();
  const [state, setState] = useState({
    username: "",
    email: "",
    password: "",
    account_type: "referral",
    country_id: selectedCountry || 0,
    invited_by_code: inviteCode || "",
    agree: false,
  });
  const { country, profile } = useSelector((store) => store);
  const { loading, userCreated, isAuthenticated } = useSelector(
    (store) => store.auth
  );
  const { countries } = country;
  useEffect(() => {
    dispatch({ type: CLEAR_CREATE_USER });

    const promises = [getCountryAction(dispatch)];
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) history.push("/");
  }, [isAuthenticated, history]);

  const switchToBusiness = () => {
    localStorage.setItem("accountType", "business");
    window.location.reload();
  };

  const [termsofuse, setTermsOfUse] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });

  const [checkedTerms, setCheckedTerms] = useState(false);

  const handleChange = ({ target: { id, value } }) => {
    const newValue = {};
    newValue[id] = value;
    setState({ ...state, ...newValue });
  };

  const handleCheckbox = (event) => {
    setState({ ...state, agree: event.target.checked });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkedTerms === false) {
      e.preventDefault();
      console.log(checkedTerms);
      setShowTermsError(!showTermsError);
      return;
    } else {
      dispatch({ type: AUTH_LOADING });
      setActEmail({ email: state.email });

      Promise.resolve(createUserAction(state, dispatch))
        .then(() =>
          setState({
            username: "",
            //  email: "",
            password: "",
            country_id: selectedCountry,
            invited_by_code: inviteCode || "",
          })
        )
        .finally(() => {
          dispatch({ type: STOP_AUTH_LOADING });
        });
    }
  };

  const handleActivate = (e) => {
    e.preventDefault();
    dispatch({ type: AUTH_LOADING });
    Promise.resolve(activateUserAction(dispatch, code))
      .then(() => {
        setState({
          username: "",
          email: "",
          password: "",
          country_id: selectedCountry,
          invited_by_code: inviteCode || "",
        });
        window.location = "/";
      })
      .finally(() => {
        //dispatch({ type: STOP_AUTH_LOADING });
      });
  };

  const resentOTPCode = (e) => {
    Promise.resolve(resendOTPAction(actEmail, dispatch))
      .then(() => console.log("OTP RESENT " + JSON.stringify(actEmail)))
      .finally(() => {
        //  dispatch({ type: STOP_AUTH_LOADING });
      });
  };

  const handleChangeCountry = ({ target: { value } }) => {
    const countryID = value;
    setSelectedCountry(countryID);
    setState({ ...state, country_id: countryID });
  };

  // const responseGoogle = (response) => {
  //   if (response.error) return;
  //   const { accessToken, profileObj } = response;

  //   const payload = {};
  //   payload["email"] = profileObj.email;
  //   payload["platform_uid"] = profileObj.googleId;
  //   payload["platform"] = "google";
  //   payload["first_name"] = profileObj.givenName;
  //   payload["last_name"] = profileObj.familyName;
  //   payload["fcm_token"] = accessToken;
  //   payload["invited_by_code"] = "";

  //   dispatch({ type: AUTH_LOADING });
  //   Promise.resolve(socialMediaRegister(payload, dispatch))
  //     .then(() =>
  //       setState({
  //         username: "",
  //         email: "",
  //         password: "",
  //         invited_by_code: inviteCode || "",
  //       })
  //     )
  //     .finally(() => {
  //       dispatch({ type: STOP_AUTH_LOADING });
  //     });
  // };

  // const responseFacebook = (response) => {
  //   if (response.status === "connected") {
  //     const payload = {};
  //     payload["email"] = response.data.email;
  //     payload["platform_uid"] = "facebook-id";
  //     payload["platform"] = "facebook";
  //     payload["first_name"] = response.data.name;
  //     payload["last_name"] = response.data.name;
  //     payload["fcm_token"] = "accessToken";
  //     payload["invited_by_code"] = inviteCode || "";
  //     payload["response"] = response;

  //     dispatch({ type: AUTH_LOADING });
  //     Promise.resolve(socialMediaRegister(payload, dispatch))
  //       .then(() => {})
  //       .finally(() => {
  //         dispatch({ type: STOP_AUTH_LOADING });
  //       });
  //   }
  // };
  // const callbackLinkedIn = (error, code, redirectUri) => {
  //   console.log(code, redirectUri);
  //   if (error) {
  //     // signin failed
  //   } else {
  //     // Obtain authorization token from linkedin api
  //     // see https://developer.linkedin.com/docs/oauth2 for more info
  //   }

  // {(userCreated || pathname.includes("activate")) && (
  // };

  return (
    <AuthWrapper>
      <div className="form-container d-flex justify-content-center">
        {(userCreated || pathname.includes("activate")) && (
          <div className="auth-form ml-md-4">
            <h4 className="text-center mt-4">Enter Activation Codes</h4>
            <p>
              (<small>Check you mail inbox/spam for the activation code</small>)
            </p>

            <form onSubmit={handleActivate} className="pt-2 pb-5">
              <CustomInput
                onChange={(e) => setCode(e.target.value)}
                type="text"
                label="Activation Code"
                id="icode"
                value={state.code}
                isRequired
              />

              <CustomButton
                type="submit"
                backgroundColor="#3A6ED4"
                color="#fff"
                text="Activate"
                loading={loading}
                additionalClass="my-5"
              />

              <div className="d-flex justify-content-center">
                <CustomButton
                  alt="logo"
                  color="blue"
                  text="Resend Code"
                  additionalClass="no-border"
                  handleClick={() => resentOTPCode()}
                />{" "}
                {/* <Link to="/" className="align-self-center">
                  {" "}
                  Back to Home
                </Link> */}
              </div>
            </form>
          </div>
        )}

        {/*** Start Terms Pane   */}
        <SlidingPane
          className=""
          overlayClassName="some-custom-overlay-class"
          isOpen={termsofuse.isPaneOpen}
          title="Terms of Use"
          subtitle="Workbrook"
          width="100%"
          from={"bottom"}
          onRequestClose={() => {
            // triggered on "<" on left top click or on outside click
            setTermsOfUse({ isPaneOpen: false });
          }}
        >
          <TermsAndConditions />
          <button
            className="i-agree"
            onClick={() => {
              setCheckedTerms(true);
              setTermsOfUse({ isPaneOpen: false });
            }}
          >
            I AGREE
          </button>
        </SlidingPane>

        {/*** END Terms Pane */}

        {!userCreated && !pathname.includes("activate") && (
          <div className="auth-form ml-md-4">
            <h4 className="text-center mt-4">Sign Up</h4>

            <CustomButton
              icon={WBlogo}
              alt="logo"
              backgroundColor="black"
              color="#fff"
              text="Switch to workbrook Business"
              additionalClass="mt-3 mb-5"
              handleClick={() => switchToBusiness()}
            />
            <form onSubmit={handleSubmit}>
              {showTermsError && !checkedTerms && (
                <p style={{ color: "red" }}>
                  Please agree with terms and condition before proceeding
                </p>
              )}
              <CustomInput
                onChange={handleChange}
                type="text"
                label="Username"
                id="username"
                value={state.username}
              />

              <CustomInput
                onChange={handleChange}
                type="text"
                label="Email (Official Email Address Only)"
                id="email"
                value={state.email}
              />

              {/* <CustomSelect
              
              id="country_id"
              label="Country"
              onChange={handleChange}
              value={state.country_id}
              options={{
                0: "Select Country",
                
              }}
             /> */}

              <p style={{ color: "#656b74" }}>Country</p>

              {/*  <InputLabel name="country-label">Select Country</InputLabel>*/}
              <Select
                labelId="country_id"
                name="country"
                label="country"
                value={state.country_id}
                onChange={handleChangeCountry}
                className="fullwidth-select"
              >
                <MenuItem value="">Select an country</MenuItem>
                {countries.map(({ name, id }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>

              <CustomInput
                tooltipText="Password should be alphanumeric and have one uppercase character"
                onChange={handleChange}
                type="password"
                label="Password"
                id="password"
                value={state.password}
                icon={EyeIcon}
              />

              {pathname.includes("activate") && (
                <CustomInput
                  isDisabled={inviteCode}
                  onChange={handleChange}
                  type="text"
                  label="Invite Code"
                  id="invited_by_code"
                  value={state.invited_by_code}
                />
              )}

              <div className="d-flex justify-content-between align-items-center mt-3 small-text">
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      id="isAdmin"
                      name="isAdmin"
                      style={{
                        visibility: "hidden",
                      }}
                      // value={state.isAdmin}
                      onChange={handleCheckbox}
                      //  state.agree
                      checked={checkedTerms}
                    />
                  }
                  label={
                    <>
                      <p>
                        By Signing up, I agree to the{" "}
                        <a
                          href="https://workbrook.com/workbrook-privacy-policy/"
                          target="_blank"
                          rel="noreferrer"
                          style={{ fontWeight: "bold" }}
                          onClick={() => {
                            setTermsOfUse({ isPaneOpen: true });
                          }}
                        >
                          Terms of Use
                        </a>{" "}
                        of this application.{" "}
                        <small className="text-muted">
                          You must agree to the Terms of Use to sign up.
                        </small>
                      </p>
                    </>
                  }
                />
              </div>

              <p className="pt-1 pb-1 mb-0 small-text text-center">
                You can also get familiar with our{" "}
                <a
                  href="https://workbrook.com/workbrook-privacy-policy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>{" "}
              </p>

              <CustomButton
                type="submit"
                backgroundColor="#3A6ED4"
                color="#fff"
                text="Sign Up"
                loading={loading}
                disabled={[
                  // !isValidEmail(state.email),
                  // !checkedTerms,
                  //!state.password,
                ].includes(true)}
                additionalClass="my-5"
              />
            </form>
            <p className="pt-4 pb-5 mb-0 small-text text-center">
              Already have an account? <Link to="/signin">Sign In</Link>{" "}
            </p>

            {/* <FacebookLogin
              appId="620384345321497"
              autoLoad
              callback={responseFacebook}
              render={(renderProps) => (
                <CustomButton
                  handleClick={() => renderProps.onClick()}
                  backgroundColor="#3A6ED4"
                  color="#fff"
                  fields="name,email"
                  alt="facebook icon"
                  text="Signup with Facebook"
                  additionalClass="my-5"
                />
              )}
            /> */}

            {/* <GoogleLogin
              clientId={"process.env.REACT_APP_GOOGLE_CLIENT_ID"}
              render={(renderProps) => (
                <CustomButton
                  handleClick={() => renderProps.onClick()}
                  backgroundColor="black"
                  color="white"
                  alt="google icon"
                  text="Signup with Google"
                  additionalClass="my-5"
                />
              )}
              buttonText="Login"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
            /> */}

            {/* <LinkedIn
              clientId="77o7tob14ug7d2"
              callback={callbackLinkedIn}
              className={clsx(
                "custom-button position-relative d-flex w-100 p-2 my-5",
                {
                  "text-center align-items-center": true,
                  "text-left": false,
                  "co-gray": false,
                }
              )}
              // scope={["name", "email", "r_emailaddress"]}
              text={
                <CustomButton
                  handleClick={() => {}}
                  backgroundColor="#3A6ED4"
                  alt="linkedin icon"
                  color="#fff"
                  text="Signup with LinkedIn"
                  additionalClass="my-5"
                />
              }
            /> */}
          </div>
        )}
      </div>
    </AuthWrapper>
  );
};

export default SignUp;
