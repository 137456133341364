import React from "react";
import { useState } from "react";
import CustomButton from "../../../../components/CustomButton";
import Card from "../../../../components/Card";
import { IoPersonSharp } from "react-icons/io5";
import CustomInput from "../../../../components/CustomInput";
import { BsPeople, BsGraphUp } from "react-icons/bs";
import CustomTextArea from "../../../../components/CustomTextArea";
import "../AddPerformance/add.css";

function AdminApproval() {
  const [leadershipTextareas, setLeadershipTextareas] = useState([
    {
      id: 1,
      placeholder: "Enter Leadership Goals/Objectives 1",
      type: "Leadership",
    },
  ]);

  const [performanceTextareas, setPerformanceTextareas] = useState([
    {
      id: 1,
      placeholder: "Enter Performance Goals/Objectives 1",
      type: "Performance",
    },
  ]);

  const [developmentTextareas, setDevelopmentTextareas] = useState([
    {
      id: 1,
      placeholder: "Enter Development Goals/Objectives",
      type: "Development",
    },
  ]);

  const [activeTab, setActiveTab] = useState("Leadership");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const addLeadershipTextarea = () => {
    const newLeadershipTextarea = {
      id: leadershipTextareas.length + 1,
      placeholder: `Enter Leadership Goals/Objectives ${
        leadershipTextareas.length + 1
      }`,
      type: "Leadership",
    };
    setLeadershipTextareas((prevTextareas) => [
      ...prevTextareas,
      newLeadershipTextarea,
    ]);
  };

  const removeLeadershipTextarea = (id) => {
    setLeadershipTextareas((prevTextareas) =>
      prevTextareas.filter((textarea) => textarea.id !== id)
    );
  };

  const addPerformanceTextarea = () => {
    const newPerformanceTextarea = {
      id: performanceTextareas.length + 1,
      placeholder: `Enter Performance Goals/Objectives ${
        performanceTextareas.length + 1
      }`,
      type: "Performance",
    };
    setPerformanceTextareas((prevTextareas) => [
      ...prevTextareas,
      newPerformanceTextarea,
    ]);
  };

  const removePerformanceTextarea = (id) => {
    setPerformanceTextareas((prevTextareas) =>
      prevTextareas.filter((textarea) => textarea.id !== id)
    );
  };

  const addDevelopmentTextarea = () => {
    const newDevelopmentTextarea = {
      id: developmentTextareas.length + 1,
      placeholder: `Enter Development Goals/Objectives ${
        developmentTextareas.length + 1
      }`,
      type: "Development",
    };
    setDevelopmentTextareas((prevTextareas) => [
      ...prevTextareas,
      newDevelopmentTextarea,
    ]);
  };

  const removeDevelopmentTextarea = (id) => {
    setDevelopmentTextareas((prevTextareas) =>
      prevTextareas.filter((textarea) => textarea.id !== id)
    );
  };

  const TextareaWithAddRemoveButtons = ({ id, placeholder, type }) => (
    <div key={id}>
      <div
        style={{ marginBottom: "8px", marginTop: "10px", fontWeight: "bold" }}
      >
        {type}
      </div>
      <textarea
        style={{
          width: "100%",
          marginTop: "10px",
          height: "7rem",
          border: "1px solid #e8e9eb",
          borderRadius: "0.875rem",
          padding: "1rem",
          marginBottom: "8px",
        }}
        placeholder={placeholder}
      />
      <div>
        <strong>Due Date</strong> <br />
        <input
          style={{ border: "1px solid #e8e9eb" }}
          className="select-contain"
          type="date"
          name={`due_date_${type.toLowerCase()}`}
          id={`due_date_${type.toLowerCase()}`}
        />
      </div>
    </div>
  );
  return (
    <div className="card-height" additionalClass="mt-4 p-4">
      <h5 style={{ fontWeight: "bolder" }} className="co-grayx">
        Approve of Performance
      </h5>

      <div
        style={{
          padding: "10px",
          maxHeight: "680px",
        }}
        className="col-12 col-md-12 list-achievements"
      >
        {/* <form> */}
        <div className="select-container mt-3">
          <strong>Select Employees to view</strong>
          <select style={{ border: "1px solid #e8e9eb" }}>
            <option value="option1">Select an Option</option>
            <option value="notStarted">Mason Jackson</option>
            <option value="inProgress">Harper Jack</option>
            <option value="completed">Avery Wyatt</option>
            <option value="cancelled">Carter Grayson</option>
          </select>
        </div>

        <br />

        <strong>Click on each performance to add</strong>
        <div className="mt-3">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button
                className={`nav-link d-flex align-items-center justify-content-center ${
                  activeTab === "Leadership" ? "active" : ""
                }`}
                onClick={() => {
                  handleTabClick("Leadership");
                }}
                style={{
                  backgroundColor:
                    activeTab === "Leadership" ? "#dcdcdc" : "#3A6ED4",
                  color: activeTab === "Leadership" ? "#fff" : "#fff",
                }}
              >
                <BsPeople className="mb-1" /> Leadership
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link d-flex align-items-center justify-content-center ${
                  activeTab === "Performance" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Performance")}
                style={{
                  backgroundColor:
                    activeTab === "Performance" ? "#dcdcdc" : "#3A6ED4",
                  color: activeTab === "Performance" ? "#fff" : "#fff",
                  marginLeft: "2rem",
                }}
              >
                <BsGraphUp className="mb-1" /> Performance
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link d-flex align-items-center justify-content-center ${
                  activeTab === "Development" ? "active" : ""
                }`}
                onClick={() => {
                  handleTabClick("Development");
                }}
                style={{
                  backgroundColor:
                    activeTab === "Development" ? "#dcdcdc" : "#3A6ED4",
                  color: activeTab === "Development" ? "#fff" : "#fff",
                  marginLeft: "2rem",
                }}
              >
                <IoPersonSharp className="mb-1" /> Development
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link d-flex align-items-center justify-content-center ${
                  activeTab === "All" ? "active" : ""
                }`}
                onClick={() => {
                  handleTabClick("All");
                }}
                style={{
                  backgroundColor: activeTab === "All" ? "#dcdcdc" : "#3A6ED4",
                  color: activeTab === "All" ? "#fff" : "#fff",
                  marginLeft: "2rem",
                }}
              >
                <IoPersonSharp className="mb-1" /> All
              </button>
            </li>
          </ul>

          <div className="tab-content mt-3">
            <div
              className={`tab-pane fade ${
                activeTab === "Leadership" ? "show active" : ""
              }`}
              id="leadership-tab"
              style={{ marginTop: "7px" }}
            >
              {leadershipTextareas.map((textarea) => (
                <TextareaWithAddRemoveButtons
                  key={textarea.id}
                  id={textarea.id}
                  placeholder={textarea.placeholder}
                  type={textarea.type}
                  onAdd={addLeadershipTextarea}
                  onRemove={() => removeLeadershipTextarea(textarea.id)}
                />
              ))}
              <br />
              <br />
              {/* {textareas.map((textarea) => (
                <div key={textarea.id}>
                  <textarea
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      height: "7rem",
                      border: "1px solid #e8e9eb",
                      borderRadius: "0.875rem",
                      padding: "1rem",
                      marginBottom: "8px",
                    }}
                    placeholder={textarea.placeholder}
                  />
                  <div className="d-flex" style={{ gap: "10px" }}>
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "#3A6ED4",
                        padding: "10px",
                        color: "#fff",
                        borderRadius: "5px",
                      }}
                      onClick={addTextarea}
                    >
                      Add
                    </button>
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "red",
                        padding: "10px",
                        color: "#fff",
                        borderRadius: "5px",
                      }}
                      onClick={() => removeTextarea(textarea.id)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              ))} */}
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "Performance" ? "show active" : ""
              }`}
              id="performance-tab"
              style={{ marginTop: "7px" }}
            >
              {performanceTextareas.map((textarea) => (
                <TextareaWithAddRemoveButtons
                  key={textarea.id}
                  id={textarea.id}
                  placeholder={textarea.placeholder}
                  type={textarea.type}
                  onAdd={addPerformanceTextarea}
                  onRemove={() => removePerformanceTextarea(textarea.id)}
                />
              ))}
              <br />
              <br />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "Development" ? "show active" : ""
              }`}
              id="development-tab"
              style={{ marginTop: "7px" }}
            >
              {developmentTextareas.map((textarea) => (
                <TextareaWithAddRemoveButtons
                  key={textarea.id}
                  id={textarea.id}
                  placeholder={textarea.placeholder}
                  type={textarea.type}
                  onAdd={addDevelopmentTextarea}
                  onRemove={() => removeDevelopmentTextarea(textarea.id)}
                />
              ))}
              <br />
              <br />
            </div>
          </div>
        </div>

        <br />

        <br />
        <div>
          <strong className="mt-5">Edit goal (Free text)</strong>
          <CustomTextArea label="" />
        </div>
        <div>
          <strong>Target Measurement (%)</strong>
          <CustomInput placeholder="Enter your target measurement " label="" />
        </div>
        <br />
        <div className="select-container mt-3">
          <strong>Status</strong>
          <select style={{ border: "1px solid #e8e9eb" }}>
            <option value="option1">Select an Option</option>
            <option value="notStarted">Not started</option>
            <option value="inProgress">In Progress</option>
            <option value="completed">Completed</option>
            <option value="cancelled">Cancelled</option>
          </select>
        </div>
        <br />
        <br />

        <CustomButton backgroundColor="#3A6ED4" color="#fff" text="Save" />
        <br />
        <br />
        <br />
        <CustomButton
          style={{ marginTop: "10px" }}
          backgroundColor="#3A6ED4"
          color="#fff"
          text="Save"
        />
        {/* </form> */}
      </div>
    </div>
  );
}

export default AdminApproval;
