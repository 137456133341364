import React from "react";
import { useState, useEffect } from "react";
import CustomButton from "../../../../components/CustomButton";
import Card from "../../../../components/Card";
import { toast } from "react-toastify";
// import { , toast } from "react-toastify";
import { IoPersonSharp } from "react-icons/io5";
import CustomInput from "../../../../components/CustomInput";
import { BsPeople, BsGraphUp, BsGearWideConnection } from "react-icons/bs"; // Import icons
import CustomTextArea from "../../../../components/CustomTextArea";
import "../AddPerformance/add.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmployee,
  getPerformance,
  postemployeeperformance,
  getEachPerformance,
} from "../../../../redux/actions/jobActions";

function EmployeeApprasial() {
  const [activeTab, setActiveTab] = useState("Leadership");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const [newDiv, setNewDivs] = useState([]);
  const dispatch = useDispatch();
  const [newDivv, setNewDivv] = useState([]);
  const [newDivs, setNewDiv] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [newDivGoals, setNewGoals] = useState([]);
  const [newDivvGoals, setNewGoal] = useState([]);
  const [newDivsGoals, setNewGoall] = useState([]);
  const [employeeAppraisal, setEmployeeAppraisal] = useState([]);
  const [performanceData, setPerformanceData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [leaderText, setLeaderText] = useState({
    appraisal: "",
  });
  const [leaderTexts, setLeaderTexts] = useState({
    appraisal: "",
  });
  const [leaderTextt, setLeaderTextt] = useState({
    appraisal: "",
  });
  const [goalSubmit, setGoalSubmit] = useState(false);

  const [sendEmployee, setSendEmployee] = useState({
    employee_id: "",
    employee_name: "",
    employee_goals: [],
    employee_appraisal: [],
  });

  const handleNewGoall = () => {
    setNewGoals((prevDivGoals) => [...prevDivGoals]);
  };
  const handleNewGoal = () => {
    setNewGoal((prevDivvGoals) => [...prevDivvGoals]);
  };
  const handleNewGoals = () => {
    setNewGoall((prevDivsGoals) => [...prevDivsGoals]);
  };

  useEffect(() => {
    // handleNewDivs();
    // handleNewDiv();
    handleNewGoal();
    handleNewGoals();
    handleNewGoall();
    // handleNewDivv();
  }, []);

  useEffect(() => {
    getEmployee(dispatch)
      .then((action) => {
        setEmployees(action.payload.data);
        console.log(action.payload.data);
      })
      .catch((error) => {
        console.error("Error fetching employees:", error);
      })
      .finally(() => {
        setTimeout(() => {}, 1000);
      });
  }, [dispatch]);
  const handleEmployeeChange = (e) => {
    const value = e.target.value;
    const employee = employees.find((all) => all.first_name === value);
    if (employee) {
      setSelectedEmployee(employee);
      setSendEmployee((prevSendEmployee) => ({
        ...prevSendEmployee,
        employee_id: employee.id,
        employee_name: employee.first_name,
      }));

      setTimeout(() => {
        getPerformance(dispatch, employee.id)
          .then((action) => {
            console.log(action.payload.data);

            const performanceData = JSON.parse(
              action.payload.data.employee_goals
            );
            const employeeAppraisal = JSON.parse(
              action.payload.data.employee_appraisal
            );

            setPerformanceData(performanceData);
            setEmployeeAppraisal(employeeAppraisal);
            console.log(performanceData);
          })
          .catch((error) => {
            console.error(error);
          });
      }, 1000);
    }
  };
  const handleSubmit = () => {
    const formDatas = [
      {
        Leadership: performanceData.Leadership.map((goal) => ({
          ...goal,
        })),
        type: "Leadership",
        employee_appraisal: leaderText.appraisal,
      },
      {
        Performance: performanceData.Performance.map((goal) => ({
          ...goal,
        })),
        type: "Performance",
        employee_appraisal: leaderTexts.appraisal,
      },
      {
        Development: performanceData.Development.map((goal) => ({
          ...goal,
        })),
        type: "Development",
        employee_appraisal: leaderTextt.appraisal,
      },
    ];

    localStorage.setItem("appraisalData", JSON.stringify(formDatas));

    // .success("Employee Appraisal for saved successfully");
    console.log(formDatas);
  };
  const handleSubmitt = () => {
    const formDatas = [
      {
        type: "Leadership",
        employee_appraisal: leaderText.appraisal,
      },
      {
        type: "Performance",
        employee_appraisal: leaderTexts.appraisal,
      },
      {
        type: "Development",
        employee_appraisal: leaderTextt.appraisal,
      },
    ];

    localStorage.setItem("appraisalData", JSON.stringify(formDatas));
    setGoalSubmit(true);
    // .success("Employee Appraisal for saved successfully");
    console.log(formDatas);
  };

  const handleAll = () => {
    const employeeId = sendEmployee.employee_id;
    const employeeName = sendEmployee.employee_name;

    const appraisalData = JSON.parse(localStorage.getItem("appraisalData"));

    const sendData = {
      employee_id: employeeId,
      employee_name: employeeName,
      employee_appraisal: JSON.stringify(appraisalData),
      employee_goals: JSON.stringify(performanceData),
    };

    console.log(sendData);
    // Send data to the backend
    postemployeeperformance(dispatch, sendData, employeeId);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLeaderText((prevLeaderText) => ({
      ...prevLeaderText,
      [name]: value,
    }));
  };
  const handleChangee = (e) => {
    const { name, value } = e.target;
    setLeaderTextt((prevLeaderTextt) => ({
      ...prevLeaderTextt,
      [name]: value,
    }));
  };
  const handleChanges = (e) => {
    const { name, value } = e.target;
    setLeaderTexts((prevLeaderTexts) => ({
      ...prevLeaderTexts,
      [name]: value,
    }));
  };
  return (
    <div className="card-height" additionalClass="mt-4 p-4">
      <h5 style={{ fontWeight: "bolder" }} className="co-grayx">
        {" "}
        Employee's Apprasial
      </h5>
      <br />
      <br />

      <div
        style={{
          padding: "10px",
          maxHeight: "680px",
        }}
        className="col-12 col-md-12 list-achievements"
      >
        {/* <form> */}
        <div>
          <div className="select-container mt-3">
            <strong>Select Employee to view</strong>
            <select
              style={{ border: "1px solid #e8e9eb" }}
              onChange={handleEmployeeChange}
            >
              <option value="">Select an Option</option>
              {employees &&
                employees.map((employee) => (
                  <option key={employee.id} value={employee.first_name}>
                    {employee.first_name}
                  </option>
                ))}
            </select>
          </div>
        </div>

        {selectedEmployee ? (
          <div className="mt-3">
            <strong>Click on each performance to view your Goals</strong>
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <button
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    activeTab === "Leadership" ? "active" : ""
                  }`}
                  onClick={() => {
                    handleTabClick("Leadership");
                  }}
                  style={{
                    backgroundColor:
                      activeTab === "Leadership" ? "#dcdcdc" : "#3A6ED4",
                    color: activeTab === "Leadership" ? "#fff" : "#fff",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  <BsPeople className="mb-1" /> Leadership
                </button>
              </li>

              <li className="nav-item">
                <button
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    activeTab === "Performance" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Performance")}
                  style={{
                    backgroundColor:
                      activeTab === "Performance" ? "#dcdcdc" : "#3A6ED4",
                    color: activeTab === "Performance" ? "#fff" : "#fff",
                    marginLeft: "2rem",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  <BsGraphUp /> Performance
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link d-flex align-items-center justify-content-center ${
                    activeTab === "Development" ? "active" : ""
                  }`}
                  onClick={() => {
                    handleTabClick("Development");
                  }}
                  style={{
                    backgroundColor:
                      activeTab === "Development" ? "#dcdcdc" : "#3A6ED4",
                    color: activeTab === "Development" ? "#fff" : "#fff",
                    marginLeft: "2rem",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  <IoPersonSharp /> Development
                </button>
              </li>
            </ul>

            <div className="tab-content mt-3">
              <div
                className={`tab-pane fade ${
                  activeTab === "Leadership" ? "show active" : ""
                }`}
                id="leadership-tab"
                style={{ marginTop: "7px" }}
              >
                <main>
                  {performanceData && performanceData.Leadership && (
                    <div>
                      {performanceData.Leadership.map((goal, index) => (
                        <div key={index}>
                          <strong>Leadership Goal/Objective(Title)</strong>
                          <CustomInput
                            style={{
                              width: "100%",
                              marginTop: "10px",
                              height: "2rem",
                              border: "1px solid #e8e9eb",
                              borderRadius: "0.875rem",
                              padding: "1rem",
                              marginBottom: "8px",
                            }}
                            placeholder="Enter your Leadership goal title"
                            name="goal_title"
                            value={goal.goal_title}
                            // value={
                            //   leadershipPerformanceData.goal_title || sendEmployee.goal_title
                            // }
                          />
                          <br />
                          <div>
                            <strong>
                              Write your Leadership Goals/Objectives (Free text)
                            </strong>
                            <textarea
                              style={{
                                width: "100%",
                                marginTop: "10px",
                                height: "9rem",
                                border: "1px solid #e8e9eb",
                                borderRadius: "0.875rem",
                                padding: "1rem",
                                marginBottom: "8px",
                              }}
                              placeholder="Enter your Leadership goals"
                              name="goal_content"
                              value={goal.goal_content}
                            />
                          </div>
                          <br />

                          <div className="select-container">
                            <strong>Status</strong>
                            <select
                              name="status"
                              value={
                                goal.status // Accessing the first element of the array
                              }
                              style={{ border: "1px solid #e8e9eb" }}
                            >
                              <option value="option1">Select an Option</option>
                              <option value="notStarted">Not started</option>
                              <option value="inProgress">In Progress</option>
                              <option value="completed">Completed</option>
                              <option value="cancelled">Cancelled</option>
                            </select>
                          </div>
                          <br />
                          <br />

                          <div>
                            <strong>Target Measurement (%)</strong>
                            <CustomInput
                              type="text"
                              placeholder={`Enter your target measurement for Leadership Goals`}
                              label=""
                              id={`target_measurement_for leadership goals`}
                              name="target_measurement"
                              value={
                                goal.target_measurement // Accessing the first element of the array
                              }
                            />
                          </div>
                          <br />

                          <div>
                            <strong>Target Achieved (%)</strong>
                            <CustomInput
                              name="target_achieved"
                              value={
                                goal.target_achieved // Accessing the first element of the array
                              }
                              type="text"
                              placeholder={`Enter your target measurement for Leadership Goals`}
                              label=""
                              id={`target_measurement_for leadership goals`}
                            />
                          </div>
                          <br />

                          <div>
                            <strong>Due Date</strong> <br />
                            <input
                              style={{ border: "1px solid #e8e9eb" }}
                              className="select-contain"
                              type="date"
                              name="due_date"
                              value={goal.due_date}
                              id={`due_date_for Leadership goals`}
                            />
                          </div>
                          <br />
                          <br />
                        </div>
                      ))}
                      {employeeAppraisal ? (
                        employeeAppraisal
                          .filter((item) => item.type === "Leadership")
                          .map((item, index) => (
                            <div key={index}>
                              <strong className="mt-5">
                                Summary of {sendEmployee.employee_name} Review
                                Comment (Free text)
                              </strong>
                              <CustomTextArea
                                value={item.employee_appraisal}
                                label=""
                              />
                            </div>
                          ))
                      ) : (
                        <div>
                          <strong className="mt-5">
                            Summary of {sendEmployee.employee_name} Review
                            Comment (Free text)
                          </strong>
                          <CustomTextArea
                            name="appraisal"
                            value={leaderText.appraisal || ""}
                            label=""
                            onChange={handleChange}
                          />

                          <br />
                          <CustomButton
                            backgroundColor="#3A6ED4"
                            color="#fff"
                            text="Save"
                            disabled={!leaderText.appraisal}
                            handleClick={handleSubmit}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {newDiv &&
                    newDiv.map((el, index) => (
                      <div key={index}>
                        {el}

                        <button
                          className="rounded-3 border border-none"
                          style={{
                            width: "10%",
                            color: "#fff",
                            height: "6vh",
                            borderRadius: "5px",
                            backgroundColor: "#3A6ED4",
                          }}
                          color="#fff"
                          text="Submit"
                        >
                          Submit
                        </button>
                      </div>
                    ))}
                </main>

                <br />
                <br />
              </div>

              <div
                className={`tab-pane fade ${
                  activeTab === "Performance" ? "show active" : ""
                }`}
                id="performance-tab"
                style={{ marginTop: "7px" }}
              >
                <main>
                  {performanceData && performanceData.Performance && (
                    <div>
                      {performanceData.Performance.map((goal, index) => (
                        <div key={index}>
                          <strong>Performance Goal/Objective(Title)</strong>
                          <CustomInput
                            style={{
                              width: "100%",
                              marginTop: "10px",
                              height: "2rem",
                              border: "1px solid #e8e9eb",
                              borderRadius: "0.875rem",
                              padding: "1rem",
                              marginBottom: "8px",
                            }}
                            placeholder="Enter your Performance goal title"
                            name="goal_title"
                            value={goal.goal_title}
                            // value={
                            //   leadershipPerformanceData.goal_title || sendEmployee.goal_title
                            // }
                          />
                          <br />
                          <div>
                            <strong>
                              Write your Performance Goals/Objectives (Free
                              text)
                            </strong>
                            <textarea
                              style={{
                                width: "100%",
                                marginTop: "10px",
                                height: "9rem",
                                border: "1px solid #e8e9eb",
                                borderRadius: "0.875rem",
                                padding: "1rem",
                                marginBottom: "8px",
                              }}
                              placeholder="Enter your Performace goals"
                              name="goal_content"
                              value={goal.goal_content}
                            />
                          </div>
                          <br />

                          <div className="select-container">
                            <strong>Status</strong>
                            <select
                              name="status"
                              value={
                                goal.status // Accessing the first element of the array
                              }
                              style={{ border: "1px solid #e8e9eb" }}
                            >
                              <option value="option1">Select an Option</option>
                              <option value="notStarted">Not started</option>
                              <option value="inProgress">In Progress</option>
                              <option value="completed">Completed</option>
                              <option value="cancelled">Cancelled</option>
                            </select>
                          </div>
                          <br />
                          <br />

                          <div>
                            <strong>Target Measurement (%)</strong>
                            <CustomInput
                              type="text"
                              placeholder={`Enter your target measurement for Performance Goals`}
                              label=""
                              id={`target_measurement_for Performance goals`}
                              name="target_measurement"
                              value={
                                goal.target_measurement // Accessing the first element of the array
                              }
                            />
                          </div>
                          <br />

                          <div>
                            <strong>Target Achieved (%)</strong>
                            <CustomInput
                              name="target_achieved"
                              value={
                                goal.target_achieved // Accessing the first element of the array
                              }
                              type="text"
                              placeholder={`Enter your target measurement for Performance Goals`}
                              label=""
                              id={`target_measurement_for Performance goals`}
                            />
                          </div>
                          <br />

                          <div>
                            <strong>Due Date</strong> <br />
                            <input
                              style={{ border: "1px solid #e8e9eb" }}
                              className="select-contain"
                              type="date"
                              name="due_date"
                              value={goal.due_date}
                              id={`due_date_for Performance goals`}
                            />
                          </div>
                          <br />

                          <br />
                        </div>
                      ))}
                      {employeeAppraisal ? (
                        employeeAppraisal
                          .filter((item) => item.type === "Performance")
                          .map((item, index) => (
                            <div key={index}>
                              <strong className="mt-5">
                                Summary of {sendEmployee.employee_name} Review
                                Comment (Free text)
                              </strong>
                              <CustomTextArea
                                value={item.employee_appraisal}
                                label=""
                              />
                            </div>
                          ))
                      ) : (
                        <div>
                          <strong className="mt-5">
                            Summary of {sendEmployee.employee_name} Review
                            Comment (Free text)
                          </strong>
                          <CustomTextArea
                            name="appraisal"
                            value={leaderTexts.appraisal || ""}
                            label=""
                            onChange={handleChanges}
                          />
                          <br />
                          <CustomButton
                            className="rounded-3 border border-none"
                            text="Save"
                            backgroundColor="#3A6ED4"
                            disabled={!leaderTexts.appraisal}
                            handleClick={handleSubmit}
                          />
                        </div>
                      )}

                      {/* <div>
                        <strong className="mt-5">
                          Summary of Employee Review Comment(Free text)
                        </strong>
                        <CustomTextArea
                          name="appraisal"
                          value={leaderTexts.appraisal}
                          onChange={handleChanges}
                          label=""
                        />
                      </div> */}
                    </div>
                  )}
                  {newDivvGoals &&
                    newDivvGoals.map((el, index) => (
                      <div key={index}>
                        {el}
                        {/* {index == newDivvGoals.length - 1 && (
                        <button
                          className="rounded-3 border border-none"
                          style={{
                            width: "10%",
                            color: "#fff",
                            height: "6vh",
                            borderRadius: "5px",
                            backgroundColor: "#3A6ED4",
                          }}
                          color="#fff"
                          text="Submit"
                          onClick={handleNewGoal}
                        >
                          Add
                        </button>
                      )} */}
                        <button
                          className="rounded-3 border border-none"
                          style={{
                            width: "10%",
                            color: "#fff",
                            height: "6vh",
                            borderRadius: "5px",
                            backgroundColor: "#3A6ED4",
                          }}
                          color="#fff"
                          text="Submit"
                        >
                          Submit
                        </button>
                      </div>
                    ))}
                </main>
                <br />
                <br />
              </div>

              <div
                className={`tab-pane fade ${
                  activeTab === "Development" ? "show active" : ""
                }`}
                id="development-tab"
                style={{ marginTop: "7px" }}
              >
                <main>
                  {performanceData && performanceData.Development && (
                    <div>
                      {performanceData.Development.map((goal, index) => (
                        <div key={index}>
                          <strong>Development Goal/Objective(Title)</strong>
                          <CustomInput
                            style={{
                              width: "100%",
                              marginTop: "10px",
                              height: "2rem",
                              border: "1px solid #e8e9eb",
                              borderRadius: "0.875rem",
                              padding: "1rem",
                              marginBottom: "8px",
                            }}
                            placeholder="Enter your Development goal title"
                            name="goal_title"
                            value={goal.goal_title}
                            // value={
                            //   leadershipPerformanceData.goal_title || sendEmployee.goal_title
                            // }
                          />
                          <br />
                          <div>
                            <strong>
                              Write your Development Goals/Objectives (Free
                              text)
                            </strong>
                            <textarea
                              style={{
                                width: "100%",
                                marginTop: "10px",
                                height: "9rem",
                                border: "1px solid #e8e9eb",
                                borderRadius: "0.875rem",
                                padding: "1rem",
                                marginBottom: "8px",
                              }}
                              placeholder="Enter your Development goals"
                              name="goal_content"
                              value={goal.goal_content}
                            />
                          </div>
                          <br />

                          <div className="select-container">
                            <strong>Status</strong>
                            <select
                              name="status"
                              value={
                                goal.status // Accessing the first element of the array
                              }
                              style={{ border: "1px solid #e8e9eb" }}
                            >
                              <option value="option1">Select an Option</option>
                              <option value="notStarted">Not started</option>
                              <option value="inProgress">In Progress</option>
                              <option value="completed">Completed</option>
                              <option value="cancelled">Cancelled</option>
                            </select>
                          </div>
                          <br />
                          <br />

                          <div>
                            <strong>Target Measurement (%)</strong>
                            <CustomInput
                              type="text"
                              placeholder={`Enter your target measurement for Development Goals`}
                              label=""
                              id={`target_measurement_for leadership goals`}
                              name="target_measurement"
                              value={
                                goal.target_measurement // Accessing the first element of the array
                              }
                            />
                          </div>
                          <br />

                          <div>
                            <strong>Target Achieved (%)</strong>
                            <CustomInput
                              name="target_achieved"
                              value={
                                goal.target_achieved // Accessing the first element of the array
                              }
                              type="text"
                              placeholder={`Enter your target measurement for Development Goals`}
                              label=""
                              id={`target_measurement_for Development goals`}
                            />
                          </div>
                          <br />

                          <div>
                            <strong>Due Date</strong> <br />
                            <input
                              style={{ border: "1px solid #e8e9eb" }}
                              className="select-contain"
                              type="date"
                              name="due_date"
                              value={goal.due_date}
                              id={`due_date_for Development goals`}
                            />
                          </div>
                          <br />
                        </div>
                      ))}
                      {employeeAppraisal ? (
                        employeeAppraisal
                          .filter((item) => item.type === "Development")
                          .map((item, index) => (
                            <div key={index}>
                              <strong className="mt-5">
                                Summary of {sendEmployee.employee_name} Review
                                Comment (Free text)
                              </strong>
                              <CustomTextArea
                                value={item.employee_appraisal}
                                label=""
                              />
                            </div>
                          ))
                      ) : (
                        <div>
                          <strong className="mt-5">
                            Summary of {sendEmployee.employee_name} Review
                            Comment (Free text)
                          </strong>
                          <CustomTextArea
                            name="appraisal"
                            classes="pt-4 pr-5"
                            value={leaderTextt.appraisal || ""}
                            label=""
                            onChange={handleChangee}
                          />
                          <br />
                          {!goalSubmit ? (
                            <CustomButton
                              className="rounded-3 border border-none"
                              color="#fff"
                              backgroundColor="#3A6ED4"
                              text="Save"
                              disabled={!leaderTextt.appraisal}
                              handleClick={handleSubmitt}
                            />
                          ) : (
                            <CustomButton
                              className="rounded-3 border border-none"
                              backgroundColor="#3A6ED4"
                              color="#fff"
                              text="Submit"
                              handleClick={handleAll}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {newDivs &&
                    newDivs.map((el, index) => (
                      <div key={index}>
                        {el}
                        {/* {index == newDivs.length - 1 && (
                        <button
                          className="rounded-3 border border-none"
                          style={{
                            width: "10%",
                            color: "#fff",
                            height: "6vh",
                            borderRadius: "5px",
                            backgroundColor: "#3A6ED4",
                          }}
                          color="#fff"
                          text="Submit"
                          onClick={handleNewDivv}
                        >
                          Add
                        </button>
                      )} */}
                        <button
                          className="rounded-3 border border-none"
                          style={{
                            width: "10%",
                            color: "#fff",
                            height: "6vh",
                            borderRadius: "5px",
                            backgroundColor: "#3A6ED4",
                          }}
                          color="#fff"
                          text="Submit"
                        >
                          Edit
                        </button>
                      </div>
                    ))}
                </main>
                <br />
                <br />
              </div>
            </div>
          </div>
        ) : null}

        <br />

        <br />

        {/* </form> */}
      </div>
    </div>
  );
}

export default EmployeeApprasial;
