import axios from "axios";
import cogoToast from "cogo-toast";
import { logoutAction } from "../../redux/actions/authActions";

/**
 * Error construct
 */
const errorConstruct = {
  response: {
    data: {
      message: "Please check your internet connection",
    },
  },
};

/**
 * Cogo toast options
 */
const cogoToastOptions = {
  hideAfter: 4,
  position: "top-center",
};

/**
 * @function requestInterceptor
 * @description request interceptor
 * @param {object} axiosLib - axiosLib
 * @param {class} ErrorClass - error class
 * @returns {object} - axios promise
 */
export const requestInterceptor = (axiosLib = axios, ErrorClass = Error) => {
  return axiosLib.interceptors.request.use(
    (config) => {
      return config;
    },
    (err) => {
      try {
        if (window.navigator.onLine) {
          return Promise.reject(err);
        }
        throw ErrorClass();
      } catch (cbErr) {
        return Promise.reject(errorConstruct);
      }
    }
  );
};

/**
 * @description Response Interceptor
 * @param {object} axiosLib - axiosLib
 * @param {class} ErrorClass - error class
 * @returns {object} - axios promise
 */
export const responseInterceptor = (axiosLib = axios, ErrorClass = Error) => {
  return axiosLib.interceptors.response.use(
    (response) => {
      const message = response?.data?.message ?? "Successful";

      if (
        [
          response?.config?.method.toLowerCase() !== "get",
          !response.config.url.toLowerCase().includes("cloudinary"),
        ].every((val) => val === true)
      ) {
        cogoToast.success(message, cogoToastOptions);
      }
      return response;
    },
    (err) => {
      try {
        if (window.navigator.onLine) {
          const errorMessage =
            err?.response?.data?.message ??
            err?.response?.data?.error?.message ??
            "Unsuccessful";
          const statusCode = err.response.status;
          const userInfo = localStorage.userData ?? sessionStorage.userData;
          const userToken = localStorage.token ?? sessionStorage.token;

          const usersCredentials = [!!userInfo, !!userToken].includes(true);
          if (statusCode === 401 && usersCredentials) {
            /**
             * checks if the statusCode is a 401 and invokes the logout procedure
             */
            logoutAction();
          }
          if (
            [
              err.response.config.method.toLowerCase() !== "get",
              !err.response.config.url.toLowerCase().includes("cloudinary"),
            ].every((val) => val === true)
          ) {
            cogoToast.error(errorMessage, cogoToastOptions);
          }
          return Promise.reject(err);
        }
        throw ErrorClass();
      } catch (cbErr) {
        return Promise.reject(errorConstruct);
      }
    }
  );
};
