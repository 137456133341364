import React, { useState, useEffect, useRef, useCallback } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import axios from "axios";
import Drawer from "@material-ui/core/Drawer";
import cogoToast from "cogo-toast";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import IconButton from "@material-ui/core/IconButton";
import icons from "../assets/icons/icon-collection.svg";
import Menu from "./Menu";
import BusinessMenu from "./Menu/BusinessMenu";
import ToolbarMenu from "./ToolbarMenu";
import { bool, node } from "prop-types";

import "./index.scss";
import LeaderBoard from "./LeaderBoard";
import ExpertBoard from "./ExpertBoard";
import { useLocation, useHistory } from "react-router-dom";
import { uploadFile } from "../utils/helpers";

import {
  createJobAction,
  createJobFromExcelAction,
  getJobLevelsAction,
  clearJobCreated,
  getVerification,
  postJobValidation,
} from "../redux/actions/jobActions";
import { createBusinessJobAction } from "../redux/actions/businessActions";
import { getStatus } from "../redux/actions/jobActions";
import { getIndustryAction } from "../redux/actions/industryActions";
import { getAllSkillsAction } from "../redux/actions/skillsAction";

import CustomButton from "../components/CustomButton";
import Plus from "../assets/icons/Plus.svg";
import Upload from "../assets/icons/upload.svg";
import AddNewPost from "./NewPost";
import BusinessAddNewPost from "../Pages/Business/BusinessNewPost";
import { useDispatch, useSelector } from "react-redux";
import { DemoPointer } from "../demo";
import CustomInput from "../components/CustomInput";
import { demoAction } from "../redux/actions/demoActions";
import ReactTooltip from "react-tooltip";
import SlidingPane from "react-sliding-pane";
import JobDetails from "./NewPost/JobDetails/";
import AddDocuments from "./NewPost/JobDocuments/";
import { openRecruiterModal } from "../redux/actions/demoActions";
import { axiosSetup } from "../ajax";

const drawerWidth = 240;

const isBusinessUser = () => {
  const accountType = localStorage.getItem("accountType");
  return accountType === "business";
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    width: `calc(100% - 73px)`,
    [theme.breakpoints.down("xs")]: {
      width: `calc(100% - 60px)`,
    },
    boxShadow: "2px 2px #eeeeee",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  black: {
    background: theme.palette.warning.main,
  },
  blue: {
    background: theme.palette.primary.main,
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    background: "#ccc",
  },
}));

const Layout = ({
  children,
  recruiter,
  isOnDuplicatePost,
  duplicatedDataValue,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const uploadInputRef = useRef(0);

  const [open, setOpen] = useState(true);
  const [newPost, setNewPost] = useState(false);
  const [massUpload, setMassUpload] = useState(false);
  const [massConfirm, setMassConfirm] = useState(false);
  const [success, setSuccess] = useState("");
  const [status, setStatus] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [canPost, setCanPost] = useState(true);
  const [isFetching, setIsFetching] = useState(true);
  const [message, setMessage] = useState(false);
 const [jobCreated, setJobCreated] = useState(false);
  const [industryOptions, setIndustryOptions] = useState({});
  const [workTypeOptions, setWorkTypeOptions] = useState({});
  const [section, setSection] = useState("details");
  const { profile } = useSelector((store) => store);
  const { profileData } = profile;
  const [levelOptions, setLevelOptions] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [skillOptions, setSkillOptions] = useState({});
  const [isJobCreated, setIsJobCreated] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [verified, setVerified] = useState(false);
  const[displayMessage, setDisplayMessage]= useState(false)

  const { pathname } = useLocation();
  const [width, setWidth] = useState(window.innerWidth);
 
  const [newJob, setNewJob] = useState({
    campany_name: "",
    industry: "0",
    about_company: "",
    no_of_staff: "0",
    job_level: "0",
    role_title: "",
    job_type: "0",
    reports_to: "",
    applicant_benefit: "salary",
    skills_required: [],
    location: "",
    additional_files: [],
    isToRefTimeLineOrToHireRecruter: 1,
    isCompanyRepresentative: "1",
    salary: "",
    duration: "",
    canRecruiterRequest: "0",
    country: 0,

  });
  const [review, setReview] = useState({
    name: "",
    user_id: "",
    tax_id: "",
    website: "",
    role: [],
  });

  const { industry, skill, job } = useSelector((store) => store);
  const { allSkills } = skill;
  const [isChecked, setIsChecked] = useState(false);
  const { industries } = industry;
  const [loading, setLoading] = useState(false);
  const { jobLevels} = job;
  const accountType = localStorage.getItem("accountType");
   const {
    userData: { account_type, company },
  } = useSelector((store) => store.auth);

  const company_id = company ? company.id : "";
  

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  const handleForm = (e) => {
    const { name, value, checked, type } = e.target;
    if (type === "checkbox") {
      if (checked) {
        setReview({ ...review, role: [...review.role, value] });
      } else {
        setReview({
          ...review,
          role: review.role.filter((role) => role !== value),
        });
      }
    } else {
      setReview({ ...review, [name]: value });
    }
  };
  const handleSubmiting = async (e) => {
    const userDataString = sessionStorage.getItem("userData");
    const userData = JSON.parse(userDataString);
    const profileId = Number(userData.id);

    e.preventDefault();
    try {
      const data = {
        name: review.name,
        user_id: profileId,
        tax_id: review.tax_id,
        website: review.website,
        role: JSON.stringify(review.role),
      };
      console.log(data);
      const response = await postJobValidation(dispatch, data)
      
      
      setDisplayMessage(true)
      console.log(displayMessage)
      
      review.name = "";
      review.tax_id = "";
      review.website = "";
      review.role = "";
      // window.location.reload(false);
    } catch (error) {
      console.log(error);
       
    }
  };


  // useEffect(() => {
  //   setNewPost(isOnDuplicatePost);
  // }, [isOnDuplicatePost]);
  // useEffect(() => {
  //   if (width <= 768 && accountType !== "referral") {
  //     setOpen(false);
  //   } else {
  //     setOpen(true);
  //   }
  // }, [width]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const {
    userData: { user_type },
  } = useSelector((store) => store.auth);

  useEffect(() => {
    if (user_type === "1") {
      setCanPost(true);
    } else {
      setCanPost(false);
    }
  }, [dispatch]);

  const handleNewPost = () => {
    clearJobCreated(dispatch);
    setNewPost(true);
  };
  const handleShowConfirmation = () => {
    setShowConfirmation(true);
  };

  const handleClose = () => {
    setSuccess(false);
    setNewPost(false);
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const displayMenu = () => {
    return isBusinessUser() ? (
      <BusinessMenu open={open} toolbar={classes.toolbar} />
    ) : (
      <Menu open={open} toolbar={classes.toolbar} />
    );
  };

  useEffect(() => {
    Promise.all([
      getIndustryAction(dispatch),
      getJobLevelsAction(dispatch),
      getAllSkillsAction(dispatch),
    ]);
  }, [dispatch]);

  useEffect(() => {
    const options = {};
    industries &&
      industries.forEach(
        (industry) => (options[industry.title] = industry.title)
      );
    setIndustryOptions(options);
  }, [industries]);

  useEffect(() => {
    const options = {};
    allSkills.forEach((skill) => (options[skill.title] = skill.title));
    setSkillOptions(allSkills);

    // setSkillOptions(options);
  }, [allSkills]);

  useEffect(() => {
    const options = {};
    jobLevels &&
      jobLevels.forEach((level) => (options[level.id] = level.title));
    setLevelOptions(options);
  }, [jobLevels]);

  const handleChange = ({ target: { value, name } }) => {
    const changedObject = {};
    changedObject[name] = value;
    setNewJob({ ...newJob, ...changedObject });
  };

  const setSelectedSkills = (skills) => {
    setNewJob({ ...newJob, ...{ skills_required: skills } });
  };

  const sendToRecruiter = async (e) => {
    e.preventDefault();
    openRecruiterModal(dispatch);

    /*
    e.preventDefault();
    if (
      !newJob.campany_name ||
      !newJob.industry ||
      !newJob.about_company ||
      !newJob.no_of_staff ||
      !newJob.job_level ||
      !newJob.role_title ||
      !newJob.job_type ||
      !newJob.reports_to ||
      !newJob.applicant_benefit ||
      !newJob.skills_required ||
      !newJob.location ||
      !newJob.salary ||
      !newJob.duration
    ) {
      return cogoToast.error("Please fill all fields");
    }
    const job_level = parseInt(newJob.job_level, 10);
    const isCompanyRepresentative = parseInt(
      newJob.isCompanyRepresentative,
      10
    );
    const canRecruiterRequest = parseInt(newJob.canRecruiterRequest, 10);
    const jobToSend = {
      ...newJob,
      ...{ job_level, isCompanyRepresentative, canRecruiterRequest },
    };

    history.push({
      pathname: "/recruiter/hire",
      state: { jobToSend },
    });
    closeDialog();


    */
  };
  const updateSelectedCountryID = (cid) => {
    setNewJob({ ...newJob, ...{ country: cid } });
  };

  const addDocLink = (link) => {
    const links = [...newJob.additional_files];
    links.push(link);
    setNewJob({ ...newJob, ...{ additional_files: links } });
  };
  const [linksObject, setLinksObject] = useState([]);
  const addDocLinkObject = (link) => {
    const links = [...linksObject];
    links.push(link);
    setLinksObject(links);
  };
  const [value, setValue] = useState(0);

  const handleChangeUpload = ({ target: { files } }) => {
    if (files && files[0]) {
      setIsUploading(true);

      Promise.resolve(
        uploadFile(
          files[0],
          "https://api.cloudinary.com/v1_1/workbrook-hash/raw/upload"
        )
      )
        .then(({ data, success }) => {
          if (success) {
            addDocLink(data);
            addDocLinkObject({ link: data, name: files[0].name });
          } else {
            cogoToast.error("Unsuccessful document upload", {
              hideAfter: 4,
              position: "top-center",
            });
          }
        })
        .finally(() => {
          setIsUploading(false);
        });
    }
  };
  const handleRefClick = () => {
    uploadInputRef.current.click();
  };

  const handleSubmit = async (e) => {
    
    const job_level = parseInt(newJob.job_level, 10);
    const isCompanyRepresentative = parseInt(
      newJob.isCompanyRepresentative,
      10
    );

    e.preventDefault();
    // if (section === "documents") {
    //   setSection("details");
    // } else {
    await createJobAction(dispatch, {
      ...newJob,
      ...{
        job_level,
        isCompanyRepresentative,
        canRecruiterRequest: parseInt(newJob.canRecruiterRequest),
      },
    });
    setIsJobCreated(true);
    // }
  };

  useEffect(() => {
    fetchVerificationStatus();
    handleStatus()
    console.log(status)
  }, []);

  const fetchVerificationStatus = useCallback(async () => {
    try {
      const userDataString = sessionStorage.getItem("userData");
     
      const userData = JSON.parse(userDataString);
      const profileId = Number(userData.id);
      console.log("profileId", profileId);

      if (profileId) {
        const action = await getVerification(dispatch, profileId);
        const successes = action.payload.success;
        setSuccess(successes);
      
        console.log("Verification success", success.success);
      } else {
        console.error("Profile ID not found in session storage");
        return false;
      }
    } catch (error) {
      console.error("Error fetching verification status:", error);
      return false;
    } finally {
      setIsFetching(false);
    }
  }, [dispatch]);
const handleStatus = async () => {
     
    try {
      const userDataString = sessionStorage.getItem("userData");
     
    const userData = JSON.parse(userDataString);
      const profileId = Number(userData.id);
      console.log(profileId)
      if (profileId) {
        const action = await getStatus(dispatch, profileId)
       const status = action.payload.status
        
        setStatus(status)
      }
     
    
    } catch (error) {
      console.log(error)
      return false
    }
  }
  const handleExcelFileUpload = async (e) => {
    e.preventDefault();
    console.log(`linksObject === ${JSON.stringify(linksObject)}`);
    // [{"link":"https://res.cloudinary.com/workbrook-hash/raw/upload/v1691354941/Uploads/ua0v2oycek2ucvj44ivm.xlsx","name":"workbrook mass job template.xlsx"}]
    let data = {
      fileLink: linksObject[0].link,
    };
    console.log(`data === ${JSON.stringify(data)}`);

    await createJobFromExcelAction(dispatch, data);
    setIsJobCreated(true);
    // }
  };

  return (
    <div className="wrapper d-flex">
      <ReactTooltip />

      <AppBar
        color="secondary"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton)}
          >
            <svg width="18" height="16">
              <use href={`${icons}#menubutton`}></use>
            </svg>
          </IconButton>
          <ToolbarMenu />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.blue]: !recruiter,
          [classes.black]: recruiter,
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.blue]: !recruiter,
            [classes.black]: recruiter,
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        {displayMenu()}
      </Drawer>
      <main className={classes.content}>
        <div className="mt-5 d-flex">
          <div className="main-content mr-md-4">{children}</div>

          <div className="d-none d-md-block w-25">
            {canPost && accountType === "referral" && (!success && status === 'not_found' ? <CustomButton
              icon={Upload}
              backgroundColor="#3A6ED4"
              color="#fff"
              alt="add post"
              text={'Request to Upload Jobs from excel'}
              additionalClass="my-3 joyride-post-job"
              handleClick={(e) => {
                e.preventDefault()
                handleShowConfirmation();
               
              }}
            /> : status == 'pending' ? <CustomButton
              icon={Upload}
              backgroundColor="#3A6ED4"
              color="#fff"
              alt="add post"
              text={'Request Pending'}
              additionalClass="my-3 joyride-post-job"
              
            /> : status == 'denied' ? <CustomButton
              icon={Upload}
              backgroundColor="#3A6ED4"
              color="#fff"
              disabled
              alt="add post"
              text={"Request Denied"}
              additionalClass="my-3 joyride-post-job"
              
            />
              : status == 'approved' && <CustomButton
                icon={Upload}
                backgroundColor="#3A6ED4"
                color="#fff"
                alt="add post"
                text={"Upload Jobs from excel "}
                additionalClass="my-3 joyride-post-job"
                handleClick={(e) => {
                  setMassUpload(true);
               
                }}
              />)}
         {canPost && accountType === "referral" && (
  <>
    {status === "not_found" && !success && (
      <CustomButton
        icon={Plus}
        backgroundColor="#3A6ED4"
        color="#fff"
        disabled={isFetching}
        alt="add post"
        text="Request to Post a job"
        additionalClass="my-3 joyride-post-job"
        handleClick={(e) => {
          e.preventDefault();
          handleShowConfirmation();
        }}
      />
    )}

    {status === "pending" && (
      <CustomButton
        icon={Plus}
        backgroundColor="#3A6ED4"
        color="#fff"
        disabled={isFetching}
        alt="add post"
        text="Request Pending"
        additionalClass="my-3 joyride-post-job"
        handleClick={(e) => {
          e.preventDefault();
          handleShowConfirmation();
        }}
      />
    )}

    {status === "denied" && (
      <CustomButton
        icon={Plus}
        backgroundColor="#3A6ED4"
        color="#fff"
        disabled
        alt="add post"
        text="Request Denied"
        additionalClass="my-3 joyride-post-job"
      />
    )}

    {status === "approved" && (
      <CustomButton
        icon={Plus}
        backgroundColor="#3A6ED4"
        color="#fff"
        disabled={isFetching}
        alt="add post"
        text="Post a job"
        additionalClass="my-3 joyride-post-job"
        handleClick={(e) => {
          e.preventDefault();
          handleNewPost() 
        }}
      />
    )}
  </>
)}

   

            {/* {canPost && verified && ( */}
            {/* {!success ? <CustomButton
                icon={Plus}
                backgroundColor="#3A6ED4"
                color="#fff"
                disabled={isFetching}
              alt="add post"
              
               text={ "Request to Post a Job"}
                additionalClass="my-3 joyride-post-job"
                handleClick={(e) => {
                  e.preventDefault();

                  if (success) {
                    handleNewPost();
                  } else {
                    handleShowConfirmation();
                  }
                }}
              /> :  <CustomButton
                icon={Plus}
                backgroundColor="#3A6ED4"
                color="#fff"
                disabled={isFetching}
                alt="add post"
                text={"Post a New Job"}
                additionalClass="my-3 joyride-post-job"
                handleClick={(e) => {
                  e.preventDefault();

                  if (success) {
                    handleNewPost();
                  } else {
                    handleShowConfirmation();
                  }
                }}
              />} */}
           
            {/* )} */}
            {canPost && accountType === "business" && (
              <>
                <CustomButton
                  icon={Plus}
                  backgroundColor="#3A6ED4"
                  color="#fff"
                  alt="add post"
                  text={" Post a Job"}
                  additionalClass="my-3 joyride-post-job"
                  handleClick={(e) => {
                    e.preventDefault();
                    const state = {
                      ["Post a Job"]: false,
                      ["Click on Post a Job"]: false,
                      ["Post to referral timeline"]: true,
                      ["Don't forget to add your files and links"]: true,
                    };
                    demoAction(dispatch, state);
                    handleNewPost();
                  }}
                />  

                <CustomButton
                  icon={Upload}
                  backgroundColor="#3A6ED4"
                  color="#fff"
                  alt="add post"
                  text={"Upload Jobs from excel "}
                  additionalClass="my-3 joyride-post-job"
                  handleClick={(e) => {
                    setMassUpload(true);
                    
                  }}
                />  
              </>
            )} 
            
            {!canPost && <div style={{ height: "60px" }}></div>}
            {isBusinessUser() ? (
              <BusinessAddNewPost
                open={newPost}
                handleClose={handleClose}
                setSuccess={setSuccess}
                success={success}
              />
            ) : (
              <AddNewPost
                open={false}
                handleClose={handleClose}
                setSuccess={setSuccess}
                success={success}
              />
            )}
            {pathname.includes("league/list") ||
            pathname.includes("league/points") ? (
              <></>
            ) : (
              <div>
                {}
                <LeaderBoard />
                <br />
                <ExpertBoard />
                {/*   
      !isBusinessUser ?    */}
                <div>
                  <SlidingPane
                    className=""
                    overlayClassName="some-custom-overlay-class"
                    isOpen={massUpload}
                    title="Mass Upload"
                    subtitle="Upload Jobs from excel"
                    width="90%"
                    height="10%"
                    from={"right"}
                    onRequestClose={() => {
                      // triggered on "<" on left top click or on outside click
                      setMassUpload(false);
                      // setOpen(false);
                    }}
                  >
                    <div
                      className="col-12 col-md-6"
                      style={{ marginTop: "10%", marginLeft: "25%" }}
                    >
                      <input
                        ref={uploadInputRef}
                        type="file"
                        onChange={handleChangeUpload}
                        style={{ display: "none" }}
                      />

                      <CustomButton
                        backgroundColor="#FFF"
                        color="#3A6ED4"
                        text="Upload an Excel file"
                        handleClick={handleRefClick}
                        loading={isUploading}
                        style={{ height: 500 }}
                      />

                      <div className="pb-5">
                        <div className="d-flex flex-column">
                          <br />
                          <br />
                          {linksObject.map((fileLink, index) => (
                            <a
                              className="doc-linkx text-underline bg-primaryx mid-radius py-3 my-1 w-100 text-left p-2"
                              key={index.toString()}
                              href={fileLink.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b>
                                Filename:{" "}
                                {fileLink.name ? fileLink.name : fileLink.link}
                              </b>
                            </a>
                          ))}
                        </div>
                      </div>

                      <div className="col-12 col-md-12">
                        <CustomButton
                          type="submit"
                          // disabled= {linksObject.length <0 && true}

                          backgroundColor="#41B883"
                          handleClick={(e) => {
                            e.preventDefault();
                            const state = {
                              ["Post a Job"]: false,
                              ["Click on Post a Job"]: false,
                              ["Don't forget to add your files and links"]: false,
                              ["Post to referral timeline"]: true,
                              ["Fill In Details"]: false,
                            };
                            demoAction(dispatch, state);
                            handleExcelFileUpload(e);
                          }}
                            color
                            ="#fff"
                          text={"Publish Job to Referral"}
                          additionalClass="mx-3 mt-2"
                        />
                      </div>
                    </div>
                    </SlidingPane>
                    {accountType === "referral" && (
                       <SlidingPane
                      className=""
                      overlayClassName="some-custom-overlay-class"
                      isOpen={newPost}
                      title="New Post"
                      subtitle="Create a New Job Post"
                      width="90%"
                      height="10%"
                      from={'right'}
                      onRequestClose={() => {
                        // triggered on "<" on left top click or on outside click
                        setNewPost(false)
                        // setOpen(false);
                      }}
                    >
                
                      <JobDetails
                        setSelectedSkills={setSelectedSkills}
                        handleChange={handleChange}
                        updateSelectedCountryID={updateSelectedCountryID}
                        industryOptions={industryOptions}
                        workTypeOptions={workTypeOptions}
                        levelOptions={levelOptions}
                        skillOptions={skillOptions}
                        setNewJob ={setNewJob}
                        newJob={newJob}
                        duplicateData={isOnDuplicatePost ? duplicatedDataValue : []}
                        isOnDuplicatePost={isOnDuplicatePost}
                      />

                      <AddDocuments
                        job={newJob}
                        addDocLinkObject={(link) => addDocLinkObject(link)}
                        addDocLink={(link) => addDocLink(link)}
                        linksObject={linksObject}
                      />

                      <div className="row" style={{marginTop:'10%'}}>  
                        <div className="col-12 col-md-6">
                          {/*      
            <CustomButton
              type="button"
              backgroundColor="#3A6ED4"

              color="#fff"
              text={"Send Job to Recruiter"}
              handleClick={sendToRecruiter}
              additionalClass="mx-3 mt-2"
           
            /> */}
                        </div>
             
                        <div className="col-12 col-md-12">

                          <CustomButton
                            type="submit"
            
                            backgroundColor="#41B883"
                            handleClick={(e) => {
                              e.preventDefault();
                              const state = {
                                ["Post a Job"]: false,
                                ["Click on Post a Job"]: false,
                                ["Don't forget to add your files and links"]: false,
                                ["Post to referral timeline"]: true,
                                ["Fill In Details"]: false,
                              };
                              demoAction(dispatch, state);
                              handleSubmit(e);
                            }}
                            color="#fff"
                            text={"Publish Job to Referral"}
                            additionalClass="mx-3 mt-2"
                          />
                        </div>
                      </div>  
       
                    </SlidingPane>
                     )}

                  {/* <SlidingPane
                    className=""
                    overlayClassName="some-custom-overlay-class"
                    isOpen={newPost}
                    title="New Post"
                    subtitle="Create a New Job Post"
                    width="90%"
                    height="10%"
                    from={"right"}
                    onRequestClose={() => {
                      // triggered on "<" on left top click or on outside click
                      setNewPost(false);
                      // setOpen(false);
                    }}
                  >
                    <JobDetails
                      setSelectedSkills={setSelectedSkills}
                      handleChange={handleChange}
                      updateSelectedCountryID={updateSelectedCountryID}
                      industryOptions={industryOptions}
                      workTypeOptions={workTypeOptions}
                      levelOptions={levelOptions}
                      skillOptions={skillOptions}
                      setNewJob={setNewJob}
                      newJob={newJob}
                      duplicateData={
                        isOnDuplicatePost ? duplicatedDataValue : []
                      }
                      isOnDuplicatePost={isOnDuplicatePost}
                    />

                    <AddDocuments
                      job={newJob}
                      addDocLinkObject={(link) => addDocLinkObject(link)}
                      addDocLink={(link) => addDocLink(link)}
                      linksObject={linksObject}
                    />

                    <div className="row" style={{ marginTop: "10%" }}>
                    
                      <div className="col-12 col-md-12">
                         <CustomButton
                          type="submit"
                          backgroundColor="#41B883"
                          handleClick={(e) => {
                            e.preventDefault();
                            const state = {
                              ["Post a Job"]: false,
                              ["Click on Post a Job"]: false,
                              ["Don't forget to add your files and links"]: false,
                              ["Post to referral timeline"]: true,
                              ["Fill In Details"]: false,
                            };
                            demoAction(dispatch, state);
                            handleSubmit(e);
                          }}
                          color="#fff"
                          text={"Publish Job to Referral"}
                          additionalClass="mx-3 mt-2"
                        />
                      </div>
                    </div>
                  </SlidingPane> */}
                </div>
                <SlidingPane
                  className=""
                  overlayClassName="some-custom-overlay-class"
                  isOpen={showConfirmation}
                  title="Job Poster Approval Questionnaire"
                  subtitle="To become an approved job poster, please fill out the job poster questionnaire. Your request will be duly reviewed."
                  width="90%"
                    height="10%"
                  
                  from={"right"}
                  onRequestClose={() => {
                    // triggered on "<" on left top click or on outside click
                    setShowConfirmation(false);
                    // setOpen(false);
                  }}
                >
                  <div className="col-12 col-md-12 ">
                    <form className="mt-4" onSubmit={handleSubmiting}>
                      <div className="form-group">
                        <label className="text-size" htmlFor="companyName">
                          Hiring company name
                        </label>
                        <CustomInput
                          type="text"
                          className="form-control"
                          id="companyName"
                          value={review.name}
                          onChange={handleForm}
                          name="name"
                          placeholder="Enter Name"
                        />
                      </div>
                      <br />
                      <div className="form-group">
                        <label className="text-size" htmlFor="companyTax">
                          Hiring company Tax ID
                        </label>
                        <CustomInput
                          type="text"
                          className="form-control"
                          value={review.tax_id}
                          onChange={handleForm}
                          id="companyTax"
                          name="tax_id"
                          placeholder="Enter Tax ID"
                        />
                      </div>
                      <br />

                      <div className="form-group">
                        <label className="text-size" htmlFor="companyWebsite">
                          Hiring company website
                        </label>
                        <CustomInput
                          type="text"
                          value={review.website}
                          onChange={handleForm}
                          className="form-control"
                          id="companyWebsite"
                          name="website"
                          placeholder="Enter Website"
                        />
                      </div>
                      <br />

                      <div>
                        <label className="text-size">
                          What is your role in the hiring company?
                        </label>
                        <div className="mt-3 d-flex gap-5">
                          <input
                            type="checkbox"
                            name="role"
                            checked={review.role.includes("Hiring Manager")}
                            onChange={handleForm}
                            value="Hiring Manager"
                          />
                          <br />

                          <label className="mt-2 mx-1 ">Hiring Manager</label>
                        </div>
                        <div className="mt-3 d-flex gap-5">
                          <input
                            type="checkbox"
                            name="role"
                            checked={review.role.includes("Business Owner")}
                            onChange={handleForm}
                            value="Business Owner"
                          />
                          <label className="mt-2 mx-1">Business Owner</label>
                        </div>
                        <div className="mt-3 d-flex gap-5">
                          <input
                            type="checkbox"
                            name="role"
                            checked={review.role.includes("Recruiter")}
                            onChange={handleForm}
                            value="Recruiter"
                          />
                          <label className="mt-2 mx-1">
                            Recruiter/HR Manager
                          </label>
                        </div>
                        <div className="mt-3 d-flex gap-5">
                          <input
                            type="checkbox"
                            name="role"
                            checked={review.role.includes(
                              "Not affiliated with hiring company"
                            )}
                            onChange={handleForm}
                            value="Not affiliated with hiring company"
                          />
                          <label className="mt-2 mx-1">
                            Not affiliated with hiring company
                          </label>
                        </div>
                      </div>
                      <br />
                      <div className="mt-3 d-flex gap-5">
                        <input
                          type="checkbox"
                          name="role"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        <label className="mt-2 mx-1">
                         I agree to pay the placement fees in full, from all successful hires through the workbrook platform.
                        </label>
                      </div>

                      <br />

                      <div className="mt-5 col-12 col-md-12">
                        <CustomButton
                          type="submit"
                          backgroundColor="#3A6ED4"
                          color="#fff"
                          text={"Submit"}
                          disabled={
                            !isChecked ||
                            !review.name ||
                            !review.tax_id ||
                            !review.website ||
                            !review.role
                          }
                          additionalClass="mx-3 mt-2"
                        />
                      </div>
                      <div className="mt-3 col-12 col-md-12">
                        <CustomButton
                          backgroundColor="#41B883"
                          color="#fff"
                          onClick={() => setShowConfirmation(false)}
                          text={"Exit"}
                          additionalClass="mx-3 mt-2"
                        />
                      </div>
                    </form>
                  </div>
                </SlidingPane>
                ;
                {/* :
      <></>
     */}
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

Layout.propTypes = {
  children: node.isRequired,
  recruiter: bool,
};

Layout.defaultProps = {
  recruiter: false,
};

export default Layout;
