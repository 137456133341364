import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import CustomButton from "../../../../components/CustomButton";
import CustomInput from "../../../../components/CustomInput";
import { createBankAccount } from "../../../../redux/actions/walletActions";

const StripeAddAccount = ({ handleClose }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [state, setState] = useState({
    accountName: "",
    accountNumber: "",
    bankName: "",
    routingNumber: "",
  });

  const handleChange = ({ target: { id, value } }) => {
    const newValue = {};
    newValue[id] = value;
    setState({ ...state, ...newValue });
  };

  const addAccount = async (evt) => {
    evt.preventDefault();
    setLoading(true);

    const bankData = {
      account_name: state.accountName,
      account_number: parseInt(state.accountNumber),
      bank: state.bankName,
      routing_number: state.routingNumber,
    };

    await createBankAccount(dispatch, bankData);
    // console.log("DONE");
    setState({
      accountName: "",
      accountNumber: "",
      bankName: "",
      routingNumber: "",
    });
    handleClose();
    setLoading(false);
  };

  return (
    <div className="mt-4">
      <h5>Add New Account</h5>
      <form className="pb-5">
        <CustomInput
          type="number"
          label="Account Number"
          id="accountNumber"
          isRequired={true}
          value={state.accountNumber}
          onChange={handleChange}
        />
        <CustomInput
          type="text"
          label="Account Name"
          id="accountName"
          isRequired={true}
          value={state.accountName}
          onChange={handleChange}
        />
        <CustomInput
          type="text"
          label="Name of Bank"
          id="bankName"
          isRequired={true}
          value={state.bankName}
          onChange={handleChange}
        />
        <CustomInput
          type="text"
          label="Routing Number"
          id="routingNumber"
          isRequired={true}
          value={state.routingNumber}
          onChange={handleChange}
        />
        <CustomButton
          backgroundColor="#3A6ED4"
          color="#fff"
          text="ADD ACCOUNT"
          loading={loading}
          additionalClass="mt-5 mb-2"
          handleClick={addAccount}
          disabled={[
            !state.accountNumber,
            !state.accountName,
            !state.bankName,
          ].includes(true)}
        />
      </form>
    </div>
  );
};

export default StripeAddAccount;
