import React from "react";
import clsx from "clsx";
import { string } from "prop-types";
import "./index.scss";

const Counter = ({ count }) => {
  return (
    <span
      className={clsx(
        "counter small-text d-flex align-items-center justify-content-center",
        {
          warning: count <= 50 && count > 10,
          danger: count <= 10,
        }
      )}
    >
      {isNaN(count) ? 199 : count}
    </span>
  );
};
Counter.propTypes = {
  count: string.isRequired,
};

export default Counter;
