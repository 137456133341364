import React, { useEffect, useState } from "react";
import { Divider, IconButton } from "@material-ui/core";
import Card from "../../../components/Card";
import CustomButton from "../../../components/CustomButton";
import icons from "../../../assets/icons/icon-collection.svg";
import BackButton from "../../../components/BackButton";
import Layout from "../../../layout";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSavedJob,
  getSavedJobs,
} from "../../../redux/actions/jobActions";
import ViewJob from "../Home/Modal";
import EmptyState from "../../../components/EmptyState";
import { SectionLoader } from "../../../components/PageLoader";
import { ButtonLoader } from "../../../components/ButtonLoader";
import { useHistory, useParams } from "react-router-dom";
  
const Saved = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [section, setSection] = useState("details");
  const [currentJob, setCurrentJob] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
 const { savedData = { saved: [], count: 0 } } = useSelector(({ job }) => job);
  const { saved, count } = savedData;
  const history = useHistory();
  const { jobId } = useParams();
  useEffect(() => {
    page === 1 ? setLoading(true) : setLoadingMore(true);
    Promise.resolve(getSavedJobs(dispatch, page)).finally(() =>
      page === 1 ? setLoading(false) : setLoadingMore(false)
    );
  }, [dispatch, page]);

  useEffect(() => {
    if (jobId) {
      openDetails();
    }
  }, [jobId]);

  const deleteJob = async (id) => {
    await deleteSavedJob(dispatch, id);
    await getSavedJobs(dispatch, page);
  };

  // console.log("SAVED", saved);

  // const openApplication = () => {
  //   setSection("apply");
  //   setOpen(true);
  // };

  const openDetails = (job) => {
    // console.log("opening...");
    setCurrentJob(job);
    setSection("details");
    setOpen(true);
  };

  return (
    <Layout>
      <BackButton />
      <div className="row position-relative mt-3 d-flex">
        {loading && !saved[0] && <SectionLoader />}
        {saved && saved[0] && (
          <>
            {saved.map((job, index) => (
              <div key={index.toString()} className="col-12 col-md-6">
                <Card additionalClass="mx-2 px-4 mb-4">
                  <div className="d-flex mb-2 align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <img
                        width="30"
                        className="rounded-circle"
                        src={job.created_by_profile.image_url}
                        alt="avatar"
                      />
                      <span className="d-flex flex-column ml-3">
                        <h6 className="mb-0">
                          {job.created_by_profile.first_name}{" "}
                          {job.created_by_profile.last_name}
                        </h6>
                        <span className="co-gray">
                          @{job.created_by_profile.username}
                        </span>
                      </span>
                    </div>
                    <IconButton onClick={() => deleteJob(job.id)}>
                      <svg width="32" height="32">
                        <use href={`${icons}#bin`}></use>
                      </svg>
                    </IconButton>
                  </div>

                  <Divider />

                  <div className="pb-3">
                    <div className="my-3">
                      <b className="d-block co-primary">{job.role_title}</b>
                      <span className="co-gray medium-text">
                        {job.campany_name}
                      </span>
                    </div>
                    <span>
                     
                       {
                     job?.about_company
                      ? <span dangerouslySetInnerHTML={{ __html: job.about_company.substring(0, 45) }}></span>
                      : <span>No company information available</span>
                      }


                      <button
                        type="button"
                        onClick={() => {
                          openDetails();
                          history.push(`/saved/${job.id}`);
                        }}
                        className="co-primary bg-white borderless"
                      >
                        <b>See More</b>
                      </button>
                    </span>
                  </div>

                  {/* {localStorage.getItem("accountType") !== "business" && (
                    <div className="mt-3">
                      <CustomButton
                        handleClick={openApplication}
                        backgroundColor="#3A6ED4"
                        color="#fff"
                        text="Apply"
                        additionalClass="my-3"
                      />
                    </div>
                  )} */}
                </Card>
              </div>
            ))}
            {count > 10 * page && (
              <div className="mx-auto mt-3 w-75">
                <CustomButton
                  handleClick={() => setPage(page + 1)}
                  text={loadingMore ? <ButtonLoader /> : "Load More"}
                />
              </div>
            )}
          </>
        )}
        {open && (
          <ViewJob
            job={currentJob}
            setSection={setSection}
            section={section}
            open={open}
            isBusiness={localStorage.getItem("accountType") === "business"}
            handleClose={() => setOpen(false)}
          />
        )}
        {!loading && !saved[0] && <EmptyState text="You have no saved job" />}
      </div>
    </Layout>
  );
};

export default Saved;
