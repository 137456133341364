import React from 'react'
import Organogram from '../../../components/Organogram'
function Organization() {
  return (
    <div>
      <Organogram/>
    </div>
  )
}

export default Organization;
