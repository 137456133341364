import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import EmptyState from "../../../components/EmptyState";
import Card from "../../../components/Card";
import SubMenu from "../../../components/SubMenu";
import Layout from "../../../layout";
import AddFunds from "./Add";
import TransactionHistory from "./History";
import Withdraw from "./Withdraw";
import "./index.scss";
import { getAccountBalance } from "../../../redux/actions/walletActions";
import { getCurrencyRates } from "../../../redux/actions/currencyRatesActions";
import { numberWithCommas } from "../../../utils/helpers";
import Carousel from "react-bootstrap/Carousel";
import wbbg from "../../../assets/images/wb-bg.png";

const subMenuOptions = [
  ["add funds", "/wallet/add"],
  ["withdrawals", "/wallet/withdraw"],
  ["transaction history", "/wallet/history"],
];

const Wallet = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { userData } = useSelector((state) => state.auth);
  const [type, setType] = useState("stripe");

  const { balance } = useSelector((state) => state.wallet);
  const { rates, currency } = useSelector((state) => state.currencyRate);
  const USD = rates.USD;
  const currencyRate = rates[currency];
  const [balanceInLocal, setBalanceInLocal] = useState({});
  useEffect(() => {
    if (balance) {
      const availableBalanceInBase = balance[0].available / USD;
      const balanceInBase = balance[0].balance / USD;
      setBalanceInLocal({
        available: availableBalanceInBase * currencyRate,
        balance: balanceInBase * currencyRate,
      });
    }
  }, [balance, currency]);
  // console.log("Userdata", userData);
  // console.log("Balance", balance);
  useEffect(() => {
    if (userData.country === "160") {
      // setType("paystack");
    } else {
      setType("stripe");
    }
  }, [userData]);

  useEffect(() => {
    Promise.resolve(getAccountBalance(dispatch));
    Promise.resolve(getCurrencyRates(dispatch));
  }, [dispatch]);
  const updateBalance = () => {
    getAccountBalance(dispatch);
    // console.log("Balance Updated");
  };

  return (
    <Layout>
      <div className="wallet d-flex flex-column flex-md-row mt-3">
        <div className="sub-menu-container">
          <BackButton />
          <SubMenu menuOptions={subMenuOptions} />
        </div>
        <div className="main-content ml-md-4">
          {userData && !userData.country && (
            <div className="d-flex flex-column justify-content-center">
              <EmptyState text="You need to complete your profile first." />
              <Link to="/profile/edit/basic" className="btn">
                Update Profile
              </Link>
            </div>
          )}
          {userData && userData.country && (
            <Card noPadding>
              <Carousel>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={wbbg}
                    alt="First slide"
                    style={{ height: "150px" }}
                  />
                  <Carousel.Caption>
                    <div className="d-flex justify-content-center align-items-center">
                      <span style={{ fontSize: "20px" }}>$</span>
                      <h1 className="mb-0 ml-2">
                        {numberWithCommas(
                          balance
                            ? balance &&
                                parseFloat(balance[0].available).toFixed(2)
                            : 0.0
                        )}
                      </h1>
                      {balanceInLocal.available
                        ? balanceInLocal.available && (
                            <span
                              style={{
                                marginLeft: "20px",
                              }}
                            >
                              {numberWithCommas(
                                currency +
                                  " " +
                                  parseFloat(balanceInLocal.available).toFixed(
                                    2
                                  )
                              )}
                            </span>
                          )
                        : null}
                    </div>
                    <p className="mb-0">Available Balance</p>
                    <p className="text-center" style={{ fontSize: "8px" }}>
                      (Subject to local currency changes)
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={wbbg}
                    alt="Available Balance"
                    style={{ height: "150px" }}
                  />

                  <Carousel.Caption>
                    <div className="d-flex justify-content-center align-items-center">
                      <span style={{ fontSize: "20px" }}>$</span>
                      <h1 className="mb-0 ml-2">
                        {numberWithCommas(
                          balance
                            ? parseFloat(balance && balance[0].balance).toFixed(
                                2
                              )
                            : 0.0
                        )}
                      </h1>
                      {balanceInLocal.balance
                        ? balanceInLocal.balance && (
                            <span
                              style={{
                                marginLeft: "20px",
                              }}
                            >
                              {numberWithCommas(
                                currency +
                                  " " +
                                  parseFloat(balanceInLocal.balance).toFixed(2)
                              )}
                            </span>
                          )
                        : null}
                    </div>
                    <p className="mb-0">Book Balance</p>
                    <p className="text-center" style={{ fontSize: "8px" }}>
                      (Subject to local currency changes)
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
              {!pathname.includes("withdraw") &&
                !pathname.includes("history") &&
                !pathname.includes("add") && (
                  <AddFunds type={type} updateBalance={updateBalance} />
                )}
              {pathname.includes("add") && (
                <AddFunds type={type} updateBalance={updateBalance} />
              )}
              {pathname.includes("withdraw") && <Withdraw type={type} />}
              {pathname.includes("history") && <TransactionHistory />}
            </Card>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Wallet;
