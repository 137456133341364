import React, { useEffect } from "react";
import { bool, object as objectPropType, string } from "prop-types";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import EditCard from "../EditCard";
import { Checkbox, TextField } from "@material-ui/core";
import {
  EDIT_EDUCATION,
  EDUCATION_LOADING,
  ERROR,
  RESET_EDUCATION_FORM,
  STOP_EDUCATION_LOADING,
} from "../../../../../redux/actionTypes";
import {
  createEducationAction,
  updateEducationAction,
} from "../../../../../redux/actions/educationActions";
import { ternaryResolver } from "../../../../../utils/helpers";

const Education = ({
  educationData,
  educationLoading,
  isEdit,
  educationId,
}) => {
  const { push } = useHistory();
  const dispatch = useDispatch();

  const handleChange = ({ target: { name, value, checked } }) => {
    // if (name === "description" && value?.split?.(" ")?.length <= MAX_WORDS) {
    //   dispatch({ type: EDIT_EDUCATION, payload: { name, value } });
    // } else
    if (name === "stillInSchool") {
      dispatch({ type: EDIT_EDUCATION, payload: { name, value: checked } });
      dispatch({
        type: EDIT_EDUCATION,
        payload: {
          name: "end_date",
          value: ternaryResolver(checked, "Present", ""),
        },
      });
    } else {
      dispatch({ type: EDIT_EDUCATION, payload: { name, value } });
    }
  };

  const handleSubmit = () => {
    const payload = {
      school: educationData.school,
      degree: educationData.degree,
      field_of_study: educationData.field_of_study,
      start_date: educationData.start_date,
      end_date: educationData.end_date,
      // description: educationData.description,
    };

    dispatch({ type: EDUCATION_LOADING });
    if (isEdit) {
      Promise.resolve(updateEducationAction(educationId, payload, dispatch))
        .then(({ type }) => {
          if (type !== ERROR) {
            dispatch({ type: RESET_EDUCATION_FORM });
            push("/profile");
          }
        })
        .finally(() => {
          dispatch({ type: STOP_EDUCATION_LOADING });
        });
    } else {
      Promise.resolve(createEducationAction(payload, dispatch))
        .then(({ type }) => {
          if (type !== ERROR) {
            dispatch({ type: RESET_EDUCATION_FORM });
          }
        })
        .finally(() => {
          dispatch({ type: STOP_EDUCATION_LOADING });
        });
    }
  };

  // useEffect(() => {
  //   setCount(MAX_WORDS - educationData?.description?.split?.(" ")?.length);
  // }, [educationData.description]);

  useEffect(() => {
    return () => dispatch({ type: RESET_EDUCATION_FORM });
  }, [dispatch]);

  return (
    <EditCard
      btnText={ternaryResolver(isEdit, "Update", "Add")}
      loading={educationLoading}
      handleSubmit={handleSubmit}
      disable={[
        !educationData.school,
        !educationData.degree,
        !educationData.field_of_study,
        !educationData.start_date,
        !educationData.end_date,
        // !educationData.description,
      ].includes(true)}
    >
      <TextField
        onChange={handleChange}
        name="school"
        value={educationData.school}
        className="w-100 mb-4"
        variant="outlined"
        label="School/Institution/University"
      />

      <TextField
        onChange={handleChange}
        name="degree"
        value={educationData.degree}
        className="w-100 mb-4"
        variant="outlined"
        label="Degree"
      />

      <TextField
        onChange={handleChange}
        name="field_of_study"
        value={educationData.field_of_study}
        className="w-100 mb-4"
        variant="outlined"
        label="Field Of Study"
      />

      <TextField
        onChange={handleChange}
        name="start_date"
        value={educationData.start_date}
        InputLabelProps={{
          shrink: true,
        }}
        type="date"
        className="w-100 mb-4"
        variant="outlined"
        label="Start date"
      />

      {!educationData.stillInSchool && (
        <TextField
          onChange={handleChange}
          name="end_date"
          value={educationData.end_date}
          InputLabelProps={{
            shrink: true,
          }}
          type="date"
          className="w-100 mb-4"
          variant="outlined"
          label="End date"
        />
      )}

      <div className="w-100 position-relative">
        <Checkbox
          name="stillInSchool"
          onChange={handleChange}
          color="primary"
          checked={educationData.stillInSchool}
          id="stillInSchool"
        />
        <label htmlFor="stillInSchool">Present</label>
      </div>
      {/* <div className="w-100 position-relative">
        <Counter count={count} />
        <CustomTextArea
          classes="pt-4 pr-5 mb-5"
          value={educationData.description}
          onChange={handleChange}
          name="description"
          id="description"
          rows="12"
          placeholder="About you (200 words)" />
      </div> */}
    </EditCard>
  );
};

Education.propTypes = {
  educationData: objectPropType.isRequired,
  educationLoading: bool.isRequired,
  isEdit: bool.isRequired,
  educationId: string.isRequired,
};

export default Education;
