import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Visa from "../../../../assets/icons/visa.svg";
import Mastercard from "../../../../assets/icons/mastercard.svg";
import Check from "../../../../assets/icons/check1.svg";
import Uncheck from "../../../../assets/icons/uncheck.svg";
import {
  Card,
  CardContent,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CustomSelect from "../../../../components/CustomSelect";
import { setDefaultCard } from "../../../../redux/actions/walletActions";

const AddedCard = ({ card, img, refreshList }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  const fullcard = JSON.parse(card.card);

  const setCardAsDefault = async (cardId) => {
    Promise.resolve(setDefaultCard(dispatch, cardId)).finally(() => {
      refreshList();
    });
    // setChecked(true);
  };

  // useEffect(() => {
  //   setChecked(card.isDefault);
  // }, [card])

  return (
    <div className="mb-4">
      <Card className={classes.card} style={{ backgroundColor: "#F7F7F7" }}>
        <CardContent>
          <div className="d-flex flex-row justify-content-between">
            <div className={classes.intext}>
              {card.brand.toLowerCase() == "visa" && (
                <img src={Visa} alt={card.brand} className={classes.cardlogo} />
              )}
              {card.brand.toLowerCase() == "mastercard" && (
                <img
                  src={Mastercard}
                  alt={card.brand}
                  className={classes.cardlogo}
                />
              )}
              **** **** {card.last4}
            </div>
            {card.isDefault === "1" ? (
              <img
                src={Check}
                alt="check"
                style={{ width: "20px" }}
                className={classes.cardlogo}
              />
            ) : (
              <img
                src={Uncheck}
                alt="check"
                style={{ width: "20px" }}
                className={classes.cardlogo}
              />
            )}
          </div>
          <Typography variant="body2" component="p" className={classes.intext}>
            {fullcard.name}
          </Typography>
        </CardContent>
      </Card>
      <div className="d-flex justify-content-end mt-2 small-text pull-right">
        <Link onClick={() => setCardAsDefault(card.id)}>Set Default</Link>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  card: {
    background: "#F7F7F7",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    padding: "0 20px 0 20px",
    fontSize: "14px",
    marginBottom: "0px",
  },
  intext: {
    fontSize: "14px",
  },
  cardlogo: {
    width: "45px",
    marginRight: "20px",
  },
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default AddedCard;
