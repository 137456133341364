import React from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import "./organogram.css";

const Organogram = () => {
  return (
    <div>
      <h1>Organizational Chart</h1>
      <Tree
        lineWidth={"2px"}
        lineColor={"black"}
        lineBorderRadius={"10px"}
        label={<div className="my-label">CEO</div>}
      >
        <TreeNode label={<div className="my-label">CTO</div>}>
          <TreeNode label={<div className="my-label">Dev Manager</div>}>
            <TreeNode label={<div className="my-label">Dev 1</div>} />
            <TreeNode label={<div className="my-label">Dev 2</div>} />
          </TreeNode>
          <TreeNode label={<div className="my-label">QA Manager</div>}>
            <TreeNode label={<div className="my-label">QA 1</div>} />
            <TreeNode label={<div className="my-label">QA 2</div>} />
          </TreeNode>
        </TreeNode>
        <TreeNode label={<div className="my-label">CFO</div>}>
          <TreeNode label={<div className="my-label">Finance Manager</div>}>
            <TreeNode label={<div className="my-label">Accountant 1</div>} />
            <TreeNode label={<div className="my-label">Accountant 2</div>} />
          </TreeNode>
        </TreeNode>
      </Tree>
    </div>
  );
};

export default Organogram;
