import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { bool, object as objectPropType } from "prop-types";
import EditCard from "../EditCard";
import CustomTextArea from "../../../../../components/CustomTextArea";
import Counter from "../../../../../components/Counter";
import {
  EDIT_PROFILE,
  PROFILE_LOADING,
  STOP_PROFILE_LOADING,
} from "../../../../../redux/actionTypes";
import { updateProfileAction } from "../../../../../redux/actions/profileActions";

const About = ({ profileData, profileLoading }) => {
  const MAX_WORDS = 200;
  const [count, setCount] = useState(MAX_WORDS);

  const dispatch = useDispatch();

  const handleChange = ({ target: { id, value } }) => {
    if (value?.split?.(" ")?.length <= MAX_WORDS) {
      dispatch({ type: EDIT_PROFILE, payload: { name: id, value } });
    }
  };

  useEffect(() => {
    setCount(MAX_WORDS - profileData?.about?.split?.(" ")?.length);
  }, [profileData.about]);

  const handleSubmit = () => {
    const payload = {
      first_name: profileData.first_name,
      last_name: profileData.last_name,
      gender: profileData.gender,
      dob: profileData.dob,
      state: profileData.state,
      country: profileData.country,
      industry: profileData.industry,
      image_url: profileData.image_url,
      resume: profileData.resume,
      phone: profileData.phone,
      links: profileData.links,
      isShowOnProfile: 1,
      about: profileData.about,
    };

    dispatch({ type: PROFILE_LOADING });
    const promise = updateProfileAction(payload, dispatch);
    Promise.resolve(promise).finally(() => {
      dispatch({ type: STOP_PROFILE_LOADING });
    });
  };

  return (
    <EditCard
      handleSubmit={handleSubmit}
      allRequired
      loading={profileLoading}
      disable={!profileData.about}
    >
      <div className="w-100 position-relative">
        <Counter count={count} />
        <CustomTextArea
          classes="pt-4 pr-5"
          value={profileData.about}
          onChange={handleChange}
          rows="15"
          id="about"
          placeholder="About you (200 words)"
        />
      </div>
    </EditCard>
  );
};

About.propTypes = {
  profileData: objectPropType.isRequired,
  profileLoading: bool.isRequired,
};

export default About;
