import {
  FormControl,
  MenuItem,
  Select,
  Modal,
  DialogContent,
  Dialog,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CustomButton from "../../../../components/CustomButton";
import { SectionLoader } from "../../../../components/PageLoader";
import EmptyState from "../../../../components/EmptyState";
import CustomInput from "../../../../components/CustomInput";
import CustomSelect from "../../../../components/CustomSelect";
import AddedCard from "./AddedCard";
import './index.css'
import StripeLogo from "../../../../assets/icons/stripelogo.png";
import PaystackLogo from "../../../../assets/icons/paystackicon.png";
import CloseIcon from "../../../../assets/icons/close-icon-png.jpeg"
 
import {
  createCardToken,
  getCards,
  resetCards,
  fundWallet,
  addCardPaystack,
  fundWalletWithPaystack,
  fundWalletWithGPAY
} from "../../../../redux/actions/walletActions";
import { usePaystackPayment } from "react-paystack";
import Account from "../Add/Account";
import GooglePayButton from '@google-pay/button-react';

const AddFunds = ({ type, updateBalance }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paying, setPaying] = useState(false);
  const [stripeOrPaystackOption, setStripeOrPaystackOption] = useState(false);
  const [page, setPage] = useState(1);
  const [reloadCards, setReloadCards] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [cardDetails, setCardDetails] = useState({
    card_name: "",
    card_number: "",
    exp_month: "1",
    exp_year: "2020",
    cvc: "",
  });
  const [amount, setAmount] = useState(0.0);

  const { cards } = useSelector((state) => state.wallet);
  const { userData } = useSelector((state) => state.auth);
  const [openDialog, setOpenDialog] = useState(false);

  // console.log("userData>>>", userData);

  const config = {
    reference: new Date().getTime(),
    amount: 5000,
    email: userData && userData["email"],
    publicKey: "pk_test_124a62105a9c93d1f5398e204b82ee80a41a9616",//process.env.PAYSTACK_PK,
    channels: ["card"],
  };

  const initializePayment = usePaystackPayment(config);

  const addPaystackCard = () => {
    // console.log("CONFIG", config);
    // console.log("Email>>>", userData.email);

    initializePayment(onSuccess, onClose);
  };

  // console.log("cards", cards);

  useEffect(() => {
    setReloadCards(true);
    // console.log("Reloading Cards..........");
    Promise.resolve(getCards(dispatch, page)).finally(() =>
      setReloadCards(false)
    );
  }, [dispatch, page]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAmount = ({ target: { id, value } }) => {
    // console.log(value);
    setAmount(value);
  };

  const handleChange = ({ target: { id, value } }) => {
    const newValue = {};
    newValue[id] = value;
    // console.log(newValue);
    setCardDetails({ ...cardDetails, ...newValue });
  };

  const handleSelect = ({ target: { name, value } }) => {
    const changed = {};
    changed[name] = value;
    // console.log(changed);
    setCardDetails({ ...cardDetails, ...changed });
  };

  const addCardToStripe = async () => {
    setLoading(true);
    resetCards(dispatch, page);
    const cardData = {
      "card[name]": cardDetails.card_name,
      "card[number]": cardDetails.card_number,
      "card[exp_month]": cardDetails.exp_month,
      "card[exp_year]": cardDetails.exp_year,
      "card[cvc]": cardDetails.cvc,
    };

    setReloadCards(true);
    Promise.resolve(createCardToken(dispatch, cardData)).finally(() => {
      getCards(dispatch, page);
      setCardDetails({
        card_number: "",
        exp_month: "1",
        exp_year: "2020",
        cvc: "",
      });
      setLoading(false);
      setOpen(false);
      setReloadCards(false);
    });

    setLoading(false);
  };

  const refreshList = async () => {
    resetCards(dispatch, page);
    getCards(dispatch, page);
  };

  const handleFundWallet = async () => {
    // Get default card
    const card = cards && cards[0].filter((card) => card.isDefault === "1");
    // console.log("Default Card", card);

    const cardDetails = card[0].card;
    const auth_code = JSON.parse(cardDetails).authorization_code;
   
    setPaying(true);
    // If no default card exist
    if (!card[0]) {
      alert("Select a default card first.");
    } else {
      // console.log("fundData", fundData);
      if (type === "stripe") {
        const fundDataStripe = {
          amount: parseFloat(amount),
          card_id: card[0].id,
        };
        await fundWallet(dispatch, fundDataStripe);
      } else if (type === "paystack") {
        const fundDataPaystack = {
          amount: parseFloat(amount),
          authorization_code: auth_code,
          email: userData && userData["email"],
        };
        
        await fundWalletWithPaystack(dispatch, fundDataPaystack);
        updateBalance();
      }
      // console.log("funded");
    }
    setPaying(false);
    setAmount(0.0);
  };

  // Paystack Implementation....
  const onSuccess = (reference) => {
    // console.log(reference);
    const ref = {
      reference: reference.reference,
      type:"paystack"
    };

    const addCard = async () => {
      addCardPaystack(dispatch, ref);
      refreshList();
    };
    addCard();
  };

  const onClose = () => {
    // console.log("closed");
  };

    const processPayment = async (paymentData)=> {
    const paymentToken = paymentData.paymentMethodData.tokenizationData.token;
    const fundDataGpay = {
      amount: parseFloat(amount),
      authorization_code: paymentToken,
      email: userData && userData["email"],
    };

    await fundWalletWithGPAY(dispatch, fundDataGpay);
  }


  const transfer = () => {};
  return (
    <div className="m-5">
      <div className="pb-2">
        <Account transfer={transfer} refreshList={refreshList} />
      </div>

      <h3
        className="p-2"
        style={{
          textAlign: "center",
        }}
      >
        Payment Options
      </h3>
        { userData['country'] != 160  ?
      <div>
        { !stripeOrPaystackOption ? 
        <button className="stripeBtn"
         onClick={()=>{
          setStripeOrPaystackOption(true)
        }}
        >
        <img src={StripeLogo} width='60' 
        
        />
        </button> 
        :<p></p>
        }
      </div> 
        :
      <div>
          { 
        !stripeOrPaystackOption ? 
        <button className="paystackBtn"
         onClick={()=>{
          setStripeOrPaystackOption(true)
        }}
        >
        <img src={PaystackLogo} width='25' /> <b>Paystack </b>
        </button> 
        :<p></p>
          }
      </div> 
        }
                  { 
        !stripeOrPaystackOption ? 
      <div className="paymentmethodContainer">
      <GooglePayButton
          environment="TEST"
      
          paymentRequest={{
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: [
              {
                type: 'CARD',
                parameters: {
                  allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                  allowedCardNetworks: ['AMEX', 'DISCOVER','MASTERCARD', 'VISA']
                },
                tokenizationSpecification: {
                  type: 'PAYMENT_GATEWAY',
                  parameters: {
                    'gateway': 'stripe',
                    'stripe:version': '2016-07-06',
                    'stripe:publishableKey': 'pk_live_g62PWzL7fEzgY6Q5KxCAfqLD00bNgeHyXk'
                  }
                }
              },
            ],
            merchantInfo: {
              merchantId: 'BCR2DN6TZ7R4JRIJ',
              merchantName: 'workbrook',
            },
            transactionInfo: {
              totalPriceStatus: 'FINAL',
              totalPriceLabel: 'Total',
              totalPrice: '1.00',
              currencyCode: 'USD',
              countryCode: 'US',
            },
          }}
          onLoadPaymentData={paymentRequest => {
            console.log('load payment data', paymentRequest);
            processPayment(paymentRequest)
          }}
          style={{
            width:'100%',
            textAlign:'center'
          }}
          buttonColor={'blue'}
          buttonType={'plain'}
          buttonSizeMode={'fill'}
          buttonLocale={'en'}

        />
      </div>
        :<p></p>
      }

{
   stripeOrPaystackOption ?
      <div className='paystackstripe'>
   
      <div className="pull-rightx">

      <a onClick={ ()=>{
        setStripeOrPaystackOption(false)
      }} href="#">
      Switch Payment Option
      </a>
      </div>
     
      <h5 style={{ color: "#3a6ed4", marginBottom: "30px", marginTop:'5%' }}>
        Choose A Card To Pay With 
      </h5>
    

      
      {reloadCards && !cards && <SectionLoader />}
      {!reloadCards && !cards && (
        <EmptyState text="You have not added any card" />
      )}
      {cards &&
        cards[0].map((card) => (
          <AddedCard
            key={card.id}
            card={card}
            img={card.brand}
            refreshList={refreshList}
          />
        ))}
        
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: "35rem",
            borderRadius: "1rem",
            padding: "0.75rem",
          },
        }}
      >
        <DialogContent>
          <h5>Add your card</h5>
          <form className="pb-5">
            <CustomInput
              type="text"
              label="Name on Card"
              id="card_name"
              name="card_name"
              value={cardDetails.card_name}
              onChange={handleChange}
            />

            <CustomInput
              label="Card Number"
              id="card_number"
              name="card_number"
              type="number"
              value={cardDetails.card_number}
              onChange={handleChange}
            />
  
            <div className="d-flex justify-content-between align-items-end">
              <FormControl variant="standard" className="custom-input w-25">
                <label className="d-block mb-0">Expiry Date</label>
                <Select
                  id="exp_month"
                  name="exp_month"
                  label="Expiry Month"
                  onChange={handleSelect}
                  defaultValue="1"
                >
                  <MenuItem value="1">Jan</MenuItem>
                  <MenuItem value="2">Feb</MenuItem>
                  <MenuItem value="3">Mar</MenuItem>
                  <MenuItem value="4">Apr</MenuItem>
                  <MenuItem value="5">May</MenuItem>
                  <MenuItem value="6">Jun</MenuItem>
                  <MenuItem value="7">Jul</MenuItem>
                  <MenuItem value="8">Aug</MenuItem>
                  <MenuItem value="9">Sep</MenuItem>
                  <MenuItem value="10">Oct</MenuItem>
                  <MenuItem value="11">Nov</MenuItem>
                  <MenuItem value="12">Dec</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant="standard" className="w-25">
                <Select
                  id="exp_year"
                  label="Expiry Year"
                  name="exp_year"
                  onChange={handleSelect}
                  defaultValue="2020"
                >
                  <MenuItem value="2020">2020</MenuItem>
                  <MenuItem value="2021">2021</MenuItem>
                  <MenuItem value="2022">2022</MenuItem>
                  <MenuItem value="2023">2023</MenuItem>
                  <MenuItem value="2024">2024</MenuItem>
                  <MenuItem value="2025">2025</MenuItem>
                  <MenuItem value="2026">2026</MenuItem>
                  <MenuItem value="2027">2027</MenuItem>
                  <MenuItem value="2028">2028</MenuItem>
                  <MenuItem value="2029">2029</MenuItem>
                  <MenuItem value="2030">2030</MenuItem>
                  <MenuItem value="2031">2031</MenuItem>
                </Select>
              </FormControl>
              <div>
                <CustomInput
                  type="number"
                  label="CCV"
                  id="cvc"
                  name="cvc"
                  onChange={handleChange}
                />
              </div>
            </div>

            <CustomButton
              backgroundColor="#3A6ED4"
              color="#fff"
              text="ADD CARD"
              additionalClass="mt-5 mb-2"
              handleClick={addCardToStripe}
              loading={loading}
              disabled={[!cardDetails.card_number, !cardDetails.cvc].includes(
                true
              )}
            />
          </form>
        </DialogContent>
      </Dialog>

      {cards && cards[0] && (
        <form className="pb-5">
          <h5>Fund Wallet</h5>
          <CustomInput
            name="amount"
            id="amount"
            label={`Enter Amount in ${
              type == "stripe" ? "Dollars(USD)" : "Naira(NGN)"
            }`}
            isRequired="true"
            type="number"
            value={amount}
            onChange={handleAmount}
          />
          <CustomButton
            backgroundColor="#3A6ED4"
            color="#fff"
            text="PAY"
            additionalClass="mt-5 mb-2"
            loading={paying}
            handleClick={handleFundWallet}
            disabled={[!amount].includes(true)}
          />

         
 

          

        </form>
      )} 
      {/**   {type && type == "stripe" && ( 
       * 
       *         {type && type == "paystack" && (
       * 
      */}
      <div className="d-flex justify-content-end pb-4 pt-4">
        {userData && userData["country"] != 160 && (
          <Link onClick={() => setOpen(true)}>+ Add New Card (Stripe)</Link>
        )}
        {userData && userData["country"] == 160 && (
          <div className="d-flex flex-column justify-content-end text-right">
            <div>
              <Link onClick={addPaystackCard}>+ Add New Card (Paystack)</Link>
            </div>
            <div>
              (Based on local regulations, you will be debited &#8358;50($0.20)
              for adding your card which will be credited into your wallet)
            </div>
          </div>
        )}
      </div>


      </div>
      :
      <div></div>
}

        


    </div>
  );
};

export default AddFunds;
