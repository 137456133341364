/* eslint-disable no-empty */
import React, { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormControlLabel } from "@material-ui/core";
import WBlogo from "../../../../assets/icons/WBlogo.svg";
import WBtext from "../../../../assets/icons/WBtext.svg";
// import AuthWrapper from "../AuthWrapper";
import EyeIcon from "../../../../assets/icons/eye.svg";
import "./index.scss";
import CustomButton from "../../../../components/CustomButton";
import CustomInput from "../../../../components/CustomInput";
import { loginAction } from "../../../../redux/actions/authActions";
import { AUTH_LOADING, STOP_AUTH_LOADING } from "../../../../redux/actionTypes";
import { isValidEmail } from "../../../../utils/helpers";
import WorkImage from "../../../../assets/images/wbg.png";
import { useHistory } from "react-router-dom";

const Signin = () => {
  const [state, setState] = useState({
    email: "",
    password: "",
    account_type: "business",
    rememberMe: !!localStorage.rememberMe,
  });
  const history = useHistory();

  const { loading } = useSelector((store) => store.auth);
  const dispatch = useDispatch();

  const handleChange = ({ target: { id, value, checked } }) => {
    if (id === "rememberMe") {
      if (checked) {
        localStorage.setItem("rememberMe", "true");
      } else {
        localStorage.removeItem("rememberMe");
      }
      setState((prevState) => ({ ...prevState, [id]: checked }));
    } else {
      setState((prevState) => ({ ...prevState, [id]: value }));
    }
  };

  const handleSubmit = (event) => {
    dispatch({ type: AUTH_LOADING });
    event.preventDefault();
    Promise.resolve(loginAction(state, dispatch)).finally(() => {
      try {
        const pageToVisit = sessionStorage.getItem("pageToVisit");
        const accountType = localStorage.getItem("accountType");
        if (pageToVisit) {
          sessionStorage.removeItem("pageToVisit");
          const pageToVisitInfo = JSON.parse(pageToVisit);
          if (pageToVisitInfo.accountType === accountType) {
            history.push(pageToVisitInfo.path);
          }
        }
      } catch (error) {}

      dispatch({ type: STOP_AUTH_LOADING });
    });
  };

  const switchToReferral = () => {
    localStorage.setItem("accountType", "referral");
    window.location.reload();
  };

  return (
    // <AuthWrapper>
    <div
      className="auth signin"
      style={{
        background: "rgba(0,0,0,0.85)",
      }}
    >
      <div className="wrapper h-100 d-flex align-items-center">
        <div className="d-flex flex-column justify-content-center w-50 biz-logo">
          <img
            style={{ width: "60%", display: "block", margin: "0 auto" }}
            src={WorkImage}
            alt="logo"
          />
          <img className="img-responsive" src={WBtext} alt="logo" />
          <h4
            className="co-white text-center mt-2"
            style={{
              fontSize: "40px",
            }}
          >
            Business
          </h4>
        </div>
        <div className="w-50 auth-form-wrapper">
          <div className="auth-form mx-auto">
            <h4 className="text-center mt-4">Login To Your Business Account</h4>

            <CustomButton
              icon={WBlogo}
              alt="logo"
              backgroundColor="#3A6ED4"
              color="#fff"
              text="Switch to workbrook Referral"
              additionalClass="mt-3 mb-5"
              handleClick={() => switchToReferral()}
            />
            <p className="demarcator text-center">Or Login with email</p>

            <form onSubmit={handleSubmit}>
              <CustomInput
                onChange={handleChange}
                type="text"
                label="Email"
                id="email"
                value={state.email}
                placeholder=""
              />

              <CustomInput
                onChange={handleChange}
                type="password"
                label="Password"
                id="password"
                placeholder=""
                value={state.password}
                icon={EyeIcon}
              />

              <div className="d-flex justify-content-between align-items-center mt-3 small-text">
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      id="rememberMe"
                      onChange={handleChange}
                      checked={state.rememberMe}
                    />
                  }
                  label="Remember Me"
                />
                <Link to="/forgotPassword">Forgot Password?</Link>
              </div>

              <CustomButton
                type="submit"
                backgroundColor="black"
                color="#fff"
                text="Login"
                loading={loading}
                disabled={[
                  !isValidEmail(state.email),
                  !state.password,
                ].includes(true)}
                additionalClass="mt-3 mb-5"
              />
            </form>
            <br/><br/>
              <p className="text-center">
                
                <a href="request-business-addition">
                  <u>Click here to request for An account for your Business{" "}</u>
              </a>
             

            </p>
            <p className="text-center">OR</p>
            
            <p className="text-center" > 
               <a href="demo-request-business">
                  <u>Click here to request a demo{" "}</u>
                </a>
            </p>
              
            <p className="pt-4 pb-3 mb-0 small-text text-center">
              Don&apos;t have an account? <Link to="/signup">Sign Up</Link>{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
    // </AuthWrapper>
  );
};

export default Signin;
