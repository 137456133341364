import cogoToast from "cogo-toast";
import { listToMatrix } from "../../utils/helpers";

import httpRequest from "../../ajax";
import {
  APPLY_TO_JOB,
  CREATE_BUSINESS_JOB, // active
  GET_SUBSIDIARIES, // active
  GET_BUSINESS_JOBS, // active
  RESET_JOBS, //
  GET_MY_ADMIN_BUSINESS_JOBS, //
  SAVE_BUSINESS_REF, //
  GET_BUSINESS_REFS, //
  GET_AVG_TIME_TO_HIRE, //
  GET_COST_OF_HIRE_ANALYTICS,
  GET_EMPLOYEE_ENGAGEMENT_ANALYTICS,
  GET_REFERAL_RATIO_ANALYTICS,
  GET_AVERAGE_TIME_TO_HIRE_ANALYTICS,
  GET_PIE_CHART_ANALYTICS,
  GET_ANNUAL_EMPLOYEES, //
  PROCESS_QUALITY_OF_HIRE, //
  GET_QUALITY_OF_HIRE, //
  RESET_JOB,
  DELETE_SAVED_JOBS,
  ERROR,
  GET_OFFERS_JOBS,
  GET_APPLIED,
  GET_JOB_LEVELS,
  GET_MY_JOBS,
  GET_SAVED_JOBS,
  RESET_JOB_CREATED,
  SAVE_JOB,
  BUSINESS_SEARCH_JOBS,
  // LIKE_UNLIKE_JOB,
  GET_REFERRAL_JOBS,
  APPLY_TO_RECRUITER_JOB,
  GET_EMPLOYEE_ENGAGEMENT,
  GET_EMPLOYEE_REFERRAL_RATIO,
  GET_COST_OF_HIRE,
} from "../actionTypes";

// Post new job to the business timeline...
export const createBusinessJobAction = (dispatch, data) => {
  return httpRequest("post", "/business/jobs/create", data)
    .then(({ data: { data } }) => {
      const action = { type: CREATE_BUSINESS_JOB, payload: data };
      dispatch(action);
      getBusinessJobsAction(dispatch, 1);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

// Get subsidiaries by a company id
export const getSubsidiaries = (dispatch, company_id) =>
  httpRequest("get", `/business/subsidiaries/${company_id}`)
    .then(({ data: { data } }) => {
      const action = { type: GET_SUBSIDIARIES, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const clearJobCreated = (dispatch) =>
  dispatch({ type: RESET_JOB_CREATED });

//   Get Business Jobs action
export const getBusinessJobsAction = (dispatch, page) =>
  httpRequest("get", `/business/jobs/${page}/10`)
    .then(({ data: { data } }) => {
      const action = { type: GET_BUSINESS_JOBS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const resetJob = (dispatch) => dispatch({ type: RESET_JOB });

export const getOffersAction = (dispatch) =>
  httpRequest("get", `/insiight/jobOffers`)
    .then(({ data: { data } }) => {
      // console.log("Offers", data);
      const action = { type: GET_OFFERS_JOBS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const resetAllJobs = (dispatch) => dispatch({ type: RESET_JOBS });

export const getMyJobsAction = (dispatch) =>
  httpRequest("get", "/jobs/byMe/1/50")
    .then(({ data: { data } }) => {
      const action = { type: GET_MY_JOBS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getJobLevelsAction = (dispatch) =>
  httpRequest("get", "/jobs/levels")
    .then(({ data: { data } }) => {
      const action = { type: GET_JOB_LEVELS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
export const jobReferralLinkClicked = (data) =>
  httpRequest("post", "/insiight/admin/jobReferralLinkClicked", data)
    .then(() => {})
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getApplications = (dispatch) =>
  httpRequest("get", "/jobs/application")
    .then(({ data: { data } }) => {
      const action = { type: GET_APPLIED, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getSavedJobs = (dispatch, page) =>
  httpRequest("get", `/jobs/saved/${page}/10`)
    .then(({ data: { data } }) => {
      const action = { type: GET_SAVED_JOBS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const saveJobAction = (dispatch, job_id) =>
  httpRequest("post", "/jobs/saved/create", { job_id })
    .then(({ data: { data } }) => {
      const action = { type: SAVE_JOB, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const applyAction = (dispatch, job_id, refCode, func) =>
  httpRequest("post", "/jobs/application/create", { job_id, refCode })
    .then(({ data: { data } }) => {
      const action = { type: APPLY_TO_JOB, payload: data };
      dispatch(action);
      func();
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

// Apply to recuiter's jobs
export const applyToRecruiterJob = (dispatch, job_id, recruiterId, func) => {
  // console.log("Applying to Recruiters Jobs");
  const rData = {
    job_id,
    recruiterId: parseInt(recruiterId, 10),
  };

  return httpRequest("post", "/jobs/application/applyToRercruiterJob", rData)
    .then(({ data: { data } }) => {
      const action = { type: APPLY_TO_RECRUITER_JOB, payload: data };
      dispatch(action);
      func();
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

export const searchJobAction = (dispatch, term, navigate = () => {}) =>
  httpRequest("get", `/jobs/${term}/search`)
    .then(({ data: { data } }) => {
      navigate();
      const action = { type: BUSINESS_SEARCH_JOBS, payload: { data, term } };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => {
      cogoToast.error(`No result for ${term}`);
      return {
        payload: response?.data?.message,
        type: ERROR,
      };
    });

export const deleteSavedJob = (dispatch, job_id) =>
  httpRequest("post", `/jobs/saved/${job_id}/delete`, {})
    .then(({ data: { data } }) => {
      const action = { type: DELETE_SAVED_JOBS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const likeUnlikeJob = (dispatch, data) =>
  httpRequest("post", "/jobs/like/likeUnLike", data)
    .then
    // ({ data: { data } }
    //   ) => {
    // const action = { type: LIKE_UNLIKE_JOB, payload: data };
    // console.log("data payload", data);
    // dispatch(action);
    // return action;
    // }
    ()
    .catch(({ response }) => {
      // console.log("error res", response);
      return {
        payload: response?.data?.message,
        type: ERROR,
      };
    });

export const getSentReferralJobs = (dispatch, page) =>
  httpRequest("get", `/insiight/referrals/${page}/${10}`)
    .then(({ data: { data } }) => {
      // console.log("in action");
      const action = { type: GET_REFERRAL_JOBS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getReferralJobs = (dispatch) =>
  httpRequest("get", `/insiight/referrals`)
    .then(({ data: { data } }) => {
      // console.log("in action");
      const action = { type: GET_REFERRAL_JOBS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getAdminJobs = (dispatch, page) =>
  httpRequest("get", `/business/jobs/byMe/${page}/10`)
    .then(({ data: { data } }) => {
      // console.log("admin jobs", data);
      const action = { type: GET_MY_ADMIN_BUSINESS_JOBS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const saveBusinessRef = (dispatch, page, bData) =>
  httpRequest("post", `/business/jobs/saveToBusinessReferral`, bData)
    .then(({ data: { data } }) => {
      const action = { type: SAVE_BUSINESS_REF, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getBusinessRef = (dispatch, page) =>
  httpRequest("get", `/business/jobs/getBusinessRefJobs/${page}/10`)
    .then(({ data: { data } }) => {
      const action = { type: GET_BUSINESS_REFS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getAvgTimeToHire = (dispatch, cid) =>
  httpRequest("get", `/business/averageTimeToHire/${cid}`)
    .then(({ data: { data } }) => {
      const action = { type: GET_AVG_TIME_TO_HIRE, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => {
      return {
        payload: response?.data?.message,
        type: ERROR,
      };
    });



 

export const getAnnualEmployees = (dispatch, cid) =>
  httpRequest("get", `/business/annualEmployees/${cid}`)
    .then(({ data: { data } }) => {
      const action = { type: GET_ANNUAL_EMPLOYEES, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const processQualityOfHire = (dispatch, qData) =>
  httpRequest("post", `/business/processQualityOfHire`, qData)
    .then(({ data: { data } }) => {
      const action = { type: PROCESS_QUALITY_OF_HIRE, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getQualityOfHire = (dispatch, cid) =>
  httpRequest("get", `/business/getQualityOfHire/${cid}`)
    .then(({ data: { data } }) => {
      const action = { type: GET_QUALITY_OF_HIRE, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

export const getEmployeeEngagement = (dispatch, cid) =>
  httpRequest("get", `/business/getEmployeeEngagement/${cid}`)
    .then(({ data: { data } }) => {
      const action = { type: GET_EMPLOYEE_ENGAGEMENT, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => {
      return {
        payload: response?.data?.message,
        type: ERROR,
      };
    });

export const processEmployeeEngagement = (score) =>
  httpRequest("post", `/business/processEmployeeEngagement/${score}`)
    .then(({ data: { data } }) => {
      return data;
    })
    .catch(({ response }) => {
      return {
        payload: response?.data?.message,
        type: ERROR,
      };
    });

export const getEmployeeReferralRatio = (dispatch, cid) =>
  httpRequest("get", `/business/getEmployeeReferralRatio/${cid}`)
    .then(({ data: { data } }) => {
      const action = { type: GET_EMPLOYEE_REFERRAL_RATIO, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => {
      return {
        payload: response?.data?.message,
        type: ERROR,
      };
    });

export const getCostOfHire = (dispatch, cid) =>
  httpRequest("get", `/business/getCostOfHire/${cid}`)
    .then(({ data: { data } }) => {
      const action = { type: GET_COST_OF_HIRE, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => {
      return {
        payload: response?.data?.message,
        type: ERROR,
      };
    });
 

 export const getCostOfHireAnalytics = (dispatch, cid) =>
 {
    httpRequest("get", `/recruiter/insight/analytics/costofhire/${cid}`)
      .then(({ data: { data } }) => {
        console.log("<<getCostOfHireAnalytics>>"+listToMatrix(data,2));
        const action = { type: GET_COST_OF_HIRE_ANALYTICS, payload: listToMatrix(data,2) };
        dispatch(action);
        return action;
      })
      .catch(({ response }) => {
          return {
          payload: response?.data?.message,
          type: ERROR,
        };
      })
    };

    export const getReferalRationalytics = (dispatch, cid) =>
    {
       httpRequest("get", `/recruiter/insight/analytics/referralratio/${cid}`)
         .then(({ data: { data } }) => {
           console.log("<<getReferalRationalytics>>"+JSON.stringify(data));
           const action = { type: GET_REFERAL_RATIO_ANALYTICS, payload: data };
           dispatch(action);
           return action;
         })
         .catch(({ response }) => {
             return {
             payload: response?.data?.message,
             type: ERROR,
           };
         })
       };

       export const getPieChartAnalytics = (dispatch, cid) =>
       {
          httpRequest("get", `/recruiter/insight/analytics/shortlistAndInterviewRatio/${cid}`)
            .then(({ data: { data } }) => {
              console.log("<<getPieChartAnalytics>>"+JSON.stringify(data));
              const action = { type: GET_PIE_CHART_ANALYTICS, payload: data };
              dispatch(action);
              return action;
            })
            .catch(({ response }) => {
                return {
                payload: response?.data?.message,
                type: ERROR,
              };
            })
        };


      export const getAverageTimeToHireAnalytics = (dispatch, cid) =>
       {
          httpRequest("get", `/recruiter/insight/analytics/timeToCompleteShortlist/${cid}`)
            .then(({ data: { data } }) => {
              console.log("<<getAverageTimeToHireAnalytics>>"+JSON.stringify(data));
              const action = { type: GET_AVERAGE_TIME_TO_HIRE_ANALYTICS, payload: data };
              dispatch(action);
              return action;
            })
            .catch(({ response }) => {
                return {
                payload: response?.data?.message,
                type: ERROR,
              };
            })
        };
        
        

  export const getEmploymentEngagementAnalytics = (dispatch, cid) =>
    {
      httpRequest("get", `/recruiter/insight/analytics/employeeengagement/${cid}`)
         .then(({ data: { data } }) => {
           console.log("<<getEmploymentEngagementAnalytics>>"+listToMatrix(data,3));
           const action = { type: GET_EMPLOYEE_ENGAGEMENT_ANALYTICS, payload: listToMatrix(data,3) };
           dispatch(action);
           return action;
         })
         .catch(({ response }) => {
             return {
             payload: response?.data?.message,
             type: ERROR,
           };
         })
       };


 