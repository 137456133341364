import React, { useState, useEffect } from "react";
import CustomButton from "../../../../components/CustomButton";
import { IoPersonSharp } from "react-icons/io5";
// import { Toast, toast } from "react-toastify";
import CustomInput from "../../../../components/CustomInput";
import CustomTextArea from "../../../../components/CustomTextArea";
import { BsPeople, BsGraphUp } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  getPerformance,
  getEachPerformance,
} from "../../../../redux/actions/jobActions";

import { getEmployee } from "../../../../redux/actions/jobActions";

function SupervisorApproval() {
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [activeTab, setActiveTab] = useState("Leadership");
  const [checkedall, setChecked] = useState(false);
  const [checkedPlan, setCheckedPlan] = useState(false);
  const [employees, setEmployees] = useState([]);
  const { employeeList } = useSelector((store) => store);
  const dispatch = useDispatch();
  const [goalSubmit, setGoalSubmit] = useState(false);
  const [performanceData, setPerformanceData] = useState([]);

  const [employeeAppraisal, setEmployeeAppraisal] = useState([]);

  const [sendEmployee, setSendEmployee] = useState({
    employee_id: "",
    employee_name: "",
    employee_goals: [],
  });

  useEffect(() => {
    getEmployee(dispatch)
      .then((action) => {
        setEmployees(action.payload.data);
      })
      .catch((error) => {
        console.error("Error fetching employees:", error);
      })
      .finally(() => {
        setTimeout(() => {}, 1000);
      });
  }, [dispatch]);

  const handleEmployeeChange = (e) => {
    const value = e.target.value;
    const employee = employees.find((all) => all.first_name === value);
    if (employee) {
      setSelectedEmployee(employee);
      setSendEmployee((prevSendEmployee) => ({
        ...prevSendEmployee,
        employee_id: employee.id,
        employee_name: employee.first_name,
      }));
      setTimeout(() => {
        getPerformance(dispatch, employee.id)
          .then((action) => {
            console.log(action.payload.data);

            const performanceData = JSON.parse(
              action.payload.data.employee_goals
            );

            const employeeAppraisal = JSON.parse(
              action.payload.data.employee_appraisal
            );
            console.log(employeeAppraisal);
            console.log(employeeAppraisal.type);

            setEmployeeAppraisal(employeeAppraisal);
            setPerformanceData(performanceData);
            console.log(performanceData);
          })
          .catch((error) => {
            console.error(error);
          });
      }, 1000);
    }
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="card-height" additionalClass="mt-4 p-4">
      <h5 style={{ fontWeight: "bold" }} className="co-grayx">
        Supervisor's Approval
      </h5>

      <div
        style={{ padding: "10px", maxHeight: "680px" }}
        className="col-12 col-md-12 list-achievements"
      >
        <div className="select-container mt-3">
          <strong>Select Employee to view</strong>
          <select
            style={{ border: "1px solid #e8e9eb" }}
            onChange={handleEmployeeChange}
          >
            <option value="">Select an Option</option>
            {employees &&
              employees.map((employee) => (
                <option key={employee.id} value={employee.first_name}>
                  {employee.first_name}
                </option>
              ))}
          </select>
        </div>

        <br />

        <div
          style={{
            padding: "10px",
            maxHeight: "680px",
          }}
          className="col-12 col-md-12 list-achievements"
        >
          {/* <form> */}

          {selectedEmployee ? (
            <div>
              <strong>Click on each performance to view your Goals</strong>
              <div className="mt-3">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <button
                      className={`nav-link d-flex align-items-center justify-content-center ${
                        activeTab === "Leadership" ? "active" : ""
                      }`}
                      onClick={() => {
                        handleTabClick("Leadership");
                      }}
                      style={{
                        backgroundColor:
                          activeTab === "Leadership" ? "#dcdcdc" : "#3A6ED4",
                        color: activeTab === "Leadership" ? "#fff" : "#fff",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      <BsPeople className="mb-1" /> Leadership
                    </button>
                  </li>

                  <li className="nav-item">
                    <button
                      className={`nav-link d-flex align-items-center justify-content-center ${
                        activeTab === "Performance" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("Performance")}
                      style={{
                        backgroundColor:
                          activeTab === "Performance" ? "#dcdcdc" : "#3A6ED4",
                        color: activeTab === "Performance" ? "#fff" : "#fff",
                        marginLeft: "2rem",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      <BsGraphUp /> Performance
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link d-flex align-items-center justify-content-center ${
                        activeTab === "Development" ? "active" : ""
                      }`}
                      onClick={() => {
                        handleTabClick("Development");
                      }}
                      style={{
                        backgroundColor:
                          activeTab === "Development" ? "#dcdcdc" : "#3A6ED4",
                        color: activeTab === "Development" ? "#fff" : "#fff",
                        marginLeft: "2rem",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      <IoPersonSharp /> Development
                    </button>
                  </li>
                </ul>

                <div className="tab-content mt-3">
                  <div
                    className={`tab-pane fade ${
                      activeTab === "Leadership" ? "show active" : ""
                    }`}
                    id="leadership-tab"
                    style={{ marginTop: "7px" }}
                  >
                    <main>
                      {performanceData && performanceData.Leadership && (
                        <div>
                          {performanceData.Leadership.map((goal, index) => (
                            <div key={index}>
                              <strong>
                                {sendEmployee.employee_name} Leadership
                                Goal/Objective(Title)
                              </strong>
                              <CustomInput
                                style={{
                                  width: "100%",
                                  marginTop: "10px",
                                  height: "2rem",
                                  border: "1px solid #e8e9eb",
                                  borderRadius: "0.875rem",
                                  padding: "1rem",
                                  marginBottom: "8px",
                                }}
                                placeholder="Enter your Leadership goal title"
                                name="goal_title"
                                value={goal.goal_title}
                              />
                              <br />
                              <div>
                                <strong>
                                  Write your Leadership Goals/Objectives (Free
                                  text)
                                </strong>
                                <textarea
                                  style={{
                                    width: "100%",
                                    marginTop: "10px",
                                    height: "9rem",
                                    border: "1px solid #e8e9eb",
                                    borderRadius: "0.875rem",
                                    padding: "1rem",
                                    marginBottom: "8px",
                                  }}
                                  placeholder="Enter your Leadership goals"
                                  name="goal_content"
                                  value={goal.goal_content}
                                />
                              </div>
                              <br />

                              <div className="select-container">
                                <strong>Status</strong>
                                <select
                                  name="status"
                                  value={goal.status}
                                  style={{ border: "1px solid #e8e9eb" }}
                                >
                                  <option value="option1">
                                    Select an Option
                                  </option>
                                  <option value="notStarted">
                                    Not started
                                  </option>
                                  <option value="inProgress">
                                    In Progress
                                  </option>
                                  <option value="completed">Completed</option>
                                  <option value="cancelled">Cancelled</option>
                                </select>
                              </div>
                              <br />
                              <br />

                              <div>
                                <strong>Target Measurement (%)</strong>
                                <CustomInput
                                  type="text"
                                  placeholder={`Enter your target measurement for Leadership Goals`}
                                  label=""
                                  id={`target_measurement_for leadership goals`}
                                  name="target_measurement"
                                  value={goal.target_measurement}
                                />
                              </div>
                              <br />

                              <div>
                                <strong>Target Achieved (%)</strong>
                                <CustomInput
                                  name="target_achieved"
                                  value={goal.target_achieved}
                                  type="text"
                                  placeholder={`Enter your target measurement for Leadership Goals`}
                                  label=""
                                  id={`target_measurement_for leadership goals`}
                                />
                              </div>
                              <br />

                              <div>
                                <strong>Due Date</strong> <br />
                                <input
                                  style={{ border: "1px solid #e8e9eb" }}
                                  className="select-contain"
                                  type="date"
                                  name="due_date"
                                  value={goal.due_date}
                                  id={`due_date_for Leadership goals`}
                                />
                              </div>
                              <br />
                              <br />
                            </div>
                          ))}
                        </div>
                      )}
                      {employeeAppraisal
                        .filter((item) => item.type === "Leadership")
                        .map((item, index) => (
                          <div>
                            <strong className="mt-5">
                              Summary of {sendEmployee.employee_name} Review
                              Comment(Free text)
                            </strong>
                            <CustomTextArea
                              value={item.employee_appraisal}
                              label=""
                            />
                          </div>
                        ))}

                      <br />
                      <button
                        className="rounded-3 border border-none"
                        style={{
                          width: "10%",
                          color: "#fff",
                          height: "6vh",
                          borderRadius: "5px",
                          backgroundColor: "#3A6ED4",
                        }}
                        color="#fff"
                        text="Submit"
                      >
                        Save
                      </button>
                    </main>

                    <br />
                    <br />
                  </div>

                  <div
                    className={`tab-pane fade ${
                      activeTab === "Performance" ? "show active" : ""
                    }`}
                    id="performance-tab"
                    style={{ marginTop: "7px" }}
                  >
                    <main>
                      {performanceData && performanceData.Performance && (
                        <div>
                          {performanceData.Performance.map((goal, index) => (
                            <div key={index}>
                              <strong>
                                {" "}
                                {sendEmployee.employee_name} Performance
                                Goal/Objective(Title)
                              </strong>
                              <CustomInput
                                style={{
                                  width: "100%",
                                  marginTop: "10px",
                                  height: "2rem",
                                  border: "1px solid #e8e9eb",
                                  borderRadius: "0.875rem",
                                  padding: "1rem",
                                  marginBottom: "8px",
                                }}
                                placeholder="Enter your Leadership goal title"
                                name="goal_title"
                                value={goal.goal_title}
                              />
                              <br />
                              <div>
                                <strong>
                                  Write your Performance Goals/Objectives (Free
                                  text)
                                </strong>
                                <textarea
                                  style={{
                                    width: "100%",
                                    marginTop: "10px",
                                    height: "9rem",
                                    border: "1px solid #e8e9eb",
                                    borderRadius: "0.875rem",
                                    padding: "1rem",
                                    marginBottom: "8px",
                                  }}
                                  placeholder="Enter your Leadership goals"
                                  name="goal_content"
                                  value={goal.goal_content}
                                />
                              </div>
                              <br />

                              <div className="select-container">
                                <strong>Status</strong>
                                <select
                                  name="status"
                                  value={goal.status}
                                  style={{ border: "1px solid #e8e9eb" }}
                                >
                                  <option value="option1">
                                    Select an Option
                                  </option>
                                  <option value="notStarted">
                                    Not started
                                  </option>
                                  <option value="inProgress">
                                    In Progress
                                  </option>
                                  <option value="completed">Completed</option>
                                  <option value="cancelled">Cancelled</option>
                                </select>
                              </div>
                              <br />
                              <br />

                              <div>
                                <strong>Target Measurement (%)</strong>
                                <CustomInput
                                  type="text"
                                  placeholder={`Enter your target measurement for Leadership Goals`}
                                  label=""
                                  id={`target_measurement_for leadership goals`}
                                  name="target_measurement"
                                  value={goal.target_measurement}
                                />
                              </div>
                              <br />

                              <div>
                                <strong>Target Achieved (%)</strong>
                                <CustomInput
                                  name="target_achieved"
                                  value={goal.target_achieved}
                                  type="text"
                                  placeholder={`Enter your target measurement for Leadership Goals`}
                                  label=""
                                  id={`target_measurement_for leadership goals`}
                                />
                              </div>
                              <br />

                              <div>
                                <strong>Due Date</strong> <br />
                                <input
                                  style={{ border: "1px solid #e8e9eb" }}
                                  className="select-contain"
                                  type="date"
                                  name="due_date"
                                  value={goal.due_date}
                                  id={`due_date_for Leadership goals`}
                                />
                              </div>
                              <br />
                              <br />
                            </div>
                          ))}
                        </div>
                      )}
                      {employeeAppraisal
                        .filter((item) => item.type === "Performance")
                        .map((item, index) => (
                          <div>
                            <strong className="mt-5">
                              Summary of {sendEmployee.employee_name} Review
                              Comment(Free text)
                            </strong>
                            <CustomTextArea
                              value={item.employee_appraisal}
                              label=""
                            />
                          </div>
                        ))}

                      <br />
                      <button
                        className="rounded-3 border border-none"
                        style={{
                          width: "10%",
                          color: "#fff",
                          height: "6vh",
                          borderRadius: "5px",
                          backgroundColor: "#3A6ED4",
                        }}
                        color="#fff"
                        text="Submit"
                      >
                        Save
                      </button>
                    </main>
                    <br />
                    <br />
                  </div>

                  <div
                    className={`tab-pane fade ${
                      activeTab === "Development" ? "show active" : ""
                    }`}
                    id="development-tab"
                    style={{ marginTop: "7px" }}
                  >
                    <main>
                      {performanceData && performanceData.Development && (
                        <div>
                          {performanceData.Development.map((goal, index) => (
                            <div key={index}>
                              <strong>
                                {" "}
                                {sendEmployee.employee_name} Development
                                Goal/Objective(Title)
                              </strong>
                              <CustomInput
                                style={{
                                  width: "100%",
                                  marginTop: "10px",
                                  height: "2rem",
                                  border: "1px solid #e8e9eb",
                                  borderRadius: "0.875rem",
                                  padding: "1rem",
                                  marginBottom: "8px",
                                }}
                                placeholder="Enter your Leadership goal title"
                                name="goal_title"
                                value={goal.goal_title}
                              />
                              <br />
                              <div>
                                <strong>
                                  Write your Development Goals/Objectives (Free
                                  text)
                                </strong>
                                <textarea
                                  style={{
                                    width: "100%",
                                    marginTop: "10px",
                                    height: "9rem",
                                    border: "1px solid #e8e9eb",
                                    borderRadius: "0.875rem",
                                    padding: "1rem",
                                    marginBottom: "8px",
                                  }}
                                  placeholder="Enter your Leadership goals"
                                  name="goal_content"
                                  value={goal.goal_content}
                                />
                              </div>
                              <br />

                              <div className="select-container">
                                <strong>Status</strong>
                                <select
                                  name="status"
                                  value={goal.status}
                                  style={{ border: "1px solid #e8e9eb" }}
                                >
                                  <option value="option1">
                                    Select an Option
                                  </option>
                                  <option value="notStarted">
                                    Not started
                                  </option>
                                  <option value="inProgress">
                                    In Progress
                                  </option>
                                  <option value="completed">Completed</option>
                                  <option value="cancelled">Cancelled</option>
                                </select>
                              </div>
                              <br />
                              <br />

                              <div>
                                <strong>Target Measurement (%)</strong>
                                <CustomInput
                                  type="text"
                                  placeholder={`Enter your target measurement for Leadership Goals`}
                                  label=""
                                  id={`target_measurement_for leadership goals`}
                                  name="target_measurement"
                                  value={goal.target_measurement}
                                />
                              </div>
                              <br />

                              <div>
                                <strong>Target Achieved (%)</strong>
                                <CustomInput
                                  name="target_achieved"
                                  value={goal.target_achieved}
                                  type="text"
                                  placeholder={`Enter your target measurement for Leadership Goals`}
                                  label=""
                                  id={`target_measurement_for leadership goals`}
                                />
                              </div>
                              <br />

                              <div>
                                <strong>Due Date</strong> <br />
                                <input
                                  style={{ border: "1px solid #e8e9eb" }}
                                  className="select-contain"
                                  type="date"
                                  name="due_date"
                                  value={goal.due_date}
                                  id={`due_date_for Leadership goals`}
                                />
                              </div>
                              <br />
                            </div>
                          ))}
                        </div>
                      )}
                      {employeeAppraisal
                        .filter((item) => item.type === "Development")
                        .map((item, index) => (
                          <div>
                            <strong className="mt-5">
                              Summary of {sendEmployee.employee_name} Review
                              Comment(Free text)
                            </strong>
                            <CustomTextArea
                              value={item.employee_appraisal}
                              label=""
                            />
                          </div>
                        ))}

                      <br />
                      <button
                        className="rounded-3 border border-none"
                        style={{
                          width: "10%",
                          color: "#fff",
                          height: "6vh",
                          borderRadius: "5px",
                          backgroundColor: "#3A6ED4",
                        }}
                        color="#fff"
                        text="Submit"
                      >
                        Submit
                      </button>
                    </main>
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <br />

          {/* </form> */}
        </div>

        <br />
        <br />
      </div>
    </div>
  );
}

export default SupervisorApproval;
