import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmptyState from "../../../../components/EmptyState";
import { SectionLoader } from "../../../../components/PageLoader";
import { ButtonLoader } from "../../../../components/ButtonLoader";
import { getTransactions } from "../../../../redux/actions/walletActions";
import moment from "moment";

const TransactionHistory = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const { transactions } = useSelector((state) => state.wallet);
  // console.log("trans", transactions);

  useEffect(() => {
    page === 1 ? setLoading(true) : setLoadingMore(true);
    Promise.resolve(getTransactions(dispatch, page)).finally(() =>
      page === 1 ? setLoading(false) : setLoadingMore(false)
    );
  }, [dispatch, page]);

  return (
    <>
      {loading && <SectionLoader />}
      {!loading && !transactions && <EmptyState text="No transaction found" />}
      <div className="history d-flex flex-column">
        {!loading &&
          transactions &&
          transactions[0].map((transaction) => (
            <div
              key={transaction.id}
              className={`${transaction.transactionType} d-flex align-items-center justify-content-between m-4`}
            >
              <div className="d-flex align-items-center">
                <span className="indicator mr-4">
                  {transaction.transactionType === "credit" ? "+" : "-"}
                </span>
                <div className="d-flex flex-column">
                  <span>{transaction.description}</span>
                  <span className="text-muted">
                    {moment(transaction.date_created).format(
                      "Do MMM YYYY; h:mm a"
                    )}
                  </span>
                </div>
              </div>
              <span className="mr-2">
                <b>{transaction.amount} USD</b>
              </span>
            </div>
          ))}
      </div>
    </>
  );
};

export default TransactionHistory;
